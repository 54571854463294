import { IRootReducer } from "../combineReducer";

export const ecommerceFilterOptions = (state: IRootReducer) =>
  state.ecommerceFilter.filter;
export const useGetSelectedSids = (state: IRootReducer) =>
  state.ecommerceFilter.selectedSids;
export const useGetUrl = (state: IRootReducer) => state.ecommerceFilter.url;

export const useGetScrollPosition = (state: IRootReducer) =>
  state.ecommerceFilter.scrollPosition;

export const useGetEcommLabelList = (state: IRootReducer) =>
  state.ecommerceFilter.labels;

export const useGetEcommSearchedText = (state: IRootReducer) =>
  state.ecommerceFilter.searchedText;

export const useGetEcommIsFocussedSearchedText = (state: IRootReducer) =>
  state.ecommerceFilter.searchedTextFocused;

export const useGetEcommShowGraphView = (state: IRootReducer) =>
  state.ecommerceFilter.showGraphView;
