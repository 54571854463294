import { useAuth0 } from "@auth0/auth0-react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  Checkbox,
  FormControlLabel,
  DialogActions,
  Button,
  Typography,
} from "@material-ui/core";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useBooleanState } from "../hooks/common/hooks";
import { showError } from "../commoncomponent/Client";
import { ExportOptionsType, FormatOptionType } from "./type";
import { getDownloadFile, getExportFileOptions } from "./util";
import CheckIcon from "../../images/checkedBox.svg";
import UnCheckIcon from "../../images/uncheckedBox.svg";
import LoadingComponent from "../commoncomponent/LoadingComponent";
import { useHistory } from "react-router";
import { subscriptionRoute } from "../../constants/routesLinks";
import { CheckboxItemText } from "../commoncomponent/style";
import { useGetAdvanceSelectedOptions } from "../../data/advancesearch/selector";
import DownloadInitialisationDialog from "./DownloadInitialisationDialog";
import { downloadFile } from "../../utils/utility";

type ExportDialogProps = {
  handleClose: () => void;
  exportFileType: "org" | "ecomm";
  pageCount: number;
  isBasicSearch: boolean;
  offset: number;
};

const ExportDialog: FC<ExportDialogProps> = ({
  handleClose,
  exportFileType,
  pageCount,
  isBasicSearch,
  offset,
}) => {
  const dispatch = useDispatch();
  const { logout } = useAuth0();
  const history = useHistory();
  const advanceFilterLists = useSelector(useGetAdvanceSelectedOptions);
  const [exportFileOptions, setExportFileOptions] =
    useState<ExportOptionsType | null>(null);
  const [selectedOption, setSelectedOption] = useState<FormatOptionType>();
  const isLoading = useBooleanState(false);
  const [csvExportModal, setCsvExportModal] = useState<string | null>(null);

  useEffect(() => {
    getExportFileOptions(exportFileType)
      .then((data) => {
        setExportFileOptions(data);
        if (data && data.formatOpts.length > 0) {
          setSelectedOption(data.formatOpts[0]);
        }
      })
      .catch((err) => {
        dispatch(showError(err, logout));
      });
  }, []);

  const exportFile = useCallback(() => {
    isLoading.setTrue();
    selectedOption &&
      getDownloadFile(
        exportFileType,
        selectedOption,
        location.search,
        pageCount,
        isBasicSearch,
        advanceFilterLists,
        offset,
        exportFileOptions?.exportDisabled
      )
        .then((res: any) => {
          if (exportFileOptions?.exportDisabled) {
            downloadFile(res.data, "stackbrain-accounts.csv");
            return;
          }
          setCsvExportModal(res.data.message);
        })
        .catch((err) => {
          dispatch(showError(err, logout));
        })
        .finally(() => isLoading.setFalse());
  }, [selectedOption]);

  const handleFormatOptionChange = useCallback(
    (optionName: string) => {
      if (!exportFileOptions) {
        return;
      }
      const selectedOptionFound = exportFileOptions.formatOpts.find(
        ({ name }) => name === optionName
      );
      if (selectedOptionFound) {
        const filterCheckedOptions: string[] = [];
        selectedOptionFound.attributes?.map(
          (item) => item.checked && filterCheckedOptions.push(item.name)
        );
        selectedOptionFound && setSelectedOption(selectedOptionFound);
      }
    },
    [exportFileOptions]
  );

  const handleCheckBoxChange = useCallback(
    (attributeName: string, checked: boolean) => {
      if (!selectedOption?.attributes) {
        return;
      }
      const updatedAttributes = selectedOption.attributes.map((item) =>
        item.name === attributeName ? { ...item, checked } : item
      );
      setSelectedOption({
        ...selectedOption,
        attributes: updatedAttributes,
      });
    },
    [selectedOption]
  );

  return (
    <Dialog open={true} maxWidth={csvExportModal ? "sm" : "md"} fullWidth>
      {csvExportModal ? (
        <DownloadInitialisationDialog
          message={csvExportModal}
          handleClose={handleClose}
        />
      ) : (
        <>
          <DialogTitle>
            <b>Export</b>
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              {exportFileOptions?.exportDisabledDisplay.header}
            </Typography>
            <br />
            <FormControl style={{ width: "50%" }}>
              <InputLabel>Export format</InputLabel>
              <Select
                value={selectedOption?.name || ""}
                label="Export Format"
                onChange={({ target }) =>
                  handleFormatOptionChange(target.value as string)
                }
              >
                {exportFileOptions?.formatOpts.map((formatOpt) => (
                  <MenuItem key={formatOpt.name} value={formatOpt.name}>
                    {formatOpt.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {selectedOption?.attributes && (
              <DialogTitle
                style={{ marginTop: 20, paddingLeft: 0, paddingBottom: 0 }}
              >
                Fields
              </DialogTitle>
            )}

            <Grid container>
              {selectedOption?.attributes?.map((attribute, index) => (
                <Grid item key={`${attribute.name}-${index}`} xs={6} md={3}>
                  <Grid container>
                    <FormControlLabel
                      control={
                        <Checkbox
                          style={{ paddingTop: 0, paddingBottom: 0 }}
                          icon={
                            <img
                              src={UnCheckIcon}
                              alt="uncheckedBox"
                              width={16}
                              height={16}
                            />
                          }
                          checkedIcon={
                            <img
                              src={CheckIcon}
                              alt="uncheckedBox"
                              width={16}
                              height={16}
                            />
                          }
                          size={"small"}
                          onChange={({ target }) =>
                            handleCheckBoxChange(attribute.name, target.checked)
                          }
                          checked={
                            selectedOption?.attributes?.find(
                              ({ name }) => name === attribute.name
                            )?.checked || false
                          }
                        />
                      }
                      label={
                        <CheckboxItemText>{attribute.name}</CheckboxItemText>
                      }
                      labelPlacement="end"
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <br />
            <Typography variant="body1">
              {exportFileOptions?.exportDisabledDisplay.footer}
            </Typography>
          </DialogContent>
          <DialogActions
            style={{
              justifyContent: "space-between",
              padding: 30,
            }}
          >
            <Button
              onClick={handleClose}
              variant={"outlined"}
              color={"primary"}
            >
              Close
            </Button>
            <LoadingComponent
              loading={isLoading.value}
              loadingVariant={"static"}
            >
              <Button
                disabled={!selectedOption}
                onClick={exportFile}
                variant={
                  exportFileOptions?.exportDisabled ? "outlined" : "contained"
                }
                color={"primary"}
              >
                Download {exportFileOptions?.exportDisabled && "Sample"}
              </Button>
            </LoadingComponent>
            {exportFileOptions?.exportDisabled && (
              <Button
                variant={"contained"}
                color={"primary"}
                onClick={() => history.push(subscriptionRoute)}
              >
                Upgrade
              </Button>
            )}
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default ExportDialog;
