import React from "react";
import { Paper, Typography, Button, Grid } from "@material-ui/core";
import { Client } from "../commoncomponent/Client";
import { getResetPasswordApiUrl } from "../../constants/ApiConstants";
import configData from "../../config";
import { useAuth0 } from "@auth0/auth0-react";
import { useBooleanState } from "../hooks/common/hooks";
import { trackEvent } from "../../service/mixpanel";

const ResetPassword = () => {
  const emailSent = useBooleanState(false);
  const { user } = useAuth0();
  const sendEmail = () => {
    const clientId = configData.AUTH0_CLIENT_ID;

    const data = {
      client_id: clientId,
      email: user?.email,
      connection: "Username-Password-Authentication",
    };
    trackEvent("RESET_PASSWORD", { ...data });
    Client.getInstance()
      .createData(getResetPasswordApiUrl(), data)
      .then(() => emailSent.setTrue());
  };

  return (
    <Grid container justifyContent={"center"}>
      <Paper style={{ width: "fit-content", padding: 50 }}>
        <Typography variant={"h4"}>
          Changing your Stack Brain Password
        </Typography>
        <Typography variant={"h6"}>
          Need to reset your password? No problem.
        </Typography>
        <Typography variant={"h6"}>
          Just Click below link to get started.
        </Typography>
        {emailSent.value ? (
          <Typography variant={"h6"}>
            We've just sent you an email to reset your password.
          </Typography>
        ) : (
          <Button
            variant={"contained"}
            color={"primary"}
            style={{ marginTop: 30 }}
            onClick={sendEmail}
          >
            Reset my password
          </Button>
        )}
      </Paper>
    </Grid>
  );
};

export default ResetPassword;
