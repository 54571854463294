import { getDomainImageApiUrl } from "../constants/ApiConstants";

export const getCurrentDateTime = () => {
  const today = new Date();
  const date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  const time =
    today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  const dateTime = date + " " + time;
  return dateTime;
};

export const getErrorMessage = (error: any): string =>
  error.response.data && error.response.data.message
    ? error.response.data.message
    : "Operation failed please try again";

export const getDomainImage = (domain: string) => getDomainImageApiUrl(domain);

export const getUtcDate = (date: number) =>
  new Date(date).toLocaleDateString("en-US");
export const getUtcTime = (time: number) =>
  new Date(time).toLocaleTimeString("en-US");

export const formatNumber = (value: number) => {
  return value !== 0
    ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : "";
};

export const getFileExtension = (fileName: string) => {
  return fileName.split(".").pop();
};

export const generateRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const getMeta = (url: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = () => reject();
    img.src = url;
  });
};

export const getImageWidth = async (url: string): Promise<number> => {
  const img: HTMLImageElement = await getMeta(url);
  const width: number = img.width;
  return width;
};

export const downloadFile = (res: any, fileName: string) => {
  const url = window.URL.createObjectURL(new Blob([res]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};
