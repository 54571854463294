import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Paper, Typography, Grid } from "@material-ui/core";

const Profile = () => {
  const { user } = useAuth0();
  return (
    <Grid container justifyContent={"center"}>
      <Paper style={{ padding: 50 }}>
        <img src={user?.picture} alt={"image"} />
        <Typography style={{ marginTop: 30 }}>{user?.name}</Typography>
        <Typography>Email: {user?.email}</Typography>
        <Typography>Email verified: {user?.email_verified}</Typography>
      </Paper>
    </Grid>
  );
};

export default Profile;
