import {
  addAdvanceListApiUrl,
  getAddEcommerceLabelApiUrl,
  getEcommerceLabelListApiUrl,
  getUpdateEcommerceLabelApiUrl,
  updateAdvanceListApiUrl,
} from "../../constants/ApiConstants";
import { trackEvent } from "../../service/mixpanel";
import { Client } from "../commoncomponent/Client";
import { reverseAdvanceSearchTransform } from "../ecommerce/EcommerceAdvance/util";

const transformData = (res: any) => {
  if (!res.data.lists) {
    return [];
  }
  return res.data.lists.map((item: any) => ({
    isFav: item.is_fav,
    sid: item.sid,
    name: item.name,
  }));
};

export const getLabels = (type: string, forceRefresh = false) => {
  trackEvent("GET_LIST");
  return Client.getInstance()
    .getData(getEcommerceLabelListApiUrl(type), forceRefresh)
    .then((res) => Promise.resolve(transformData(res)))
    .catch((err) => Promise.reject(err));
};
export const updateLabel = (
  sid: string,
  selectedSids: string[],
  type: string,
  text?: string
) => {
  const data: {
    sid: string;
    query: { [key: string]: any };
  } = {
    sid: sid,
    query: {
      sids: selectedSids,
    },
  };
  if (text) {
    data.query["text"] = text;
  }
  trackEvent("UPDATE_NEW_LIST", data);
  return Client.getInstance()
    .createData(getUpdateEcommerceLabelApiUrl(type), data)
    .then(() => Promise.resolve())
    .catch((err) => Promise.reject(err));
};

export const addLabel = (
  name: string,
  selectedSids: string[],
  type: string,
  text?: string
) => {
  const data: {
    name: string;
    query: { [key: string]: any };
  } = {
    name: name,
    query: {
      sids: selectedSids,
    },
  };
  if (text) {
    data.query["text"] = text;
  }
  trackEvent("ADD_NEW_LIST", data);
  return Client.getInstance()
    .createData(getAddEcommerceLabelApiUrl(type), data)
    .then(() => Promise.resolve())
    .catch((err) => Promise.reject(err));
};

export const addNewAdvanceSearchLabel = (
  name: string,
  advanceSearchList: any,
  type: string
) => {
  const data = {
    name: name,
    ...reverseAdvanceSearchTransform(advanceSearchList, 0),
  };
  trackEvent("ADD_NEW_ADVANCE_LIST", data);
  return Client.getInstance()
    .createData(addAdvanceListApiUrl(type), data)
    .then(() => Promise.resolve())
    .catch((err) => Promise.reject(err));
};

export const updateAdvanceLists = (
  sid: string,
  advanceFilterLists: any,
  type: string
) => {
  const data = {
    sid: sid,
    ...reverseAdvanceSearchTransform(advanceFilterLists, 0),
  };
  trackEvent("UPDATE_ADVANCE_SEARCH_LIST", data);
  return Client.getInstance()
    .createData(updateAdvanceListApiUrl(type), data)
    .then(() => Promise.resolve())
    .catch((err) => Promise.reject(err));
};
