import React, { FC } from "react";
import { GraphDataType } from "./type";
import ReactECharts from "echarts-for-react";
import { PrimaryButtonColor, secondarySubHeadingFontColor } from "../../../constants/ColorConstants";
import { formatNumber } from "../../../utils/utility";

type BarGraphProps = {
  filters: GraphDataType;
  name: string;
};

const getBarWidth = (length: number) => {
  if (length === 1) {
    return "10%";
  }
  if (length <= 3) {
    return "20%";
  }
  return "60%";
};

const BarGraph: FC<BarGraphProps> = ({ filters, name }) => (
  <ReactECharts
    option={{
      tooltip: {
        trigger: "item",
      },
      yAxis: {
        type: "category",
        data: filters.labels,
        splitLine: {
          show: false,
        },
      },
      grid: {
        bottom: "3%",
        containLabel: true,
      },
      xAxis: {
        type: "value",
        axisLabel: {
          show: true,
          textStyle: {
            fontSize: 10,
          },
        },
        splitLine: {
          show: true,
        },
        label: {
          show: true,
          textStyle: {
            fontSize: 12,
          },
        },
      },
      color: PrimaryButtonColor,
      series: [
        {
          name: name,
          data: filters.datasets.data,
          type: "bar",
          barWidth: getBarWidth(filters.datasets.data.length),
          label: {
            formatter: (d: any) => {
              return formatNumber(parseInt(d.data));
            },
            position: "right",
            show: true,
            textStyle: {
              fontSize: 12,
              color: secondarySubHeadingFontColor,
            },
          },
        },
      ],
    }}
    opts={{ renderer: "svg" }}
  />
);

export default BarGraph;
