import React from "react";
import { useHistory } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import configData from "./config";
import { logoutRoute, organisationRoute } from "./constants/routesLinks";

interface Auth0ProviderWithHistoryProps {
  children: JSX.Element;
}

const Auth0ProviderWithHistory: React.FC<Auth0ProviderWithHistoryProps> = ({
  children,
}) => {
  const domain = configData.AUTH0_DOMAIN || "";
  const clientId = configData.AUTH0_CLIENT_ID || "";
  const audience = configData.AUTH0_AUDIENCE || "";
  const redirectUri = configData.HOST || "";
  const history = useHistory();

  const onRedirectCallback = (appState: any) => {
    if (appState?.returnTo === logoutRoute) {
      history.push(organisationRoute);
      return;
    }
    history.push(appState?.returnTo || window.location.pathname);
    return;
  };

  return (
    <Auth0Provider
      audience={audience}
      scope="read:current_user update:current_user_metadata"
      domain={domain}
      clientId={clientId}
      redirectUri={redirectUri}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
