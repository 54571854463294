import { LogoutOptions } from "@auth0/auth0-spa-js";
import { Dispatch } from "redux";
import { IActionPayload } from "../../component/commoncomponent/Base";
import { Client, showError } from "../../component/commoncomponent/Client";
import { getEcommAdvanceSearchApiUrl } from "../../constants/ApiConstants";
import { trackEvent } from "../../service/mixpanel";
import { transformUpgradePlanMessage, updateUpgradeMessage } from "../action";
import { UPDATE_ADVANCE_OPTIONS } from "../advancesearch/type";
import { LabelType } from "../LabelsList/type";
import { FilterType } from "./type";
import { transformAdvanceSearch } from "./util";

export const UPDATE_ECOMMERCE_FILTERS = "UPDATE_ECOMMERCE_FILTERS";
export const UPDATE_SELECTED_SIDS = "UPDATE_SELECTED_SIDS";
export const UPDATE_ECOMM_SEARCHED_TEXT = "UPDATE_ECOMM_SEARCHED_TEXT";
export const CLEAR_ECOMMERCE_URL = "CLEAR_ECOMMERCE_URL";
export const UPDATE_SCROLL_POSITION = "UPDATE_SCROLL_POSITION";
export const UPDATE_ECOMM_LABEL_LIST = "UPDATE_ECOMM_LABEL_LIST";
export const UPDATE_ECOMM_ADVANCE_OPTIONS = "UPDATE_ECOMM_ADVANCE_OPTIONS";
export const UPDATE_ECOMM_ADVANCE_SELECTED_OPTIONS =
  "UPDATE_ECOMM_ADVANCE_SELECTED_OPTIONS";
export const UPDATE_ECOMM_FOCUSED_SEARCHED_TEXT =
  "UPDATE_ORG_FOCUSED_SEARCHED_TEXT";
export const UPDATE_ECOMM_SHOW_GRAPH_VIEW = "UPDATE_ECOMM_GRAPH_VIEW";

export const updateEcommerceFilters = (
  filters: FilterType[]
): IActionPayload<FilterType[]> => ({
  type: UPDATE_ECOMMERCE_FILTERS,
  payload: filters,
});

export const updateEcommerceShowGraphView = (
  showGraphView: boolean
): IActionPayload<boolean> => ({
  type: UPDATE_ECOMM_SHOW_GRAPH_VIEW,
  payload: showGraphView,
});

export const updateSids = (sids: string[]): IActionPayload<string[]> => ({
  type: UPDATE_SELECTED_SIDS,
  payload: sids,
});

export const updateEcommSearchedText = (
  text: string
): IActionPayload<string> => ({
  type: UPDATE_ECOMM_SEARCHED_TEXT,
  payload: text,
});

export const clearEcommerceUrl = (url: string): IActionPayload<string> => ({
  type: CLEAR_ECOMMERCE_URL,
  payload: url,
});

export const updateEcommFocusedSearchedText = (
  isFocued: boolean
): IActionPayload<boolean> => ({
  type: UPDATE_ECOMM_FOCUSED_SEARCHED_TEXT,
  payload: isFocued,
});

export const updateScrollPosition = (
  position: number
): IActionPayload<number> => ({
  type: UPDATE_SCROLL_POSITION,
  payload: position,
});

export const updateEcommLabelList = (
  labels: LabelType[]
): IActionPayload<LabelType[]> => ({
  type: UPDATE_ECOMM_LABEL_LIST,
  payload: labels,
});

export const getAdvanceSearchAction =
  (logout: (options?: LogoutOptions | undefined) => void) =>
  (dispatch: Dispatch) => {
    trackEvent("GET_ADVANCE_OPTIONS_LISTS");
    Client.getInstance()
      .getData(getEcommAdvanceSearchApiUrl(), true)
      .then((res) => {
        dispatch({
          type: UPDATE_ADVANCE_OPTIONS,
          payload: res.data.filter_cfg
            ? transformAdvanceSearch(res.data.filter_cfg)
            : [],
        });
      })
      .catch((err) => {
        showError(err, logout);
        err.statusCode === 402 &&
          dispatch(
            updateUpgradeMessage(transformUpgradePlanMessage(err.response.data))
          );
      });
  };
