import ApartmentIcon from '@mui/icons-material/Apartment';
import {
  eCommerceRoute,
  organisationNewRoute,
  subscriptionRoute,
  technologiesRoute,
  userListRoute,
} from "./routesLinks";
import {
  ECOMMERCE_FLAG,
  ORGANISATION_NEW_FLAG,
  SUBSCRIPTION_FLAG,
  TECHNOLOGY_FLAG,
  USER_FACET_FLAG,
} from "../config/app";
import ListAltIcon from "@material-ui/icons/ListAlt";
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import SubscriptionsIcon from "@material-ui/icons/Subscriptions";
import AccountTreeIcon from '@mui/icons-material/AccountTree';

export const navigationLinks = [
  {
    id: 4,
    menuTitle: "Technologies",
    icon: <AccountTreeIcon />,
    url: technologiesRoute,
    isInBeta: false,
    toShow: TECHNOLOGY_FLAG,
  },
  {
    id: 2,
    menuTitle: "Organisations",
    icon: <ApartmentIcon />,
    url: organisationNewRoute,
    isInBeta: false,
    toShow: ORGANISATION_NEW_FLAG,
  },
  {
    id: 3,
    menuTitle: "Ecommerce",
    icon: <ShoppingCartOutlinedIcon />,
    url: eCommerceRoute,
    isInBeta: false,
    toShow: ECOMMERCE_FLAG,
  },

  {
    id: 5,
    menuTitle: "Lists",
    icon: <ListAltIcon />,
    url: userListRoute,
    isInBeta: false,
    toShow: USER_FACET_FLAG,
  },
  {
    id: 6,
    menuTitle: "Subscriptions",
    icon: <SubscriptionsIcon />,
    url: subscriptionRoute,
    isInBeta: false,
    toShow: SUBSCRIPTION_FLAG,
  },
];
