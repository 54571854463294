import { useAuth0 } from "@auth0/auth0-react";
import { Grid, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { monthlyConstant, yearlyConstant } from "../../constants/SiteConstants";
import { useGetToken } from "../../data/authSelector";
import { updateSubscriptionPlan } from "../../data/subscription/action";
import { useGetSubscriptionInfo } from "../../data/subscription/selector";
import { showError } from "../commoncomponent/Client";
import LoadingComponent from "../commoncomponent/LoadingComponent";
import { TabButtonContainer } from "../ecommerce/style";
import { useDataState } from "../hooks/common/hooks";
import BillingPortalView from "./BillingPortalView";
import PlansCardView from "./PlansCardView";
import { CaptionTitleText, TitleHighlightText } from "./style";
import { getSubscriptionData } from "./util";

const SubscriptionView = () => {
  const dispatch = useDispatch();
  const { logout } = useAuth0();
  const accessToken = useSelector(useGetToken);
  const { heading, subHeading, planData } = useSelector(useGetSubscriptionInfo);
  const selectedPackType = useDataState<string>(monthlyConstant);

  useEffect(() => {
    accessToken &&
      getSubscriptionData()
        .then((data) => {
          dispatch(updateSubscriptionPlan(data));
        })
        .catch((err) => {
          showError(err, logout);
        });
  }, [accessToken]);

  return (
    <LoadingComponent loading={planData.length === 0}>
      <>
        <Grid style={{ marginTop: 30 }} container justifyContent={"center"}>
          <Typography>
            <TitleHighlightText>{heading}</TitleHighlightText>{" "}
          </Typography>
        </Grid>
        <Grid container style={{ marginTop: 10 }} justifyContent={"center"}>
          <CaptionTitleText>{subHeading}</CaptionTitleText>
          <BillingPortalView />
        </Grid>
        <Grid container justifyContent={"center"} style={{ marginTop: 20 }}>
          <TabButtonContainer
            disableTouchRipple
            color={
              selectedPackType.value === monthlyConstant ? "primary" : "default"
            }
            variant={
              selectedPackType.value === monthlyConstant ? "contained" : "text"
            }
            onClick={() => selectedPackType.set(monthlyConstant)}
          >
            Monthly
          </TabButtonContainer>
          <TabButtonContainer
            disableTouchRipple
            color={
              selectedPackType.value === yearlyConstant ? "primary" : "default"
            }
            onClick={() => selectedPackType.set(yearlyConstant)}
            variant={
              selectedPackType.value === yearlyConstant ? "contained" : "text"
            }
          >
            Yearly
          </TabButtonContainer>
        </Grid>
        <Grid
          container
          style={{ marginTop: 20, padding: "0px 40px" }}
          spacing={3}
          // alignItems={"stretch"}
          justifyContent={"center"}
        >
          {planData.map((item) => (
            <Grid
              xs={12}
              md={6}
              lg={3}
              item
              key={item.name}
              style={{ marginBottom: 15, maxWidth: 325 }}
            >
              <PlansCardView
                planData={item}
                planSubscriptionPlan={selectedPackType.value || ""}
              />
            </Grid>
          ))}
        </Grid>
      </>
    </LoadingComponent>
  );
};

export default SubscriptionView;
