import { LogoutOptions } from "@auth0/auth0-spa-js";
import { AxiosRequestConfig } from "axios";
import { toast } from "react-toastify";
import { Dispatch } from "redux";
import { IActionPayload } from "../../component/commoncomponent/Base";
import { Client, showError } from "../../component/commoncomponent/Client";
import {
  addNewDomainListApiUrl,
  deleteUserListApiUrl,
  getLabelListApiUrl,
  truncateUserListApiUrl,
  uploadDomainFileApiUrl,
} from "../../constants/ApiConstants";
import { successToastConfiguration } from "../../constants/ColorConstants";
import { trackEvent } from "../../service/mixpanel";
import { transformUpgradePlanMessage, updateUpgradeMessage } from "../action";
import { DELETE_USER_LIST, LabelType, UPDATE_LABEL_LIST } from "./type";
import { transformUserList } from "./util";

export const updateLabelList = (
  labels: LabelType[]
): IActionPayload<LabelType[]> => ({
  type: UPDATE_LABEL_LIST,
  payload: labels,
});

export const getUserListAction =
  (logout: (options?: LogoutOptions | undefined) => void) =>
  (dispatch: Dispatch) => {
    trackEvent("GET_USER_LISTS");
    Client.getInstance()
      .getData(getLabelListApiUrl(), true)
      .then((res) => {
        dispatch({
          type: UPDATE_LABEL_LIST,
          payload: res.data.lists ? transformUserList(res.data.lists) : [],
        });
      })
      .catch((err) => {
        showError(err, logout);
        err.statusCode === 402 &&
          dispatch(
            updateUpgradeMessage(transformUpgradePlanMessage(err.response.data))
          );
      });
  };

export const deleteUserListAction =
  (sid: string, logout: (options?: LogoutOptions | undefined) => void) =>
  (dispatch: Dispatch) => {
    trackEvent("DELETE_USER_LISTS", { sid: sid });
    Client.getInstance()
      .createData(deleteUserListApiUrl(sid), {})
      .then(() => {
        toast.success("List deleted", successToastConfiguration);
        dispatch({
          type: DELETE_USER_LIST,
          payload: sid,
        });
      })
      .catch((err) => {
        showError(err, logout);
        err.statusCode === 402 &&
          dispatch(
            updateUpgradeMessage(transformUpgradePlanMessage(err.response.data))
          );
      });
  };

export const truncateUserListAction = (sid: string) => {
  trackEvent("TRUNCATE_USER_LISTS", { sid: sid });
  return Client.getInstance()
    .createData(truncateUserListApiUrl(sid), {})
    .then(() => Promise.resolve())
    .catch((err) => Promise.reject(err));
};

export const addDomainList = (domainName: string) => {
  trackEvent("ADD_NEW_DOMAIN_LIST", { name: domainName });
  return Client.getInstance()
    .createData(addNewDomainListApiUrl(), {
      name: domainName,
    })
    .then(() => Promise.resolve())
    .catch((err) => Promise.reject(err));
};

export const uploadDomainList = (
  file: File,
  id: string,
  options: AxiosRequestConfig
) => {
  trackEvent("UPLOAD_DOMAIN_FILE");
  const formData = new FormData();
  formData.append("file", file);
  return Client.getInstance()
    .createData(uploadDomainFileApiUrl(id), formData, true, options)
    .then(() => Promise.resolve())
    .catch((err) => Promise.reject(err));
};
