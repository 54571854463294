import { iconsColor } from "../constants/ColorConstants";
import { FaFacebookF } from "react-icons/fa";
import { BiLink } from "react-icons/bi";
import { AiFillInstagram } from "react-icons/ai";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { AiFillYoutube } from "react-icons/ai";
import { FaTiktok } from "react-icons/fa";

const socialIcons: { [key: string]: JSX.Element | string } = {
  fb: <FaFacebookF style={{ fontSize: 14, color: iconsColor }} />,
  it: <AiFillInstagram style={{ fontSize: 14, color: iconsColor }} />,
  tw: <FaTwitter style={{ fontSize: 14, color: iconsColor }} />,
  ln: <FaLinkedinIn style={{ fontSize: 14, color: iconsColor }} />,
  yt: <AiFillYoutube style={{ fontSize: 14, color: iconsColor }} />,
  tk: <FaTiktok style={{ fontSize: 14, color: iconsColor }} />,
  website: <BiLink style={{ fontSize: 14, color: iconsColor }} />,
};

export const getSocialIcons = (type: string) => socialIcons[type] || "";
