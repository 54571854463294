import React, { FC } from "react";
import { FeedbackFish } from "@feedback-fish/react";
import configData from "../../config";
import { useAuth0 } from "@auth0/auth0-react";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import FeedbackIcon from "@material-ui/icons/Feedback";
import { HtmlToolTipWrapper } from "../drawer/SideNavbarStyle";
import { toolTipValue } from "../drawer/Util";

type FeedbackModalProps = {
  isDrawerOpen: boolean;
};
const FeedbackModal: FC<FeedbackModalProps> = ({ isDrawerOpen }) => {
  const { user } = useAuth0();
  return (
    <FeedbackFish
      projectId={configData.FEEDBACK_TOKEN || ""}
      userId={user?.email}
    >
      <HtmlToolTipWrapper
        isselected={0}
        title={!isDrawerOpen ? toolTipValue("Give Feedback") : ""}
        placement={"right-start"}
      >
        <ListItem button>
          <ListItemIcon style={{ color: "white", minWidth: 40 }}>
            <FeedbackIcon />
          </ListItemIcon>
          <ListItemText style={{ color: "white" }}>Give feedback</ListItemText>
        </ListItem>
      </HtmlToolTipWrapper>
    </FeedbackFish>
  );
};

export default FeedbackModal;
