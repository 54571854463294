import { cloneDeep } from "lodash";
import { IActionPayload } from "../../component/commoncomponent/Base";
import {
  CLEAR_ECOMMERCE_URL,
  UPDATE_ECOMMERCE_FILTERS,
  UPDATE_ECOMM_FOCUSED_SEARCHED_TEXT,
  UPDATE_ECOMM_LABEL_LIST,
  UPDATE_ECOMM_SEARCHED_TEXT,
  UPDATE_SCROLL_POSITION,
  UPDATE_SELECTED_SIDS,
  UPDATE_ECOMM_SHOW_GRAPH_VIEW,
} from "./action";
import { EcommerceFilterType } from "./type";

export const initialEcommerceFilters: EcommerceFilterType = {
  filter: [],
  selectedSids: [],
  url: "",
  scrollPosition: 0,
  labels: [],
  searchedText: "",
  searchedTextFocused: false,
  showGraphView: false,
};

export const ecommerceFilterReducer = (
  state = initialEcommerceFilters,
  action: IActionPayload<any>
) => {
  const stateClone = cloneDeep(state);

  switch (action.type) {
    case UPDATE_ECOMMERCE_FILTERS:
      stateClone.filter = action.payload;
      return stateClone;

    case UPDATE_SCROLL_POSITION:
      stateClone.scrollPosition = action.payload;
      return stateClone;

    case UPDATE_SELECTED_SIDS:
      stateClone.selectedSids = action.payload;
      return stateClone;

    case CLEAR_ECOMMERCE_URL:
      stateClone.url = "";
      return stateClone;

    case UPDATE_ECOMM_LABEL_LIST:
      stateClone.labels = action.payload;
      return stateClone;

    case UPDATE_ECOMM_SEARCHED_TEXT:
      stateClone.searchedText = action.payload;
      return stateClone;

    case UPDATE_ECOMM_FOCUSED_SEARCHED_TEXT:
      stateClone.searchedTextFocused = action.payload;
      return stateClone;

    case UPDATE_ECOMM_SHOW_GRAPH_VIEW:
      stateClone.showGraphView = action.payload;
      return stateClone;

    default:
      return state;
  }
};
