import { useAuth0 } from "@auth0/auth0-react";
import { Button, Grid } from "@material-ui/core";
import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetAdvanceSelectedOptions } from "../../data/advancesearch/selector";
import { updateAdvanceSelectedSearchOptions } from "../../data/advancesearch/action";
import { getOrgAdvanceSearchConfigAction } from "../../data/organisation/action";
import { showError } from "../commoncomponent/Client";
import AdvanceOptions from "../ecommerce/EcommerceAdvance/AdvanceOptions";
import {
  SelectedFieldContainer,
  RemoveFieldButton,
} from "../ecommerce/EcommerceAdvance/AdvanceStyle";
import { getOrgPrepopulatedAdvanceSearch } from "./util";
import { textConstant } from "../../constants/SiteConstants";

type OrganisationAdvanceSearchProps = {
  handleAdvanceSearch: () => void;
};

const OrganisationAdvanceSearch: FC<OrganisationAdvanceSearchProps> = ({
  handleAdvanceSearch,
}) => {
  const dispatch = useDispatch();
  const { logout } = useAuth0();
  const advanceFilterLists = useSelector(useGetAdvanceSelectedOptions);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    (searchParams.get("sid") || searchParams.get(textConstant)) &&
      getOrgPrepopulatedAdvanceSearch(
        searchParams.get("sid") || "",
        searchParams.get(textConstant) || ""
      )
        .then((res) => {
          dispatch(updateAdvanceSelectedSearchOptions(res));
        })
        .catch((err) => dispatch(showError(err)));
  }, [location.pathname]);

  useEffect(() => {
    dispatch(getOrgAdvanceSearchConfigAction(logout));
    return () => {
      dispatch(updateAdvanceSelectedSearchOptions([]));
    };
  }, []);

  const handleAddFilter = () => {
    dispatch(
      updateAdvanceSelectedSearchOptions([
        ...advanceFilterLists,
        {
          fieldName: "",
          id: Math.random(),
          operation: "req",
          value: [],
          fieldType: "",
          selectedValuePair: [],
          text: "",
        },
      ])
    );
  };

  const handleRemoveFilter = (id: number) => {
    dispatch(
      updateAdvanceSelectedSearchOptions(
        advanceFilterLists.filter((item) => item.id !== id)
      )
    );
  };

  return (
    <Grid>
      {advanceFilterLists.map((item) => (
        <SelectedFieldContainer key={item.id}>
          <AdvanceOptions key={item.id} optionInfo={item} />
          <RemoveFieldButton
            fullWidth
            onClick={() => handleRemoveFilter(item.id)}
            variant={"outlined"}
            color={"secondary"}
          >
            Remove filter
          </RemoveFieldButton>
        </SelectedFieldContainer>
      ))}
      <Grid style={{ padding: 10 }}>
        <Button
          fullWidth
          onClick={handleAddFilter}
          variant={"text"}
          color={"primary"}
        >
          {advanceFilterLists.length ? "Add another filter" : "Add filter"}
        </Button>
        <Button
          onClick={handleAdvanceSearch}
          style={{ marginTop: 10 }}
          fullWidth
          variant={"contained"}
          color={"primary"}
        >
          Execute Search
        </Button>
      </Grid>
    </Grid>
  );
};

export default OrganisationAdvanceSearch;
