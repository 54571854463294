import { GridCellParams } from "@material-ui/data-grid";
import React, { useMemo } from "react";
import {
  CheckIconColor,
  CrossIconColor,
} from "../../../../constants/ColorConstants";
import { getDomainImage } from "../../../../utils/utility";
import { DomainImageContainer } from "../../../ecommerce/style";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { CompanyLogo } from "../../../commoncomponent/style";

export const useTechLossFilteredListColumns = () =>
  useMemo(
    () => [
      {
        field: "domain",
        headerName: "Domain",
        flex: 2,
        renderCell: (params: GridCellParams) => (
          <DomainImageContainer>
            <CompanyLogo src={getDomainImage(params.row.domain)} />
            <span>{params.row.domain}</span>
          </DomainImageContainer>
        ),
      },
      {
        field: "country",
        headerName: "Country",
        flex: 1,
      },
      {
        field: "contact",
        headerName: "Contact",
        flex: 1,
        renderCell: (params: GridCellParams) =>
          params.row.contact === "yes" ? (
            <CheckIcon style={{ color: CheckIconColor }} />
          ) : (
            <CloseIcon style={{ color: CrossIconColor }} />
          ),
      },
      {
        field: "removedIn",
        headerName: "Removed In",
        flex: 1,
      },
    ],
    []
  );
