import { useAuth0 } from "@auth0/auth0-react";
import { Grid, Button } from "@material-ui/core";
import React, { FC, useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { eCommerceRoute } from "../../constants/routesLinks";
import { ecommConstant, textDebounceTime } from "../../constants/SiteConstants";
import { updateAdvanceSelectedSearchOptions } from "../../data/advancesearch/action";
import {
  updateSids,
  clearEcommerceUrl,
  updateScrollPosition,
  updateEcommerceFilters,
  updateEcommSearchedText,
  updateEcommFocusedSearchedText,
  getAdvanceSearchAction,
} from "../../data/ecommerceStore/action";
import {
  ecommerceFilterOptions,
  useGetEcommIsFocussedSearchedText,
  useGetEcommSearchedText,
  useGetScrollPosition,
  useGetSelectedSids,
} from "../../data/ecommerceStore/ecommerceSelector";
import { showError } from "../commoncomponent/Client";
import { HorizontalDivider } from "../commoncomponent/style";
import { DrawerNavigation } from "../drawer/SideNavbarStyle";
import LabelList from "../Label/LabelList";
import EcommerceAdvanceSearch from "./EcommerceAdvance/EcommerceAdvanceSearch";
import { useShowFilter } from "./hooks";
import { getEcommerceResults, getEcommSearchUrl } from "./util";
import TextFieldWrapper from "../commoncomponent/TextFieldWrapper";
import { debounce } from "lodash";
import BarChartIcon from "@mui/icons-material/BarChart";
import TableViewIcon from "@mui/icons-material/TableView";
import { PrimaryButtonColor } from "../../constants/ColorConstants";
import { SHOW_GRAPH_FLAG } from "../../config/app";
import SearchTechnology from "../technology/SearchTechnology";

type EcommerceFilterSectionProps = {
  handleAdvanceSearch: () => void;
  isAdvanceSearchOpen: boolean;
  setIsAdvanceSearchOpen: () => void;
  handleGraphChange: () => void;
  showChartView: boolean;
};
const EcommerceFilterSection: FC<EcommerceFilterSectionProps> = ({
  handleAdvanceSearch,
  isAdvanceSearchOpen,
  setIsAdvanceSearchOpen,
  handleGraphChange,
  showChartView,
}) => {
  const filters = useSelector(ecommerceFilterOptions);
  const scrollRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedSids = useSelector(useGetSelectedSids);
  const scrollPosition = useSelector(useGetScrollPosition);
  const { logout } = useAuth0();
  const ecommerceSearchText = useSelector(useGetEcommSearchedText);
  const isSearchTextFocused = useSelector(useGetEcommIsFocussedSearchedText);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollPosition;
    }
  }, [scrollRef.current]);

  const updateFilters = () => {
    isAdvanceSearchOpen && dispatch(getAdvanceSearchAction(logout));
    getEcommerceResults(location.search, 0)
      .then((data) => {
        dispatch(updateEcommerceFilters(data.filter));
      })
      .catch((error) => {
        dispatch(showError(error, logout));
      });
  };

  const handleSelect = (sid: string) => {
    scrollRef.current &&
      dispatch(updateScrollPosition(scrollRef.current.scrollTop));
    if (selectedSids.find((item) => item === sid)) {
      const filteredData = selectedSids.filter((item) => item !== sid);
      dispatch(updateSids(filteredData));
      history.push(
        getEcommSearchUrl(filteredData, ecommerceSearchText, location.search)
      );
      return;
    }
    if (!selectedSids) {
      return;
    }
    dispatch(updateSids([...selectedSids, sid]));
    history.push(
      getEcommSearchUrl(
        [...selectedSids, sid],
        ecommerceSearchText,
        location.search
      )
    );
  };

  const showFilter = useShowFilter(filters, selectedSids, handleSelect);

  const handleTextChange = useCallback(
    debounce((text) => {
      history.push(getEcommSearchUrl(selectedSids, text, location.search));
    }, textDebounceTime),
    []
  );

  const handleClearClick = () => {
    if (isAdvanceSearchOpen) {
      dispatch(updateAdvanceSelectedSearchOptions([]));
      return;
    }
    dispatch(updateSids([]));
    dispatch(clearEcommerceUrl(""));
    dispatch(updateScrollPosition(0));
    history.push(eCommerceRoute);
  };

  return (
    <DrawerNavigation ref={scrollRef}>
      <Grid
        container
        justifyContent={"space-between"}
        style={{ margin: `0 5px 5px 0` }}
        alignItems={"center"}
      >
        <LabelList
          selectedSids={selectedSids}
          updateFilters={updateFilters}
          type={ecommConstant}
          labelSaveType={isAdvanceSearchOpen ? "advance" : "basic"}
        />
        {SHOW_GRAPH_FLAG === "1" &&
          (!showChartView ? (
            <BarChartIcon
              style={{ color: PrimaryButtonColor, cursor: "pointer" }}
              onClick={handleGraphChange}
            />
          ) : (
            <TableViewIcon
              style={{ color: PrimaryButtonColor, cursor: "pointer" }}
              onClick={handleGraphChange}
            />
          ))}
        <Button
          onClick={setIsAdvanceSearchOpen}
          size={"small"}
          color={"primary"}
          variant={isAdvanceSearchOpen ? "outlined" : "text"}
        >
          {isAdvanceSearchOpen ? "Hide Advanced" : "Advanced"}
        </Button>
        <Button
          onClick={handleClearClick}
          variant={"text"}
          size={"small"}
          color={"primary"}
        >
          Clear
        </Button>
      </Grid>
      <HorizontalDivider />
      {!isAdvanceSearchOpen && (
        <TextFieldWrapper
          onFocus={() => dispatch(updateEcommFocusedSearchedText(true))}
          onBlur={() => dispatch(updateEcommFocusedSearchedText(false))}
          variant={"outlined"}
          autoFocus={isSearchTextFocused}
          size={"small"}
          style={{ margin: "10px 0" }}
          value={ecommerceSearchText}
          label={"Search Text"}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              history.push(
                getEcommSearchUrl(
                  selectedSids,
                  ecommerceSearchText,
                  location.search
                )
              );
            }
          }}
          onChange={({ target }) => {
            dispatch(updateEcommSearchedText(target.value));
            handleTextChange(target.value);
          }}
        />
      )}
      {!isAdvanceSearchOpen && (
        <Grid style={{ marginBottom: 10 }}>
          <SearchTechnology handleSelect={handleSelect} />
        </Grid>
      )}
      {isAdvanceSearchOpen ? (
        <EcommerceAdvanceSearch handleAdvanceSearch={handleAdvanceSearch} />
      ) : (
        showFilter
      )}
    </DrawerNavigation>
  );
};

export default EcommerceFilterSection;
