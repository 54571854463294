import { IRootReducer } from "../combineReducer";

export const useGetTechCategoryOptions = (state: IRootReducer) =>
  state.techFilter.category;
export const useGetTechSelectedSids = (state: IRootReducer) =>
  state.techFilter.selectedSid;
export const useGetAlreadySelected = (state: IRootReducer) =>
  state.techFilter.alreadySelected;
export const useGetTechLoading = (state: IRootReducer) =>
  state.techFilter.isLoading;
export const useGetTechScrollPosition = (state: IRootReducer) =>
  state.techFilter.scrollPosition;
