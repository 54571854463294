import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { operationConstant, textConstant } from "../../../constants/SiteConstants";
import { updateAdvanceSelectedSearchOptions } from "../../../data/advancesearch/action";
import { useGetAdvanceSelectedOptions } from "../../../data/advancesearch/selector";
import {
  AdvanceSearchOptionsType,
  SelectedAdvanceFilterValueType,
} from "../../../data/advancesearch/type";
import TextFieldWrapper from "../../commoncomponent/TextFieldWrapper";
import AdvanceSelectOptionDisplayView from "./AdvanceSelectOptionDisplayView";

type AdvanceSelectedTextfieldViewProps = {
  selectedFilterInfo?: SelectedAdvanceFilterValueType;
  selectedOptionInfo?: AdvanceSearchOptionsType;
  optionInfoId: number;
};

const AdvanceSelectedTextfieldView: FC<AdvanceSelectedTextfieldViewProps> = ({
  selectedFilterInfo,
  selectedOptionInfo,
  optionInfoId,
}) => {
  const addEcommFilters = useSelector(useGetAdvanceSelectedOptions);
  const dispatch = useDispatch();

  if (!selectedFilterInfo || !selectedOptionInfo) {
    return <></>;
  }

  const handleChange = (selectedOption: string, type: string) => {
    const index = addEcommFilters.findIndex((item) => item.id === optionInfoId);
    if (type === operationConstant) {
      addEcommFilters[index].operation = selectedOption;
    }
    if (type === textConstant) {
      addEcommFilters[index].text = selectedOption;
    }
    dispatch(updateAdvanceSelectedSearchOptions(addEcommFilters));
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
      <AdvanceSelectOptionDisplayView
        displayAs={selectedOptionInfo.selectionConfig.displayAs || ""}
        handleChange={(selectedOption: string) =>
          handleChange(selectedOption, operationConstant)
        }
        options={selectedOptionInfo.selectionConfig.options || []}
        value={selectedFilterInfo.operation || ""}
        label={selectedOptionInfo.selectionConfig.name || ""}
        isMultiple={selectedOptionInfo.selectionConfig.chooseMultiple}
      />
      <TextFieldWrapper
        label={"Text"}
        value={selectedFilterInfo.text}
        onChange={({ target }) => handleChange(target.value, textConstant)}
      />
    </div>
  );
};

export default AdvanceSelectedTextfieldView;
