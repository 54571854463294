import { Button, Dialog } from "@material-ui/core";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { FC } from "react";
import LoadingComponent from "../commoncomponent/LoadingComponent";
import { useDataState } from "../hooks/common/hooks";

type AddListModalProps = {
  handleClose: () => void;
  handleSubmit: (domainName: string) => void;
  isLoading: boolean;
};

const AddListModal: FC<AddListModalProps> = ({
  handleClose,
  handleSubmit,
  isLoading,
}) => {
  const listName = useDataState<string>();

  return (
    <Dialog open onClose={handleClose} fullWidth>
      <DialogTitle>Create New List</DialogTitle>
      <DialogContent>
        <TextField
          value={listName.value}
          onChange={({ target }) => listName.set(target.value)}
          label={"List Name"}
          size={"small"}
          variant={"standard"}
          fullWidth
        />
      </DialogContent>
      <DialogActions style={{ justifyContent: "center", gap: "20px" }}>
        <Button variant={"text"} onClick={handleClose} color={"primary"}>
          Cancel
        </Button>
        <LoadingComponent loading={isLoading} loadingVariant={"static"}>
          <Button
            onClick={() => handleSubmit(listName.value || "")}
            disabled={listName.value === ""}
            variant={"contained"}
            color={"primary"}
          >
            Submit
          </Button>
        </LoadingComponent>
      </DialogActions>
    </Dialog>
  );
};

export default AddListModal;
