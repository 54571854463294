import { Grid, Button } from "@material-ui/core";
import Styled from "styled-components";

export const DataGridContainer = Styled.span`
    height: 95%;
    & .MuiDataGrid-window {
        overflow-x: hidden;
        &::-webkit-scrollbar {
      width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #d2d1d1;
    border-radius: 10px;
  }
}`;

interface EcommerceParentContainerProps {
  isurlvalid: number;
}

export const EcommerceParentContainer = Styled(Grid)`
  justify-content: ${(props: EcommerceParentContainerProps) =>
    !props.isurlvalid && "center"};
  margin-top: ${(props: EcommerceParentContainerProps) =>
    !props.isurlvalid && "50px"};
`;

export const DomainImageContainer = Styled.div`
  display: inline-flex;
  align-items: center;
  gap: 10px;
`;

export const TabButtonContainer = Styled(Button)`
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  text-transform: none;
`;

type GraphDataGridContainerType = {
  height?: string;
  overflow?: string;
};
export const GraphDataGridContainer = Styled(Grid)`
  padding: 5px 20px;
  height: ${(props: GraphDataGridContainerType) => props.height || "auto"};
  overflow: ${(props: GraphDataGridContainerType) => props.overflow};
`;
