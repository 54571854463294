import { useAuth0 } from "@auth0/auth0-react";
import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { successToastConfiguration } from "../../constants/ColorConstants";
import { useGetToken } from "../../data/authSelector";
import { showError } from "../commoncomponent/Client";
import { getDownloadFile } from "./util";

const DownloadFileView = () => {
  const { id } = useParams<{
    id: string;
  }>();
  const accessToken = useSelector(useGetToken);
  const dispatch = useDispatch();
  const { logout } = useAuth0();

  useEffect(() => {
    accessToken !== "" &&
      getDownloadFile(id)
        .then(() => {
          toast.success(
            "File downloaded successfully",
            successToastConfiguration
          );
        })
        .catch((err) => {
          dispatch(showError(err, logout));
        });
  }, [accessToken]);

  return <Grid container />;
};

export default DownloadFileView;
