import { useAuth0 } from "@auth0/auth0-react";
import { Grid, Button } from "@material-ui/core";
import { debounce } from "lodash";
import React, { FC, useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { organisationNewRoute } from "../../constants/routesLinks";
import { orgConstant, textDebounceTime } from "../../constants/SiteConstants";
import { updateAdvanceSelectedSearchOptions } from "../../data/advancesearch/action";
import {
  getOrgAdvanceSearchConfigAction,
  updateOrganisationFilters,
  updateOrgFocusedSearchedText,
  updateOrgScrollPosition,
  updateOrgSearchedText,
  updateOrgSids,
} from "../../data/organisation/action";
import {
  organisationFilterOptions,
  useGetOrgIsFocusedSearchedText,
  useGetOrgScrollPosition,
  useGetOrgSearchedText,
  useGetOrgSelectedSids,
} from "../../data/organisation/organisationSelector";
import { HorizontalDivider } from "../commoncomponent/style";
import TextFieldWrapper from "../commoncomponent/TextFieldWrapper";
import { DrawerNavigation } from "../drawer/SideNavbarStyle";
import { useShowFilter } from "../ecommerce/hooks";
import LabelList from "../Label/LabelList";
import SearchTechnology from "../technology/SearchTechnology";
import OrganisationAdvanceSearch from "./OrganisationAdvanceSearch";
import { getOrganisationResults, getOrgSearchUrl } from "./util";
import BarChartIcon from "@mui/icons-material/BarChart";
import TableViewIcon from "@mui/icons-material/TableView";
import { PrimaryButtonColor } from "../../constants/ColorConstants";
import { SHOW_GRAPH_FLAG } from "../../config/app";

type OrganisationFilterSectionProps = {
  handleAdvanceSearch: () => void;
  isAdvanceSearchOpen: boolean;
  setIsAdvanceSearchOpen: () => void;
  handleGraphChange: () => void;
  showChartView: boolean;
};

const OrganisationFilterSection: FC<OrganisationFilterSectionProps> = ({
  handleAdvanceSearch,
  isAdvanceSearchOpen,
  setIsAdvanceSearchOpen,
  handleGraphChange,
  showChartView,
}) => {
  const filters = useSelector(organisationFilterOptions);
  const scrollRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedSids = useSelector(useGetOrgSelectedSids);
  const scrollPosition = useSelector(useGetOrgScrollPosition);
  const orgSearchText = useSelector(useGetOrgSearchedText);
  const isSearchTextFocused = useSelector(useGetOrgIsFocusedSearchedText);
  const { logout } = useAuth0();

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollPosition;
    }
  }, [scrollRef.current]);

  const handleSelect = (sid: string) => {
    scrollRef.current &&
      dispatch(updateOrgScrollPosition(scrollRef.current.scrollTop));
    if (selectedSids.find((item) => item === sid)) {
      const filteredData = selectedSids.filter((item) => item !== sid);

      dispatch(updateOrgSids(filteredData));
      history.push(
        getOrgSearchUrl(filteredData, orgSearchText, location.search)
      );
      return;
    }
    if (!selectedSids) {
      return;
    }
    dispatch(updateOrgSids([...selectedSids, sid]));
    history.push(
      getOrgSearchUrl([...selectedSids, sid], orgSearchText, location.search)
    );
  };

  const showFilter = useShowFilter(filters, selectedSids, handleSelect);

  const updateFilters = () => {
    isAdvanceSearchOpen && dispatch(getOrgAdvanceSearchConfigAction(logout));
    getOrganisationResults(location.search, 0).then((data) => {
      dispatch(updateOrganisationFilters(data.filter));
    });
  };

  const handleClearClick = () => {
    if (isAdvanceSearchOpen) {
      dispatch(updateAdvanceSelectedSearchOptions([]));
      return;
    }
    dispatch(updateOrgSids([]));
    dispatch(updateOrgScrollPosition(0));
    history.push(organisationNewRoute);
  };

  const handleTextChange = useCallback(
    debounce((text) => {
      history.push(getOrgSearchUrl(selectedSids, text, location.search));
    }, textDebounceTime),
    []
  );

  return (
    <DrawerNavigation ref={scrollRef}>
      <Grid container justifyContent={"space-between"}>
        <LabelList
          selectedSids={selectedSids}
          updateFilters={updateFilters}
          type={orgConstant}
          labelSaveType={isAdvanceSearchOpen ? "advance" : "basic"}
        />
        {SHOW_GRAPH_FLAG === "1" &&
          (!showChartView ? (
            <BarChartIcon
              style={{ color: PrimaryButtonColor, cursor: "pointer" }}
              onClick={handleGraphChange}
            />
          ) : (
            <TableViewIcon
              style={{ color: PrimaryButtonColor, cursor: "pointer" }}
              onClick={handleGraphChange}
            />
          ))}
        <Button
          onClick={setIsAdvanceSearchOpen}
          size={"small"}
          color={"primary"}
          variant={isAdvanceSearchOpen ? "outlined" : "text"}
        >
          {isAdvanceSearchOpen ? "Hide Advanced" : "Advanced"}
        </Button>
        <Button
          onClick={handleClearClick}
          variant={"text"}
          size={"small"}
          color={"primary"}
        >
          Clear
        </Button>
      </Grid>
      <HorizontalDivider style={{ marginTop: 5 }} />
      {!isAdvanceSearchOpen && (
        <TextFieldWrapper
          onFocus={() => dispatch(updateOrgFocusedSearchedText(true))}
          onBlur={() => dispatch(updateOrgFocusedSearchedText(false))}
          variant={"outlined"}
          autoFocus={isSearchTextFocused}
          size={"small"}
          style={{ margin: "10px 0px" }}
          value={orgSearchText}
          label={"Search Text"}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              history.push(
                getOrgSearchUrl(selectedSids, orgSearchText, location.search)
              );
            }
          }}
          onChange={({ target }) => {
            dispatch(updateOrgSearchedText(target.value));
            handleTextChange(target.value);
          }}
        />
      )}
      {!isAdvanceSearchOpen && (
        <Grid style={{ marginBottom: 10 }}>
          <SearchTechnology handleSelect={handleSelect} />
        </Grid>
      )}

      {isAdvanceSearchOpen ? (
        <OrganisationAdvanceSearch handleAdvanceSearch={handleAdvanceSearch} />
      ) : (
        showFilter
      )}
    </DrawerNavigation>
  );
};

export default OrganisationFilterSection;
