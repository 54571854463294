import { listConstant, operationLabelConstant, selectValueLabelConstant } from "../../constants/SiteConstants";

const transformOptions = (res: any) =>
  res.map((item: any) => ({
    name: item.name,
    value: item.val,
  }));

export const transformAdvanceSearch = (res: any) => {
  const filterTypes = res.map((item: any) => ({
    name: item.name,
    value: item.field,
  }));
  const filterValue = res.map((item: any) => ({
    filterType: item.field,
    listData: item.list_data
      ? {
        name:
          item.list_data.name && item.list_data.name !== ""
            ? item.list_data.name
            : selectValueLabelConstant,
        chooseMultiple: item.list_data.choose_multiple,
        options: transformOptions(item.list_data.opts),
        displayAs:
          item.list_data.display_as && item.list_data.display_as !== ""
            ? item.list_data.display_as
            : listConstant,
      }
      : null,
    name: item.name,
    selectionConfig: {
      chooseMultiple: item.selection_list_data.choose_multiple,
      options: transformOptions(item.selection_list_data.opts),
      displayAs:
        item.selection_list_data.display_as &&
          item.selection_list_data.display_as !== ""
          ? item.selection_list_data.display_as
          : listConstant,
      name:
        item.selection_list_data.name !== ""
          ? item.selection_list_data.name
          : operationLabelConstant,
    },
    techConfig: item.tech_cfg
      ? {
        installListData: {
          chooseMultiple: item.tech_cfg.install_list_data.choose_multiple,
          name:
            item.tech_cfg.install_list_data.name &&
              item.tech_cfg.install_list_data.name !== ""
              ? item.tech_cfg.install_list_data.name
              : "Options",
          options: transformOptions(item.tech_cfg.install_list_data.opts),
          displayAs: item.tech_cfg.install_list_data.display_as || "list",
        },
        listData: {
          name:
            item.tech_cfg.list_data.name &&
              item.tech_cfg.list_data.name !== ""
              ? item.tech_cfg.list_data.name
              : "Month",
          options: transformOptions(item.tech_cfg.list_data.opts),
          displayAs: item.tech_cfg.list_data.display_as || "list",
        },
      }
      : null,
  }));
  return {
    filterTypes: filterTypes,
    filterInfo: filterValue,
  };
};
