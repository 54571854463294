import { Paper, Typography } from "@material-ui/core";
import React from "react";
import { FC } from "react";
import { SubText } from "./style";

interface SiteDetailsProps {
  platform: string;
  location?: string[];
  title: string;
}

const SiteDetails: FC<SiteDetailsProps> = ({ title, location, platform }) => {
  return (
    <Paper elevation={0} style={{ padding: 30 }}>
      {title && (
        <Typography variant={"body1"} color={"primary"}>
          Title: <SubText>{title}</SubText>
        </Typography>
      )}
      {platform && (
        <Typography variant={"body1"} color={"primary"}>
          Platform: <SubText>{platform}</SubText>
        </Typography>
      )}
      {location && location.length > 0 && (
        <Typography variant={"body1"} color={"primary"}>
          Country: <SubText>{location.join(",")}</SubText>
        </Typography>
      )}
    </Paper>
  );
};

export default SiteDetails;
