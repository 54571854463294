import React, { FC } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { DialogContent } from "@material-ui/core";
import { DialogContentText } from "@mui/material";

type DeleteConfirmationBoxProps = {
  handleClose: () => void;
  handleSubmit: () => void;
  title: string;
};

const DeleteConfirmationBox: FC<DeleteConfirmationBoxProps> = ({
  handleClose,
  handleSubmit,
  title,
}) => {
  return (
    <div>
      <Dialog
        open={true}
        onClose={handleClose}
        PaperProps={{
          style: {
            padding: 10,
          },
        }}
      >
        <DialogTitle>Delete confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete {title}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color={"error"} onClick={handleClose}>
            Cancel
          </Button>
          <Button variant={"outlined"} onClick={handleSubmit} autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteConfirmationBox;
