import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { env, SENTRY_CLIENT_ID } from "./app";

export const initSentry = () => {
  if (env === "local") {
    return;
  }

  Sentry.init({
    environment: env,
    dsn: SENTRY_CLIENT_ID,
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
};

export const logError = (error: Error, errorInfo?: any) => {
  if (env === "local") {
    return;
  }

  Sentry.withScope((scope) => {
    errorInfo && scope.setExtras(errorInfo);
    Sentry.captureException(error);
  });
};

