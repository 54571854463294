export interface SubscriptionPlansType {
  currentPlan: CurrentPlanType;
  planData: PlansListType[];
  billingPortal: PlanDisplayConfigType;
  heading: string;
  subHeading: string;
}

export interface CurrentPlanType {
  name: string;
  plan: string;
}

export interface PlanDisplayConfigType {
  displayPortal: boolean;
  text: string;
}

export interface PlansListType {
  plan: string;
  prices: PriceType[];
  name: string;
  negativeFeatures: string[];
  positiveFeatures: string[];
  headline: string;
}

export interface PriceType {
  interval: string;
  price: number;
}

export const UPDATE_SUBSCRIPTION_PLANS = "UPDATE_SUBSCRIPTION_PLANS";
