import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateUpgradeMessage } from '../../data/action';
import { useGetUpgradeMessage } from '../../data/authSelector';
import { useBooleanState } from '../hooks/common/hooks';
import UpgradePlanModal from './UpgradePlanModal';

type UpgradePlanProps = {
    children?: React.ReactNode;
}
const UpgradePlan: FC<UpgradePlanProps> = ({
    children
}) => {
    const upgradeMessagePlan = useSelector(useGetUpgradeMessage);
    const showModal = useBooleanState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (upgradeMessagePlan) {
            showModal.setTrue();
        }
        return (() => showModal.setFalse());
    }, [upgradeMessagePlan]);

    return (
        <>
            {
                showModal.value && upgradeMessagePlan && <UpgradePlanModal
                    description={upgradeMessagePlan.params}
                    handleClose={() => {
                        showModal.toggle();
                        dispatch(updateUpgradeMessage(null));
                    }}
                    title={upgradeMessagePlan.heading}
                />
            }
            {children}
        </>
    );
};

export default UpgradePlan;
