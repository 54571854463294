import { Grid, Breadcrumbs, Typography, Button } from "@material-ui/core";
import React, { FC } from "react";
import { useHistory } from "react-router";
import { organisationNewRoute } from "../../../constants/routesLinks";

interface TechnologyTopBreadcrumbSectionProps {
  name: string;
  handleFavouriteChange: () => void;
  isFavourite: boolean;
  sid: string;
}

const TechnologyTopBreadcrumbSection: FC<TechnologyTopBreadcrumbSectionProps> =
  ({ name, handleFavouriteChange, isFavourite, sid }) => {
    const history = useHistory();

    return (
      <Grid container justifyContent={"space-between"}>
        <Grid item>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography style={{ color: "darkgray" }}>You are here</Typography>
            <Typography
              color="primary"
              style={{ cursor: "pointer" }}
              onClick={() => history.goBack()}
            >
              Technology
            </Typography>
            <Typography color="textPrimary">{name}</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Button
            variant={"outlined"}
            size={"small"}
            color={"primary"}
            style={{ marginRight: 5 }}
            onClick={() => history.push(`${organisationNewRoute}?sid=${sid}`)}
          >
            Search technology
          </Button>
          <Button
            variant={"outlined"}
            size={"small"}
            color={!isFavourite ? "primary" : "secondary"}
            onClick={handleFavouriteChange}
          >
            {!isFavourite ? "Add to favourite" : "Remove from favourite"}
          </Button>
        </Grid>
      </Grid>
    );
  };

export default TechnologyTopBreadcrumbSection;
