import { createTheme, ThemeProvider } from "@material-ui/core";
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import Auth0ProviderWithHistory from "./AuthProvider";
import AppNavigation from "./component/AppNavigation";
import ErrorBoundary from "./component/commoncomponent/ErrorBoundary";
import UpgradePlan from "./component/upgradeplan/UpgradePlanView";
import { PrimaryButtonColor } from "./constants/ColorConstants";
import { store } from "./data/store";

const theme = createTheme({
  palette: {
    primary: {
      main: PrimaryButtonColor,
    },
  },
});

const StackBrainApp = () => {
  return (
    <Provider store={store}>
      <ErrorBoundary>
        <BrowserRouter>
          <Auth0ProviderWithHistory>
            <ThemeProvider theme={theme}>
              <UpgradePlan>
                <AppNavigation />
              </UpgradePlan>
            </ThemeProvider>
          </Auth0ProviderWithHistory>
        </BrowserRouter>
      </ErrorBoundary>
    </Provider>
  );
};

export default StackBrainApp;
