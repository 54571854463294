export const MIN_CHAR_FOR_AUTO_COMPLETE = 1;
export const AUTO_COMPLETE_DEBOUNCE_TIME = 1000;
export const TECHNOLOGY = true;
export const KEYWORD = true;
export const LOCATION = true;
export const SOCIAL_MEDIA = false;
export const CONTENT = false;
export const TRAFFIC = false;
export const SHOPIFY = true;
export const API_TIMEOUT = 15000;
