import { Typography } from "@material-ui/core";
import React, { FC } from "react";
import TechnologyCard from "./TechnologyCard";
import { TechGroupsType } from "./type";

interface AppAndTechViewProps {
  technology: TechGroupsType[];
}

const AppAndTechView: FC<AppAndTechViewProps> = ({ technology }) => {
  return (
    <>
      {technology.map((item, index) => (
        <div key={`${item.name}-${index}`}>
          <Typography color={"primary"} style={{ marginBottom: 5 }}>
            {item.name}
          </Typography>
          <TechnologyCard techItem={item.techs} />
        </div>
      ))}
    </>
  );
};

export default AppAndTechView;
