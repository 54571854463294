import _cloneDeep from "lodash/cloneDeep";
import {
  REMOVE_AUTH_DATA,
  UPDATE_ACCESS_TOKEN,
  UPDATE_UPGRADE_MESSAGE,
  UPDATE_USER_DATA,
} from "./action";
import { upgradePlanMessageType } from "./type";

export interface AuthType {
  userData: any;
  accessToken: string;
  upgradeMessage: upgradePlanMessageType | null;
}

const initialState: AuthType = {
  userData: {},
  accessToken: "",
  upgradeMessage: null
};

export const authReducer = (state = initialState, action: any): any => {
  const stateClone = _cloneDeep(state);
  switch (action.type) {
    case UPDATE_ACCESS_TOKEN: {
      stateClone.accessToken = action.payload;
      return stateClone;
    }
    case UPDATE_USER_DATA: {
      stateClone.userData = action.payload;
      return stateClone;
    }
    case UPDATE_UPGRADE_MESSAGE: {
      stateClone.upgradeMessage = action.payload;
      return stateClone;
    }
    case REMOVE_AUTH_DATA: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
