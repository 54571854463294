import TourWrapper from "../component/commoncomponent/tour/TourWrapper";
import { ReactourStepContentArgs } from "reactour";
import { grey } from "@material-ui/core/colors";
export const tourConfig = [
  {
    selector: "#export_org",
    content: (props: ReactourStepContentArgs) => (
      <TourWrapper title={"Export"} contentProps={props}>
        This is the description of the export button.
      </TourWrapper>
    ),
    style: {
      padding: "20px",
      borderRadius: 10,
      boxShadow: `0px 0px 10px ${grey['900']}`,
    },
  },
  {
    selector: "#org_grid",
    content: (props: ReactourStepContentArgs) => (
      <TourWrapper title={"Searched Result"} contentProps={props}>
        This is the description of the Organisation result section.
      </TourWrapper>
    ),
    style: {
      padding: "20px",
      borderRadius: 10,
      boxShadow: `0px 0px 10px ${grey['900']}`,
    },
  },
  {
    selector: "#org_filter",
    content: (props: ReactourStepContentArgs) => (
      <TourWrapper title={"Organisation filters"} contentProps={props}>
        This is the description of the Organisation filter section.
      </TourWrapper>
    ),
    style: {
      padding: "20px",
      borderRadius: 10,
      boxShadow: `0px 0px 10px ${grey['900']}`,
    },
  },
];
