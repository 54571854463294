import { useAuth0 } from "@auth0/auth0-react";
import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { techDetailRoute } from "../../constants/routesLinks";
import { useGetToken } from "../../data/authSelector";
import { updateIsLoading } from "../../data/common/action";
import {
  updateTechFilters,
  updateTechSids,
} from "../../data/technologyStore/action";
import {
  useGetAlreadySelected,
  useGetTechSelectedSids,
} from "../../data/technologyStore/selector";
import { showError } from "../commoncomponent/Client";
import LoadingComponent from "../commoncomponent/LoadingComponent";
import { FilterOptionsGridContainer } from "../commoncomponent/style";
import { useBooleanState, useDataState } from "../hooks/common/hooks";
import TechnologyDataGrid from "./TechnologyDataGrid";
import TechnologyFilterSection from "./TechnologyFilterSection";
import { TechnologyType } from "./type";
import { getCategoryData, getTechnology } from "./util";

const TechnologyView = () => {
  const dispatch = useDispatch();
  const accessToken = useSelector(useGetToken);
  const alreadySelected = useSelector(useGetAlreadySelected);
  const selectedSid = useSelector(useGetTechSelectedSids);
  const technology = useDataState<TechnologyType[]>([]);
  const totalCount = useDataState<string>("");
  const isError = useBooleanState(false);
  const selectedTechId = useDataState<string>("");
  const history = useHistory();
  const selectedTechName = useDataState("");
  const { logout } = useAuth0();

  useEffect(() => {
    !alreadySelected &&
      accessToken &&
      getCategoryData()
        .then((res) => dispatch(updateTechFilters(res)))
        .catch((err) => {
          dispatch(showError(err, logout));
        });
  }, [accessToken, alreadySelected]);

  useEffect(() => {
    if (selectedSid !== "") {
      fetchTechList();
    }
  }, [selectedSid]);

  const fetchTechList = (isLoading = true) => {
    isLoading && dispatch(updateIsLoading(true));
    getTechnology(selectedSid)
      .then((res) => {
        technology.set(res.techs);
        totalCount.set(res.total);
        dispatch(updateIsLoading(false));
      })
      .catch((error) => {
        dispatch(updateIsLoading(true));
        isError.setTrue();
        dispatch(showError(error, logout));
      });
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    accessToken &&
      searchParams.get("sid") &&
      dispatch(updateTechSids(searchParams.get("sid") || ""));
  }, [accessToken]);

  return (
    <>
      <Grid container>
        <FilterOptionsGridContainer item xs={12} sm={3}>
          <TechnologyFilterSection
            setSelectedTechName={(techName) => selectedTechName.set(techName)}
          />
        </FilterOptionsGridContainer>
        <Grid item xs={12} sm={9} style={{ padding: 20 }}>
          <LoadingComponent>
            <TechnologyDataGrid
              techName={selectedTechName.value || ""}
              isTechSelected={!selectedSid}
              list={technology.value || []}
              noResultFound={isError.value}
              totalCount={totalCount.value || ""}
              techSelected={(domain: string) => {
                history.push(`${techDetailRoute}?techsid=${domain}`);
                selectedTechId.set(domain);
              }}
              fetchTechList={fetchTechList}
            />
          </LoadingComponent>
        </Grid>
      </Grid>
    </>
  );
};

export default TechnologyView;
