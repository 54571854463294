import * as features from "./features";
import * as appData from "./app";

const configData = {
  username: "xxtt",
  password: "",
  ...features,
  ...appData,
};

export default configData;
