import React from "react";
import { TextFieldProps } from "@material-ui/core";
import { MatTextField } from "./style";

type TextFieldWrapperProps = {
  onBlur?: () => void;
  isBasic?: boolean;
} & TextFieldProps;

const TextFieldWrapper = (props: TextFieldWrapperProps) => {
  return (
    <>
      <MatTextField
        {...props}
        onBlur={props.onBlur}
        InputLabelProps={{
          style: {
            fontSize: "13px",
          },
        }}
        fullWidth
        size={"small"}
        isbasic={props.isBasic ? 1 : 0}
      />
    </>
  );
};

export default TextFieldWrapper;
