import {
  getAddTechFavouriteApiUrl,
  getDeleteTechFavouriteApiUrl,
  getTechnologyHighlightDetailsApiUrl,
} from "../../../constants/ApiConstants";
import { trackEvent } from "../../../service/mixpanel";
import { Client } from "../../commoncomponent/Client";
import { TechHighlightDetails } from "./type";

const transformData = (data: any): TechHighlightDetails => {
  return {
    description: data.description,
    monthlyDrops: data.monthly_drops,
    monthlyGains: data.monthly_gains,
    name: data.name,
    sid: data.sid,
    total: data.total,
    website: data.website,
    isFavourite: data.is_favourite,
  };
};

export const getSelectedTechnologyHighlightDetails = (techId: string) => {
  trackEvent("TECHNOLOGY_HIGHLIGHT_DETAIL", { techId: techId });
  return Client.getInstance()
    .createData(getTechnologyHighlightDetailsApiUrl(techId), {})
    .then((res) => Promise.resolve(transformData(res.data)))
    .catch((err) => Promise.reject(err));
};

export const getAddFavouriteTech = (domainId: string) => {
  trackEvent("ADD_FAVOURITE_TECH", { domainId: domainId });
  return Client.getInstance()
    .createData(getAddTechFavouriteApiUrl(domainId), {})
    .then(() => Promise.resolve())
    .catch((err) => Promise.reject(err));
};

export const getRemoveFavouriteTech = (domainId: string) => {
  trackEvent("REMOVE_FAVOURITE_TECH", { domainId: domainId });
  return Client.getInstance()
    .createData(getDeleteTechFavouriteApiUrl(domainId), {})
    .then(() => Promise.resolve())
    .catch((err) => Promise.reject(err));
};
