import { IActionPayload } from "../../component/commoncomponent/Base";
import _cloneDeep from "lodash/cloneDeep";
import {
  CLEAR_FILTER_INPUT,
  UPDATE_ORGANISATION_FILTERS,
  UPDATE_ORGANISATION_SCROLL_POSITION,
  UPDATE_ORGANISATION_SELECTED_SIDS,
  UPDATE_ORG_FOCUSED_SEARCHED_TEXT,
  UPDATE_ORG_LABEL_LIST,
  UPDATE_ORG_SEARCHED_TEXT,
  UPDATE_ORG_SHOW_GRAPH_VIEW,
} from "./action";
import { OrganisationFilterValuesType } from "./type";

export const initialFilterValues: OrganisationFilterValuesType = {
  filter: [],
  selectedSids: [],
  scrollPosition: 0,
  labels: [],
  searchedText: "",
  searchedTextFocused: false,
  showGraphView: false,
};

export const filterReducer = (
  state = initialFilterValues,
  action: IActionPayload<any>
) => {
  const stateClone = _cloneDeep(state);

  switch (action.type) {
    case UPDATE_ORGANISATION_FILTERS:
      stateClone.filter = action.payload;
      return stateClone;

    case UPDATE_ORG_LABEL_LIST:
      stateClone.labels = action.payload;
      return stateClone;

    case UPDATE_ORGANISATION_SELECTED_SIDS:
      stateClone.selectedSids = action.payload;
      return stateClone;

    case UPDATE_ORGANISATION_SCROLL_POSITION:
      stateClone.scrollPosition = action.payload;
      return stateClone;

    case UPDATE_ORG_SEARCHED_TEXT:
      stateClone.searchedText = action.payload;
      return stateClone;

    case UPDATE_ORG_FOCUSED_SEARCHED_TEXT:
      stateClone.searchedTextFocused = action.payload;
      return stateClone;

    case CLEAR_FILTER_INPUT:
      return initialFilterValues;

    case UPDATE_ORG_SHOW_GRAPH_VIEW:
      stateClone.showGraphView = action.payload;
      return stateClone;

    default:
      return state;
  }
};
