import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import React, { FC, useMemo, useState } from "react";
import { dataSizeOption, graphOptions } from "../../../constants/optionsConstant";
import {
  BAR_OPTION,
  PIE_OPTION,
  TOP_10_OPTION,
} from "../../../constants/SiteConstants";
import { FilterType } from "../../../data/ecommerceStore/type";
import { OrganisationGraphContainer } from "../../organisation/OrganisationGraph/Style";
import { transformOrgGraphData } from "../../organisation/OrganisationGraph/util";
import { OptionsType } from "../Base";
import SplitButtonWrapper from "../SplitButtonWrapper";
import BarGraph from "./BarGraph";
import PieCharts from "./PieCharts";

type GraphModalProps = {
  handleClose: () => void;
  data: FilterType;
  name: string;
};

const GraphModal: FC<GraphModalProps> = ({ handleClose, data, name }) => {
  const [selectedOption, setSelectedOption] = useState<OptionsType>({
    label: "Top 10",
    value: TOP_10_OPTION,
  });
  const [selectedTab, setSelectedTab] = useState<OptionsType>({
    value: PIE_OPTION,
    label: "Pie Chart",
  });

  const filteredData = useMemo(() => {
    const graphData = transformOrgGraphData(
      data.facets,
      selectedTab.value === PIE_OPTION ? false : true,
      true
    );

    return {
      labels: graphData.labels
        .reverse()
        .slice(0, selectedOption.value === TOP_10_OPTION ? 10 : 20)
        .reverse(),
      datasets: {
        data: graphData.datasets.data
          .reverse()
          .slice(0, selectedOption.value === TOP_10_OPTION ? 10 : 20)
          .reverse(),
        backgroundColor: graphData.datasets.backgroundColor
          .reverse()
          .slice(0, selectedOption.value === TOP_10_OPTION ? 10 : 20)
          .reverse(),
        label: graphData.datasets.label,
      },
    };
  }, [selectedOption, selectedTab]);

  const renderGraph = () => {
    if (selectedTab.value === BAR_OPTION) {
      return (
        <Grid container justifyContent="center">
          <Grid item xs={8}>
            <OrganisationGraphContainer
              height={
                filteredData.labels.length < 10
                  ? 60
                  : filteredData.labels.length * 6
              }
            >
              <BarGraph filters={filteredData} name={name} />
            </OrganisationGraphContainer>
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid container justifyContent="center">
        <Grid item xs={11}>
          <OrganisationGraphContainer height={100}>
            <PieCharts
              filters={filteredData}
              name={name}
              showLegend
              expandedView
            />
          </OrganisationGraphContainer>
        </Grid>
      </Grid>
    );
  };

  return (
    <Dialog open maxWidth={"lg"} fullWidth onClose={handleClose}>
      <DialogTitle>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Grid item>
            <Typography variant={"h6"}>{name}</Typography>
          </Grid>
          <Grid item>
            <Grid container alignItems={"center"} spacing={2}>
              <Grid item>
                <SplitButtonWrapper
                  options={graphOptions}
                  handleSelectOption={(option) => {
                    setSelectedTab(option);
                  }}
                  selectedOption={selectedTab}
                />
              </Grid>
              <Grid item>
                <SplitButtonWrapper
                  options={dataSizeOption}
                  isDisabled={data.facets.length < 10}
                  handleSelectOption={(option) => {
                    setSelectedOption(option);
                  }}
                  selectedOption={selectedOption}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ padding: 0 }}>{renderGraph()}</DialogContent>
    </Dialog>
  );
};

export default GraphModal;
