import React, { FC } from "react";
import { DataGrid } from "@material-ui/data-grid";
import { EcommerceItemInfo } from "./type";
import { FilterInfoText } from "../commoncomponent/style";
import { DataGridContainer } from "./style";
import { Button, Grid } from "@material-ui/core";
import { useDatagridStyles } from "../userfacetlist/useDatagridStyle";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import { ColumnType } from "../organisation/type";
import { useFilteredListColumns } from "../hooks/common/DataGridHooks";

interface EcommerceDataGridProps {
  list: EcommerceItemInfo[];
  noResultFound: boolean;
  itemSelect: (domain: string) => void;
  isLoading: boolean;
  handleExport: () => void;
  activeColumns: ColumnType[];
}

const EcommerceDataGrid: FC<EcommerceDataGridProps> = ({
  list,
  noResultFound,
  itemSelect,
  isLoading,
  handleExport,
  activeColumns,
}) => {
  const classes = useDatagridStyles();
  const columns = useFilteredListColumns(activeColumns);

  if (noResultFound) {
    return (
      <FilterInfoText variant={"h6"} align={"center"}>
        There are no results that match your search <br />
        Please try modifying your search to get more results.
      </FilterInfoText>
    );
  }

  return (
    <>
      <Grid container style={{ marginBottom: 5 }} justifyContent={"flex-end"}>
        <Button
          onClick={handleExport}
          startIcon={<CloudDownloadOutlinedIcon />}
          variant={"outlined"}
          color={"primary"}
          size={"small"}
        >
          Export
        </Button>
      </Grid>
      <DataGridContainer>
        <DataGrid
          rows={list}
          columns={columns}
          hideFooter
          disableColumnMenu
          rowHeight={60}
          onRowClick={(props) => itemSelect(props.row.domain)}
          style={{ height: "91%" }}
          loading={isLoading}
          className={classes.datagrid}
        />
      </DataGridContainer>
    </>
  );
};

export default EcommerceDataGrid;
