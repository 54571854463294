import { useAuth0 } from "@auth0/auth0-react";
import { Grid } from "@material-ui/core";
import React, { FC, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { FilterType } from "../../../../data/ecommerceStore/type";
import { showError } from "../../../commoncomponent/Client";
import FiltersView from "../../../commoncomponent/Filters/FiltersView";
import LoadingComponent from "../../../commoncomponent/LoadingComponent";
import { FilterOptionsGridContainer } from "../../../commoncomponent/style";
import { useBooleanState, useDataState } from "../../../hooks/common/hooks";
import { TechnologyItemInfo } from "../TechnologySearchView/type";
import { SelectedTechType } from "../type";
import TechnologyLossDataGrid from "./TechnologyLossDataGrid";
import { getSelectedTechnologyLossDetails } from "./util";

interface TechnologyLossSectionProps {
  techId: string;
  selectedTech: (tech: SelectedTechType) => void;
}
const TechnologyLossSection: FC<TechnologyLossSectionProps> = ({
  techId,
  selectedTech,
}) => {
  const selectedSids = useDataState<string[]>([]);
  const searchedTechs = useDataState<TechnologyItemInfo[]>([]);
  const filters = useDataState<FilterType[]>([]);
  const isLoading = useBooleanState(false);
  const { logout } = useAuth0();
  const dispatch = useDispatch();

  useEffect(() => {
    isLoading.setTrue();
    getSelectedTechnologyLossDetails(techId, selectedSids.value || [])
      .then((res) => {
        searchedTechs.set(res.rows);
        filters.set(res.filters);
        isLoading.setFalse();
      })
      .catch((err) => {
        dispatch(showError(err, logout));
      });
  }, [selectedSids.value]);

  const handleSelect = (sid: string) => {
    if (selectedSids.value && selectedSids.value.find((item) => item === sid)) {
      const filteredData = selectedSids.value.filter((item) => item !== sid);
      selectedSids.set(filteredData);
      return;
    }
    if (!selectedSids.value) {
      return;
    }
    selectedSids.set([...selectedSids.value, sid]);
  };

  const showFilter = useMemo(
    () =>
      filters.value &&
      filters.value.map((item) => (
        <FiltersView
          key={`${Math.random()}`}
          name={item.name}
          checkboxOption={item.facets}
          isChecked={selectedSids.value || []}
          handleFilterChange={handleSelect}
        />
      )),
    [filters]
  );

  return (
    <Grid container>
      {filters.value && filters.value.length > 0 && (
        <FilterOptionsGridContainer
          item
          xs={12}
          sm={3}
          style={{ paddingTop: 0 }}
        >
          {showFilter}
        </FilterOptionsGridContainer>
      )}
      <Grid
        item
        xs={12}
        sm={filters.value && filters.value.length > 0 ? 9 : 12}
      >
        <LoadingComponent loading={isLoading.value}>
          <TechnologyLossDataGrid
            selectedTech={selectedTech}
            list={searchedTechs.value || []}
          />
        </LoadingComponent>
      </Grid>
    </Grid>
  );
};

export default TechnologyLossSection;
