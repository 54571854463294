import { ListItem } from "@material-ui/core";
import Styled from "styled-components";
import { PrimaryButtonColor } from "../../constants/ColorConstants";

interface ListItemTextProps {
  isselected: number;
}

export const ListItemText = Styled(ListItem)`
    font-size: 14px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: ${(props: ListItemTextProps) =>
      props.isselected ? `1px ${PrimaryButtonColor} solid` : undefined};
    border-radius: ${(props: ListItemTextProps) =>
      props.isselected ? "2px" : undefined};
    font-weight: ${(props: ListItemTextProps) =>
      props.isselected ? "500" : undefined};
    color: ${(props: ListItemTextProps) =>
      props.isselected ? PrimaryButtonColor : undefined};
`;

export const TechNameContainer = Styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
`;
