import { graphColor, PrimaryButtonColor } from "../../../constants/ColorConstants";
import { generateRandomColor } from "../../../utils/utility";
import { CheckboxOptionType } from "../../commoncomponent/Filters/type";
import { GraphDataType } from "../../commoncomponent/Graph/type";

export const transformEcommerceGraphData = (
  filterData: CheckboxOptionType[],
  showBarGraphColor = false,
  showFullGraph = false
): GraphDataType => {
  const label: string[] = [];
  const dataSet: any = {
    label: "",
    data: [],
    backgroundColor: [],
  };
  if (showFullGraph) {
    filterData.map((facet: CheckboxOptionType, index) => {
      label.push(facet.name);
      dataSet.data.push(facet.count);
      dataSet.backgroundColor.push(
        showBarGraphColor
          ? PrimaryButtonColor
          : index <= 9
            ? graphColor[index]
            : generateRandomColor()
      );
    });
  } else {
    if (filterData.length > 10) {
      filterData.slice(0, 10).map((facet: CheckboxOptionType, index) => {
        label.push(facet.name);
        dataSet.data.push(facet.count);
        dataSet.backgroundColor.push(
          showBarGraphColor
            ? PrimaryButtonColor
            : index <= 9
              ? graphColor[index]
              : generateRandomColor()
        );
      });
    } else {
      filterData.map((facet: CheckboxOptionType, index) => {
        label.push(facet.name);
        dataSet.data.push(facet.count);
        dataSet.backgroundColor.push(
          showBarGraphColor ? PrimaryButtonColor : graphColor[index]
        );
      });
    }
  }
  return {
    labels: label.reverse(),
    datasets: {
      ...dataSet,
      data: dataSet.data.reverse(),
      backgroundColor: dataSet.backgroundColor.reverse(),
    },
  };
};
