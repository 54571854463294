import { getTechnologyGainDetailsApiUrl } from "../../../../constants/ApiConstants";
import { trackEvent } from "../../../../service/mixpanel";
import { Client } from "../../../commoncomponent/Client";
import { TechnologyItemInfo } from "../TechnologySearchView/type";

const transformRows = (data: any): TechnologyItemInfo[] => {
  return (
    data.map((item: any) => ({
      id: item.sid,
      contact: item.attrs.Contact,
      country: item.attrs.Country,
      addedIn: item.attrs["Added In"],
      domain: item.domain,
    })) || []
  );
};

export const getSelectedTechnologyGainDetails = (
  techId: string,
  selectedSids: string[]
) => {
  trackEvent("TECHNOLOGY_GAIN_DETAILS", {
    techId: techId,
    selectedSids: selectedSids,
  });
  return Client.getInstance()
    .createData(
      getTechnologyGainDetailsApiUrl(techId),
      {
        tech_sid: techId,
        filter_sids: selectedSids,
        order_by: "",
        offset: 0,
        count: 100,
      },
      false
    )
    .then((res) =>
      Promise.resolve({
        filters: res.data.facet_groups,
        rows: transformRows(res.data.rows),
        total: res.data.total,
      })
    )
    .catch((err) => Promise.reject(err));
};
