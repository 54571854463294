import delay from "lodash/delay";
import { FC } from "react";
import { SearchedItemContainer, SearchList } from "./SearchAutoCompleteStyle";
import { IfilterAutocomplete, ITechnologyOption } from "../drawer/type/type";
import { Typography } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { useBooleanState } from "../hooks/common/hooks";
import TextFieldWrapper from "./TextFieldWrapper";

interface ISearchAutoCompleteProps {
  label: string;
  onChange: (text: string) => void;
  loading: boolean;
  value: string;
  apiData: IfilterAutocomplete[];
  isResultFound?: boolean;
  variant?: "standard" | "filled" | "outlined" | undefined;
  onOptionSelect: (selectedOption: ITechnologyOption) => void;
  searchVariant?: "basic" | "big";
}

const SearchAutoCompleteWrapper: FC<ISearchAutoCompleteProps> = ({
  label,
  onChange,
  apiData,
  value,
  onOptionSelect,
  isResultFound,
  loading,
  variant,
  searchVariant = "big",
}) => {
  const showDropdown = useBooleanState(false);

  const handleBlur = () => {
    delay(() => {
      if (showDropdown) {
        showDropdown.setFalse();
      }
    }, 500);
  };

  return (
    <div>
      <TextFieldWrapper
        onChange={({ target }) => onChange(target.value)}
        label={label}
        value={value}
        onFocus={() => showDropdown.setTrue()}
        onBlur={handleBlur}
        variant={variant || "standard"}
        isBasic={searchVariant === "basic"}
      />
      {value !== "" && showDropdown.value && (
        <SearchedItemContainer searchVariant={searchVariant} elevation={3}>
          {apiData.length > 0 ? (
            apiData.map((item: IfilterAutocomplete) => (
              <SearchList
                key={item.id}
                onClick={() => {
                  onOptionSelect({
                    name: item.name,
                    id: item.id,
                  });
                }}
              >
                <Typography variant={"body2"} color={"textPrimary"}>
                  {item.name}
                </Typography>
                <Typography variant={"caption"}>{item.description}</Typography>
              </SearchList>
            ))
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              {!isResultFound && !loading ? (
                <Typography>No result found</Typography>
              ) : (
                <CircularProgress />
              )}
            </div>
          )}
        </SearchedItemContainer>
      )}
    </div>
  );
};

export default SearchAutoCompleteWrapper;
