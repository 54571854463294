import { Grid, Paper, Typography } from "@material-ui/core";
import Styled from "styled-components";
import CheckIcon from "@material-ui/icons/Check";
import {
  correctIconColor,
  lightGrey,
  planMessageColor,
  primaryDarkFontColor,
  white,
  wrongIconColor,
} from "../../constants/ColorConstants";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";

export const PlansCardContainer = Styled(Paper)`
    border-radius: 5px;
    padding: 30px;
    padding-bottom: 0px;
    height: 90%;
    margin-top: 20px;
    border: 2px solid ${lightGrey};
    background-color: ${white};
`;

export const PlanTitle = Styled(Typography)`
    font-size: 20px;
    color: ${primaryDarkFontColor};
    font-weight: bold;
    line-height: 1.735rem;
`;

export const Tick = Styled(CheckIcon)`
    color: ${correctIconColor};
`;

export const Wrong = Styled(CloseOutlinedIcon)`
    color: ${wrongIconColor};
`;

export const PlanMessage = Styled(Typography)`
    font-size: 14px;
    line-height: 1.735rem;
    color: ${primaryDarkFontColor};
    margin-bottom: 10px;
`;

export const HighlightedText = Styled.span`
    font-size: 14px;
    color: ${primaryDarkFontColor};
    font-weight: bold;
    line-height: 1.735rem;
`;

export const TitleHighlightText = Styled.span`
    font-size: 32px;
    color: ${primaryDarkFontColor};
    font-weight: bold;
    line-height: 1.735rem;
`;

export const TitleText = Styled.span`
    font-size: 64px;
    color: ${planMessageColor};
    font-weight: bold;
    line-height: 1.735rem;
`;

export const CaptionTitleText = Styled(Typography)`
    font-size: 20px;
    color: ${planMessageColor};
    font-weight: 400;
    line-height: 1.735rem;
`;

export const BillingPortalContainer = Styled(Grid)`
    padding: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    justify-content: center;
`;
