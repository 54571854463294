import { createStyles, Grid, makeStyles } from "@material-ui/core";
import Styled from "styled-components";
import {
  LabelPopperBackgroundColor,
  LabelPopperColor,
  ScrollBarColor,
} from "../../constants/ColorConstants";

export const LabelStyles = makeStyles(() =>
  createStyles({
    popper: {
      width: 280,
      position: "absolute",
      zIndex: 10,
      padding: 16,
      color: LabelPopperColor,
      backgroundColor: LabelPopperBackgroundColor,
    },
  })
);

export const ListContainer = Styled(Grid)`
    overflow-x: hidden;
    max-height: 200px;
    overflow-y: auto;
    width: 100%;
    &::-webkit-scrollbar-thumb {
        background: ${ScrollBarColor};
        border-radius: 10px;
    }
`;
