import { Grid } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import React, { FC } from "react";
import { noOfRecordPerPage } from "../../constants/SiteConstants";

interface PaginationGridProps {
  totalCount: number;
  pageCount: number;
  setPageCount: (pageCount: number) => void;
  noOfItemToShow: number;
}

const PaginationGrid: FC<PaginationGridProps> = ({
  totalCount,
  pageCount,
  setPageCount,
  noOfItemToShow,
}) => {
  if (totalCount > noOfRecordPerPage) {
    return (
      <Grid style={{ marginTop: 5 }} container justifyContent={"center"}>
        <Pagination
          count={
            totalCount > noOfItemToShow
              ? Math.ceil(noOfItemToShow / noOfRecordPerPage)
              : Math.ceil(totalCount / noOfRecordPerPage)
          }
          color="primary"
          page={pageCount}
          // eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
          onChange={(event, val) => {
            setPageCount(val);
          }}
        />
      </Grid>
    );
  }

  return null;
};

export default PaginationGrid;
