import { IActionPayload } from "../../component/commoncomponent/Base";
import { UPDATE_LOADING, UPDATE_ERROR } from "./type";

export const updateIsLoading = (
  isLoading: boolean
): IActionPayload<boolean> => ({
  payload: isLoading,
  type: UPDATE_LOADING,
});

export const updateErrorMessage = (
  errorMessage: string
): IActionPayload<string> => ({
  payload: errorMessage,
  type: UPDATE_ERROR,
});
