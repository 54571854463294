import { Grid, Chip } from "@material-ui/core";
import { compact } from "lodash";
import { useMemo } from "react";

export const useRenderSelectedOptions = (
  name: string[],
  id: string[],
  handleSearchChange: (id: string, name: string) => void
) =>
  useMemo(
    () => (
      <Grid container style={{ marginTop: 5 }}>
        {compact(name).map((item, index) => (
          <Grid item key={`${item}${index}`} style={{ padding: 1 }}>
            <Chip
              style={{ height: "auto", padding: 3 }}
              label={item}
              onDelete={() => handleSearchChange(id[index] || "", item)}
            />
          </Grid>
        ))}
      </Grid>
    ),
    [name, handleSearchChange]
  );
