import React, { FC } from "react";
import { GraphDataType } from "../../commoncomponent/Graph/type";
import ReactECharts from "echarts-for-react";
import { primaryDarkFontColor } from "../../../constants/ColorConstants";
import { formatNumber } from "../../../utils/utility";

type PieChartProps = {
  filters: GraphDataType;
  name: string;
  showLegend?: boolean;
  expandedView?: boolean;
};

const PieCharts: FC<PieChartProps> = ({
  filters,
  name,
  showLegend,
}) => {
  const getOptions = () => {
    const options: any = {
      tooltip: {
        trigger: "item",
      },
      grid: {
        left: "15%",
        top: 300,
      },
      series: [
        {
          name: name,
          type: "pie",
          radius: [50, 250],
          center: ["50%", "50%"],
          roseType: "area",
          itemStyle: {
            borderRadius: 8,
          },
          top: 10,
          toolbox: {
            show: true,
            feature: {
              mark: { show: true },
              dataView: { show: true, readOnly: false },
              restore: { show: true },
              saveAsImage: { show: true },
            },
          },
          color: filters.datasets.backgroundColor,
          label: {
            alignTo: "edge",
            formatter: (d: any) => `${d.name}\n${d.value ? formatNumber(d.value) : 0} (${d.percent})%`,
            minMargin: 5,
            edgeDistance: 100,
            lineHeight: 20,
            rich: {
              name: {
                fontSize: 13,
                color: primaryDarkFontColor,
              },
              value: {
                color: "#999",
                fontStyle: "italic",
              },
            },
          },
          labelLine: {
            length: 15,
            length2: 0,
            maxSurfaceAngle: 80,
          },
          data: filters.labels.map((label, index) => ({
            value: filters.datasets.data[index],
            name: label,
          })),
        },
      ],
    };
    if (showLegend) {
      options.legend = {
        orient: "horizontal",
      };
    }
    if (filters.labels.length < 11) {
      options.series[0].labelLayout = (params: any) => {
        const isLeft = params.labelRect.x < 600 / 2;
        const points = params.labelLinePoints as number[][];
        points[2][0] = isLeft
          ? params.labelRect.x
          : params.labelRect.x + params.labelRect.width;
        return {
          labelLinePoints: points,
        };
      };
    }
    return options;
  };
  return (
    <ReactECharts
      key={filters.labels.length}
      style={{ height: 630 }}
      option={getOptions()}
    />
  );
};

export default PieCharts;
