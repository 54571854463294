import { useAuth0 } from "@auth0/auth0-react";
import { Drawer, Grid, Typography, Button } from "@material-ui/core";
import React, { FC } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getDomainImage } from "../../../utils/utility";
import { showError } from "../../commoncomponent/Client";
import LoadingComponent from "../../commoncomponent/LoadingComponent";
import { useBooleanState, useDataState } from "../../hooks/common/hooks";
import { DomainImageContainer } from "../style";
import EcommerceTabsView from "./EcommerceTabsView";
import SiteDetails from "./SiteDetails";
import { EcommerceItemDetailsType } from "./type";
import {
  getAddFavouriteDomain,
  getRemoveFavouriteDomain,
  getSelectedEcommerceDetails,
  getSelectedTechDetails,
} from "./util";
import { LaunchRounded } from "@material-ui/icons";

interface EcommerceItemDetailPanelProps {
  handleClose: () => void;
  selectedDomain: string;
  sid?: string;
}
const EcommerceItemDetailPanel: FC<EcommerceItemDetailPanelProps> = ({
  handleClose,
  selectedDomain,
  sid,
}) => {
  const siteDetails = useDataState<EcommerceItemDetailsType>();
  const isFavourite = useBooleanState(false);
  const isLoading = useBooleanState(false);
  const { logout } = useAuth0();
  const dispatch = useDispatch();

  useEffect(() => {
    isLoading.setTrue();
    if (sid) {
      getSelectedTechDetails(sid)
        .then((data) => {
          siteDetails.set(data);
          data.isFavourite ? isFavourite.setTrue() : isFavourite.setFalse();
        })
        .catch((error) => {
          dispatch(showError(error, logout));
        })
        .finally(() => {
          isLoading.setFalse();
        });
      return;
    }
    getSelectedEcommerceDetails(selectedDomain)
      .then((data) => {
        siteDetails.set(data);
        data.isFavourite ? isFavourite.setTrue() : isFavourite.setFalse();
      })
      .catch((err) => {
        dispatch(showError(err, logout));
      })
      .finally(() => {
        isLoading.setFalse();
      });
    return () => {
      siteDetails.set(undefined);
    };
  }, []);

  const handleFavouriteChange = () => {
    if (!isFavourite.value) {
      siteDetails.value &&
        getAddFavouriteDomain(siteDetails.value.sid)
          .then(() => {
            isFavourite.setTrue();
          })
          .catch((err) => {
            dispatch(showError(err, logout));
          });
    } else {
      siteDetails.value &&
        getRemoveFavouriteDomain(siteDetails.value.sid)
          .then(() => {
            isFavourite.setFalse();
          })
          .catch((err) => {
            dispatch(showError(err, logout));
          });
    }
  };

  const getView = () => {
    return (
      <>
        <Grid item xs={12} style={{ paddingLeft: 30 }}>
          <Grid container justifyContent={"space-between"}>
            <DomainImageContainer>
              <img
                style={{ height: 25, width: 25 }}
                src={getDomainImage(selectedDomain)}
                alt={"Domain favicaon"}
              />
              <Typography
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() =>
                  window.open(`https://${selectedDomain}`, "_blank")
                }
                color={"primary"}
                variant={"h5"}
              >
                {selectedDomain}
                <LaunchRounded
                  style={{ marginLeft: 5, fontSize: "1.5rem" }}
                  fontSize="small"
                />
              </Typography>
            </DomainImageContainer>
            <Button
              variant={"outlined"}
              size={"small"}
              color={!isFavourite.value ? "primary" : "secondary"}
              onClick={handleFavouriteChange}
            >
              {!isFavourite.value
                ? "Add to favourite"
                : "Remove from favourite"}
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ width: "100%" }}>
          <SiteDetails
            location={siteDetails.value?.location.countries || []}
            platform={siteDetails.value?.platform || ""}
            title={siteDetails.value?.title || ""}
          />
        </Grid>
        <Grid item xs={12} style={{ width: "100%", marginTop: 30 }}>
          <EcommerceTabsView
            technology={siteDetails.value?.techGroups || []}
            contacts={siteDetails.value?.contact}
          />
        </Grid>
      </>
    );
  };

  return (
    <Drawer anchor={"right"} open={true} onClose={handleClose}>
      <Grid container style={{ padding: 30, width: "70vw" }}>
        <LoadingComponent loading={isLoading.value}>
          {getView()}
        </LoadingComponent>
      </Grid>
    </Drawer>
  );
};

export default EcommerceItemDetailPanel;
