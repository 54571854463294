import { GridCellParams } from "@material-ui/data-grid";
import React from "react";
import { useMemo } from "react";
import { CategoryType } from "../../data/technologyStore/type";
import {
  CheckboxItemsContainer,
  CheckboxItemText,
} from "../commoncomponent/style";
import { LaunchRounded } from "@material-ui/icons";
import { PrimaryButtonColor } from "../../constants/ColorConstants";
import { TechNameContainer } from "./style";
import { Button } from "@material-ui/core";

export const useGetShowTechCategory = (
  selectedSid: string,
  categories: CategoryType[],
  totalNumberOfResult?: number,
  handleSelect?: (sid: string) => void
) =>
  useMemo(
    () =>
      categories &&
      categories.map((item) => (
        <CheckboxItemsContainer
          key={item.sid}
          isselected={selectedSid === item.sid ? 1 : 0}
          onClick={() => handleSelect && handleSelect(item.sid)}
        >
          <CheckboxItemText>{item.name}</CheckboxItemText>
          <CheckboxItemText
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            {item.count && item.count.toLocaleString()}
          </CheckboxItemText>
        </CheckboxItemsContainer>
      )),
    [categories, totalNumberOfResult, selectedSid]
  );

export const useTechFilteredListColumns = (
  handleTechSelect: (sid: string) => void,
  handleFavouriteSelect: (sid: string, isFavourite: boolean) => void
) =>
  useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        flex: 2,
        renderCell: (params: GridCellParams) => (
          <TechNameContainer onClick={() => handleTechSelect(params.row.id)}>
            {params.row.name}
            <LaunchRounded
              style={{ marginLeft: 5, color: PrimaryButtonColor }}
              fontSize="small"
            />
          </TechNameContainer>
        ),
      },
      {
        field: "total",
        headerName: "Total",
        flex: 1,
      },
      {
        field: "monthlyGain",
        headerName: "Monthly gain",
        flex: 1,
      },
      {
        field: "monthlyLoss",
        headerName: "Monthly loss",
        flex: 1,
      },
      {
        field: "isFav",
        headerName: "Favourite",
        flex: 1,
        renderCell: (params: GridCellParams) =>
          params.row.isFav ? (
            <Button
              onClick={() => handleFavouriteSelect(params.row.id, false)}
              style={{ fontSize: 9 }}
              color={"secondary"}
              variant="outlined"
            >
              Remove from favourite
            </Button>
          ) : (
            <Button
              onClick={() => handleFavouriteSelect(params.row.id, true)}
              style={{ fontSize: 9, width: "100%" }}
              variant="outlined"
              color={"primary"}
            >
              Add To Favourite
            </Button>
          ),
      },
    ],
    []
  );
