import { Grid, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { Divider } from "./style";

interface CardDetailsTitleProps {
  icon?: JSX.Element;
  title: string;
}

const CardDetailsTitle: FC<CardDetailsTitleProps> = ({ icon, title }) => {
  return (
    <>
      <Grid container spacing={1} alignItems={"center"}>
        {icon && <Grid item>{icon}</Grid>}
        <Grid item>
          <Typography variant={"body1"} color={"primary"}>
            {title}
          </Typography>
        </Grid>
      </Grid>
      <Divider />
    </>
  );
};

export default CardDetailsTitle;
