import React, { useEffect } from "react";
import { ActionButtonDomain, ActionContainer } from "./style";
import { Tooltip } from "@material-ui/core";
import { SelectedListType } from "./type";
import { useDispatch } from "react-redux";
import {
  deleteUserListAction,
  truncateUserListAction,
  uploadDomainList,
} from "../../data/LabelsList/action";
import { useAuth0 } from "@auth0/auth0-react";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  deleteConstant,
  flushConstant,
  uploadConstant,
} from "../../constants/SiteConstants";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import {
  dangerColor,
  PrimaryButtonColor,
  successToastConfiguration,
} from "../../constants/ColorConstants";
import { useBooleanState, useDataState } from "../hooks/common/hooks";
import DeleteConfirmationBox from "../commoncomponent/DeleteConfirmationBox";
import { showError } from "../commoncomponent/Client";
import { toast } from "react-toastify";
import UploadFileView from "./UploadFileView";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";

export type onColumnActionType = (sid: string) => void;

type ActionManagerProp = {
  selectedFacet: SelectedListType;
};

const getListOptionsIcons = (type: string) => {
  if (type === deleteConstant) {
    return <DeleteIcon style={{ color: dangerColor }} />;
  }
  if (type === flushConstant) {
    return <DeleteOutlinedIcon style={{ color: PrimaryButtonColor }} />;
  }
  if (type === uploadConstant) {
    return <FileUploadOutlinedIcon style={{ color: PrimaryButtonColor }} />;
  }
};

const ActionManager: React.FC<ActionManagerProp> = ({ selectedFacet }) => {
  const dispatch = useDispatch();
  const { logout } = useAuth0();
  const showDeleteConfirmationBox = useBooleanState(false);
  const selectedItemDetails = useDataState<string>("");
  const showUploadModal = useBooleanState(false);
  const uploadPercent = useDataState<number>(0);

  useEffect(() => {
    if (uploadPercent.value !== 100) {
      return;
    }
    const timeout = setTimeout(() => {
      uploadPercent.set(0);
    }, 100);

    return () => clearTimeout(timeout);
  }, [uploadPercent]);

  const handleOptionClick = () => {
    if (selectedItemDetails.value === "delete") {
      dispatch(deleteUserListAction(selectedFacet.sid, logout));
    }
    if (selectedItemDetails.value === "flush") {
      truncateUserListAction(selectedFacet.sid)
        .then(() => {
          showDeleteConfirmationBox.setFalse();
          toast.success(
            "User list flushed successfully",
            successToastConfiguration
          );
        })
        .catch((err) => dispatch(showError(err, logout)));
    }
  };

  const handleUploadFile = (file: File) => {
    const options = {
      onUploadProgress: (progressEvent: any) => {
        const { loaded, total } = progressEvent;
        const percent = Math.floor((loaded * 100) / total);
        if (percent === 100) {
          uploadPercent.set(0);
          return;
        }
        uploadPercent.set(percent);
      },
    };
    uploadDomainList(file, selectedFacet.sid, options)
      .then(() => {
        toast.success("File uploaded successfuly", successToastConfiguration);
        showUploadModal.setFalse();
      })
      .catch((err) => dispatch(showError(err, logout)))
      .finally(() => uploadPercent.set(0));
  };

  return (
    <ActionContainer>
      {selectedFacet.actions.map((item) => (
        <Tooltip
          PopperProps={{ style: { textTransform: "capitalize" } }}
          title={item}
          key={item}
        >
          <ActionButtonDomain
            onClick={() => {
              selectedItemDetails.set(item);
              item === uploadConstant
                ? showUploadModal.setTrue()
                : showDeleteConfirmationBox.setTrue();
            }}
          >
            {getListOptionsIcons(item)}
          </ActionButtonDomain>
        </Tooltip>
      ))}
      {showDeleteConfirmationBox.value && (
        <DeleteConfirmationBox
          handleSubmit={handleOptionClick}
          handleClose={() => showDeleteConfirmationBox.setFalse()}
          title={selectedFacet.name}
        />
      )}
      {showUploadModal.value && (
        <UploadFileView
          handleClose={() => showUploadModal.setFalse()}
          title={selectedFacet.name}
          handleSubmit={handleUploadFile}
          progress={uploadPercent.value || 0}
        />
      )}
    </ActionContainer>
  );
};

export default ActionManager;
