import Styled from "styled-components";

type OrganisationGraphContainerProps = {
  height: number;
};
export const OrganisationGraphContainer = Styled.div`
padding-bottom: 30px;
    & .echarts-for-react {
        height: ${(props: OrganisationGraphContainerProps) =>
          props.height}vh !important;
    }
`;
