import Styled from "styled-components";
import { black } from "../../../constants/ColorConstants";

export const BoxContainer = Styled.div`
    padding: 24px 24px 5px 24px;
    border: 2px solid #E0E2E6;
    border-radius: 5px;
    height: 100%;
`;

export const Divider = Styled.hr`
    border-top: 2px solid rgb(224, 226, 230);
    border-bottom: 0px;
    margin: 24px 0px;
    position: relative;
    left: -24px;
    width: calc(100% + 48px);
`;

export const SubText = Styled.span`
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    color: ${black};
`;
