import _cloneDeep from "lodash/cloneDeep";
import { commonStateType, UPDATE_ERROR, UPDATE_LOADING } from "./type";

const initialState: commonStateType = {
  errorMessage: "",
  isLoading: false,
};

export const commonStateReducer = (state = initialState, action: any): any => {
  const stateClone = _cloneDeep(state);
  switch (action.type) {
    case UPDATE_LOADING:
      stateClone.isLoading = action.payload;
      return stateClone;

    case UPDATE_ERROR:
      stateClone.errorMessage = action.payload;
      return stateClone;

    default:
      return state;
  }
};
