import { useRef, FC, useEffect } from "react";
import { Button, Grid, Typography, Paper, IconButton } from "@material-ui/core";
import { LabelStyles, ListContainer } from "./style";
import { useAllUsersList, useModalOutsideClick } from "./hooks";
import { useDispatch } from "react-redux";
import { useBooleanState, useDataState } from "../hooks/common/hooks";
import { LabelType } from "../../data/LabelsList/type";
import CloseIcon from "@material-ui/icons/Close";
import TextFieldWrapper from "../commoncomponent/TextFieldWrapper";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import { getLabels } from "./util";
import { updateEcommLabelList } from "../../data/ecommerceStore/action";
import { updateOrgLabelList } from "../../data/organisation/action";
import { showError } from "../commoncomponent/Client";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingComponent from "../commoncomponent/LoadingComponent";
import { FilterInfoText } from "../commoncomponent/style";
import { ecommLabelConstant, orgLabelConstant } from "../../constants/SiteConstants";

interface LabelPopperProps {
  handleClose: () => void;
  openModalForAdd: () => void;
  openModalForEdit: (selectedLabel: LabelType) => void;
  type: string;
}

const LabelPopper: FC<LabelPopperProps> = ({
  handleClose,
  openModalForAdd,
  openModalForEdit,
  type,
}) => {
  const listsRef = useRef<HTMLDivElement>(null);
  const filteredLabels = useDataState<LabelType[]>();
  const { popper } = LabelStyles();
  const searchItem = useDataState<string>("");
  const showSearchBox = useBooleanState();
  const labelList = useDataState<LabelType[]>([]);
  const dispatch = useDispatch();
  const { logout } = useAuth0();
  const isLoading = useBooleanState(false);

  useEffect(() => {
    fetchLabels(true);
  }, []);

  const fetchLabels = (forceRefresh = false) => {
    isLoading.setTrue();
    getLabels(type, forceRefresh)
      .then((res) => {
        filteredLabels.set(res);
        labelList.set(res);
        if (type === ecommLabelConstant) {
          dispatch(updateEcommLabelList(res));
        }
        if (type === orgLabelConstant) {
          dispatch(updateOrgLabelList(res));
        }
      })
      .catch((err) => {
        dispatch(showError(err, logout));
        filteredLabels.set([]);
        labelList.set([]);
      })
      .finally(() => isLoading.setFalse());
  };

  const handleClickOutside = (event: any) => {
    if (listsRef.current && !listsRef.current.contains(event.target)) {
      handleClose();
    }
  };

  useModalOutsideClick(handleClickOutside);

  const allUserList = useAllUsersList(
    filteredLabels.value || [],
    openModalForEdit
  );

  const onSearchFilter = (text: string) => {
    searchItem.set(text);
    if (labelList.value) {
      filteredLabels.set(
        labelList.value.filter((label) =>
          label.name.toLowerCase().includes(text)
        )
      );
    }
  };

  const renderListSection = () => {
    if (!labelList.value?.length) {
      return <></>;
    }

    if (!showSearchBox.value) {
      return (
        <IconButton
          title="Expand Searchbox"
          aria-label="Expand Searchbox"
          size="small"
          onClick={() => showSearchBox.setTrue()}
        >
          <SearchIcon fontSize="small" />
        </IconButton>
      );
    }

    return (
      <TextFieldWrapper
        value={searchItem.value}
        onChange={({ target }) => onSearchFilter(target.value)}
        placeholder={"Search"}
        isBasic
        InputProps={{
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              onClick={() => {
                showSearchBox.setFalse();
                searchItem.set("");
                onSearchFilter("");
              }}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    );
  };

  return (
    <>
      <Paper elevation={2} className={popper} ref={listsRef}>
        <Grid container justifyContent="flex-end" style={{ marginBottom: 5 }}>
          <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="outlined"
            color={"primary"}
            fullWidth
            size={"small"}
            onClick={openModalForAdd}
          >
            Add new list
          </Button>
        </Grid>
        <Grid item xs={12} style={{ margin: "10px 0px" }}>
          <Grid container spacing={1} justifyContent="space-between" alignItems={"center"}>
            <Grid item xs={6}>
              <Typography
                variant={"body2"}
              >
                Select an existing list
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {renderListSection()}
            </Grid>
          </Grid>
        </Grid>
        <ListContainer item xs={12}>
          <LoadingComponent loading={isLoading.value}>
            {labelList.value?.length ? (
              <>{allUserList}</>
            ) : (
              <FilterInfoText align={"center"}>No list present</FilterInfoText>
            )}
          </LoadingComponent>
        </ListContainer>
      </Paper>
    </>
  );
};

export default LabelPopper;
