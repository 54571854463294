import {
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    Button,
} from "@mui/material";
import React, { FC } from "react";

type DownloadInitialisationDialogProps = {
    message: string;
    handleClose: () => void;
};
const DownloadInitialisationDialog: FC<DownloadInitialisationDialogProps> = ({
    message,
    handleClose,
}) => (
    <>
        <DialogTitle>
            <b>CSV Export</b>
        </DialogTitle>
        <DialogContent>
            <Typography variant={"body1"}>{message}</Typography>
        </DialogContent>
        <DialogActions>
            <Button variant={"contained"} onClick={handleClose}>
                Ok
            </Button>
        </DialogActions>
    </>
);

export default DownloadInitialisationDialog;
