import {
  InputLabel,
  Input,
  MenuItem,
  Checkbox,
  ListItemText,
  FormHelperText,
} from "@material-ui/core";
import React, { FC } from "react";
import {
  FormControlContainer,
  DropdownContainer,
} from "../ecommerce/EcommerceAdvance/AdvanceStyle";
import CheckIcon from "../../images/checkedBox.svg";
import UnCheckIcon from "../../images/uncheckedBox.svg";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextFieldWrapper from "./TextFieldWrapper";
import { OptionsType } from "../../data/advancesearch/type";

type DropdownWrapperProps = {
  isMultiple?: boolean;
  isSearchable?: boolean;
  label: string;
  dropdownOptions: OptionsType[];
  selectedOptions: string[] | string;
  selectedDropdownOptions: OptionsType[];
  value?: string | string[];
  onChange: (selectedOption: string[] | string) => void;
};

const DropdownWrapper: FC<DropdownWrapperProps> = ({
  isMultiple,
  dropdownOptions,
  label,
  selectedOptions,
  onChange,
  value,
  isSearchable,
  selectedDropdownOptions,
}) => {
  const handleChangeMultipleWithoutSearch = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    onChange(event.target.value as string[]);
  };

  const handleMultipleChange = (_: any, values: OptionsType[]) => {
    onChange(values.map((item) => item.value));
  };

  const handleSingleChange = (_: any, values: OptionsType) => {
    onChange(values.value);
  };

  const renderValue = (selectedIds: string[]) => {
    const filteredIds = selectedIds.map((item) =>
      dropdownOptions.find((filterItem) => filterItem.value === item)
    );
    const filteredIdsName = filteredIds.map((item) => item?.name);
    return filteredIdsName.join(", ");
  };

  if (isSearchable) {
    return (
      <Autocomplete
        multiple={isMultiple}
        options={dropdownOptions}
        value={selectedDropdownOptions}
        disableCloseOnSelect={isMultiple}
        onChange={(_: any, values: any) =>
          isMultiple
            ? handleMultipleChange(_, values)
            : handleSingleChange(_, values)
        }
        getOptionLabel={(option) => option.name}
        renderInput={(params) => <TextFieldWrapper {...params} label={label} />}
      />
    );
  }

  if (isMultiple) {
    return (
      <FormControlContainer style={{ width: "100%", marginTop: 10 }}>
        <InputLabel style={{ fontSize: 13 }}>{label}</InputLabel>
        <DropdownContainer
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
          multiple
          value={selectedOptions || []}
          fullWidth
          style={{ fontSize: 13 }}
          renderValue={(selected) => renderValue(selected as string[])}
          onChange={handleChangeMultipleWithoutSearch}
          input={<Input />}
        >
          {dropdownOptions &&
            dropdownOptions.map((option) => (
              <MenuItem
                style={{ padding: 0 }}
                key={option.value}
                value={option.value}
              >
                <Checkbox
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  icon={
                    <img
                      src={UnCheckIcon}
                      alt="uncheckedBox"
                      width={16}
                      height={16}
                    />
                  }
                  checkedIcon={
                    <img
                      src={CheckIcon}
                      alt="uncheckedBox"
                      width={16}
                      height={16}
                    />
                  }
                  size={"small"}
                  checked={selectedOptions.indexOf(option.value) > -1}
                />
                <ListItemText>{option.name}</ListItemText>
              </MenuItem>
            ))}
        </DropdownContainer>
        {selectedOptions === "" && (
          <FormHelperText style={{ color: "red" }}>
            This is required!
          </FormHelperText>
        )}
      </FormControlContainer>
    );
  }

  return (
    <FormControlContainer size={"small"} fullWidth style={{ marginTop: 10 }}>
      <InputLabel>{label}</InputLabel>
      <DropdownContainer
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
        style={{ fontSize: 13 }}
        value={value}
        label={label}
        onChange={({ target }) => onChange(target.value as string)}
      >
        {dropdownOptions.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.name}
          </MenuItem>
        ))}
      </DropdownContainer>
    </FormControlContainer>
  );
};

export default DropdownWrapper;
