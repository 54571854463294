import { Button } from "@material-ui/core";
import { useMemo } from "react";
import { CheckboxOptionType } from "./type";
import FilterCheckBoxView from "./FilterCheckBoxView";
import { totalNumberOfRecordsAdd } from "../../../constants/SiteConstants";

export const useGetShowFilterCheckBox = (
  filteredCheckbox: CheckboxOptionType[],
  totalNumberOfResult: number,
  isChecked: string[],
  handleFilterChange: (sid: string) => void
) =>
  useMemo(
    () => (
      <FilterCheckBoxView
        filteredCheckBoxes={filteredCheckbox}
        handleFilterChange={handleFilterChange}
        totalNumberOfResult={totalNumberOfResult}
        isChecked={isChecked}
      />
    ),
    [filteredCheckbox, isChecked, totalNumberOfResult]
  );

export const useGetShowMoreOrLessButton = (
  filteredCheckboxLength: number,
  totalNumberOfResult: number,
  noOfFilterToShow: number,
  setTotalNumberOfResult: (total: number) => void
) =>
  useMemo(() => {
    if (filteredCheckboxLength === 0) {
      return null;
    }
    if (filteredCheckboxLength > totalNumberOfResult) {
      return (
        <Button
          variant={"outlined"}
          color={"primary"}
          fullWidth
          size={"small"}
          onClick={() =>
            setTotalNumberOfResult(
              totalNumberOfResult + totalNumberOfRecordsAdd
            )
          }
        >
          Show more
        </Button>
      );
    }
    if (
      totalNumberOfResult &&
      filteredCheckboxLength < totalNumberOfResult &&
      filteredCheckboxLength > noOfFilterToShow
    ) {
      return (
        <Button
          variant={"outlined"}
          color={"primary"}
          fullWidth
          size={"small"}
          onClick={() =>
            totalNumberOfResult &&
            setTotalNumberOfResult(
              totalNumberOfResult - totalNumberOfRecordsAdd
            )
          }
        >
          Show less
        </Button>
      );
    }
  }, [totalNumberOfResult, filteredCheckboxLength]);
