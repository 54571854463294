import { Grid } from "@material-ui/core";
import React, { FC } from "react";
import { TechInfoContainer } from "./style";
import TechnologyInfoSection from "./TechnologyInfoSection";
import TechnologyTopBreadcrumbSection from "./TechnologyTopBreadcrumbSection";
import { TechHighlightDetails } from "./type";

interface TechnologyHighlightSectionProps {
  techDetails?: TechHighlightDetails;
  handleFavouriteChange: () => void;
  isFavourite: boolean;
}

const TechnologyHighlightSection: FC<TechnologyHighlightSectionProps> = ({
  techDetails,
  handleFavouriteChange,
  isFavourite,
}) => {
  return (
    <Grid container>
      <TechnologyTopBreadcrumbSection
        name={techDetails?.name || ""}
        sid={techDetails?.sid || ""}
        handleFavouriteChange={handleFavouriteChange}
        isFavourite={isFavourite}
      />
      <TechInfoContainer>
        <TechnologyInfoSection
          name={techDetails?.name || ""}
          description={techDetails?.description || ""}
          loss={techDetails?.monthlyDrops || "0"}
          total={techDetails?.total || ""}
          gain={techDetails?.monthlyGains || "0"}
        />
      </TechInfoContainer>
    </Grid>
  );
};

export default TechnologyHighlightSection;
