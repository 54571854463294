import { useAuth0 } from "@auth0/auth0-react";
import { debounce } from "lodash";
import React, { FC, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { showError } from "../commoncomponent/Client";
import SearchAutoCompleteWrapper from "../commoncomponent/SearchAutoComplete";
import { IfilterAutocomplete } from "../drawer/type/type";
import { useBooleanState, useDataState } from "../hooks/common/hooks";
import { getOrganisationAutoCompleteResults } from "../organisation/util";

interface SearchTechnologyProps {
  handleSelect: (id: string, name?: string) => void;
  variant?: "outlined" | "filled" | "standard";
  searchVariant?: "basic" | "big";
  searchedText?: string;
  isEmptySearch?: boolean;
}

const SearchTechnology: FC<SearchTechnologyProps> = ({
  handleSelect,
  variant,
  searchVariant,
  searchedText,
  isEmptySearch,
}) => {
  const isResultFound = useBooleanState(false);
  const debounceText = useDataState<string>("");
  const isLoading = useBooleanState(false);
  const searchedData = useDataState<IfilterAutocomplete[]>([]);
  const searchText = useDataState<string>("");
  const { logout } = useAuth0();
  const dispatch = useDispatch();

  useEffect(() => {
    searchText.set(searchedText);
  }, [searchedText]);

  useEffect(() => {
    if (debounceText.value === "") {
      return;
    }
    isLoading.setTrue();
    isResultFound.setTrue();
    getOrganisationAutoCompleteResults(debounceText.value || "")
      .then((res) => {
        if (res.length === 0) {
          isResultFound.setFalse();
        }
        searchedData.set(res);
        isLoading.setFalse();
      })
      .catch((err) => {
        dispatch(showError(err, logout));
      });
  }, [debounceText.value]);

  const handleTextChange = useCallback(
    debounce((text) => {
      debounceText.set(text);
    }, 500),
    []
  );

  return (
    <SearchAutoCompleteWrapper
      label={"Search Technology"}
      onChange={(text) => {
        searchText.set(text);
        handleTextChange(text);
      }}
      variant={variant || "outlined"}
      isResultFound={isResultFound.value}
      apiData={searchedData.value || []}
      loading={isLoading.value}
      value={searchText.value || ""}
      onOptionSelect={(option) => {
        handleSelect(option.id, option.name);
        isEmptySearch ? searchText.set("") : searchText.set(option.name);
      }}
      searchVariant={searchVariant}
    />
  );
};

export default SearchTechnology;
