import { Button, Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetToken } from "../../data/authSelector";
import { addDomainList, getUserListAction } from "../../data/LabelsList/action";
import { useGetLabels } from "../../data/LabelsList/selector";
import { useDatagridStyles } from "./useDatagridStyle";
import { useUserListColumns } from "./hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { DataGrid } from "@material-ui/data-grid";
import AddListModal from "./AddListModal";
import { useBooleanState } from "../hooks/common/hooks";
import { showError } from "../commoncomponent/Client";

const UserFacetList = () => {
  const classes = useDatagridStyles();
  const accessToken = useSelector(useGetToken);
  const userFacetList = useSelector(useGetLabels);
  const dispatch = useDispatch();
  const { logout } = useAuth0();
  const showModal = useBooleanState(false);
  const isLoading = useBooleanState(false);

  useEffect(() => {
    accessToken && dispatch(getUserListAction(logout));
  }, [accessToken]);

  const handleSubmit = (name: string) => {
    isLoading.setTrue();
    addDomainList(name)
      .then(() => {
        showModal.setFalse();
        dispatch(getUserListAction(logout));
      })
      .catch((err) => dispatch(showError(err, logout)))
      .finally(() => isLoading.setFalse());
  };

  return (
    <Grid style={{ padding: 16 }}>
      <Grid container justifyContent={"flex-end"}>
        <Button
          onClick={() => showModal.setTrue()}
          variant={"outlined"}
          color={"primary"}
          size={"small"}
        >
          New List
        </Button>
      </Grid>
      <div style={{ height: "100%", width: "100%", marginTop: 15 }}>
        <DataGrid
          rows={userFacetList}
          autoHeight
          columns={useUserListColumns()}
          hideFooterPagination
          hideFooter
          disableSelectionOnClick
          disableColumnMenu
          rowHeight={65}
          headerHeight={42}
          className={classes.datagrid}
        />
      </div>
      {showModal.value && (
        <AddListModal
          handleClose={() => showModal.setFalse()}
          handleSubmit={handleSubmit}
          isLoading={isLoading.value}
        />
      )}
    </Grid>
  );
};

export default UserFacetList;
