import React, { FC } from "react";
import { dropdownConstant } from "../../../constants/SiteConstants";
import { OptionsType } from "../../../data/advancesearch/type";
import AdvanceOptionDropdownView from "./AdvanceOptionDropdownView";
import AdvanceOptionListView from "./AdvanceOptionListView";

type AdvanceSelectOptionDisplayViewProps = {
  displayAs: string;
  value: string;
  options: OptionsType[];
  label: string;
  handleChange: (selectedOptions: string) => void;
  isMultiple: boolean;
  isSearchable?: boolean;
  isTechField?: boolean;
};

const AdvanceSelectOptionDisplayView: FC<AdvanceSelectOptionDisplayViewProps> =
  ({
    displayAs,
    options,
    handleChange,
    value,
    label,
    isMultiple,
    isSearchable,
    isTechField = false,
  }) => {
    if (displayAs === dropdownConstant) {
      return (
        <AdvanceOptionDropdownView
          value={value}
          label={label}
          isSearchable={isSearchable}
          isMultiple={isMultiple}
          dropdownOptions={options}
          isFieldTech={isTechField}
          handleChange={(selectedOption) => handleChange(selectedOption)}
        />
      );
    }

    return (
      <AdvanceOptionListView
        setSelectedOptions={(selectedOptions) => handleChange(selectedOptions)}
        listOptions={options}
        isMultiple={isMultiple}
        label={label}
        value={value.split(",")}
      />
    );
  };

export default AdvanceSelectOptionDisplayView;
