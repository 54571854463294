import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import React, { FC, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { ecommerceFilterOptions } from "../../../data/ecommerceStore/ecommerceSelector";
import { FilterInfoText } from "../../commoncomponent/style";
import { BoxContainer } from "../EcommerceDetailPanel/style";
import { transformEcommerceGraphData } from "./util";
import PieCharts from "../../commoncomponent/Graph/PieCharts";
import { FilterType } from "../../../data/ecommerceStore/type";
import GraphModal from "../../commoncomponent/Graph/GraphModal";

type EcommerceGraphProps = {
  isLoading: boolean;
};

const EcommerceGraph: FC<EcommerceGraphProps> = ({ isLoading }) => {
  const filters = useSelector(ecommerceFilterOptions);
  const [selectedFilter, setSelectedFilter] = useState<FilterType | null>(null);

  const renderGraph = useMemo(
    () =>
      filters.map((filter, index) =>
        filter.name.toLowerCase() !== "list" ? (
          <BoxContainer
            key={`${filter.name}-${index}`}
            style={{ height: "auto", marginTop: 30 }}
          >
            <Grid
              container
              spacing={1}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Grid item>
                <Typography variant={"body1"} color={"primary"}>
                  {filter.name}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant={"contained"}
                  onClick={() => setSelectedFilter(filter)}
                  color={"primary"}
                >
                  Expand
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              justifyContent={"center"}
              style={{ marginBottom: 30 }}
            >
              <Grid item xs={12}>
                <PieCharts
                  key={filter.name}
                  filters={transformEcommerceGraphData(
                    filter.facets,
                    false,
                    false
                  )}
                  name={filter.name}
                />
              </Grid>
            </Grid>
          </BoxContainer>
        ) : null
      ),
    []
  );

  if (isLoading) {
    return (
      <Grid
        container
        style={{ height: "98vh" }}
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Grid>
    );
  }

  if (filters.length > 0) {
    return (
      <>
        {renderGraph}
        {selectedFilter && (
          <GraphModal
            handleClose={() => setSelectedFilter(null)}
            data={selectedFilter}
            name={selectedFilter.name}
          />
        )}
      </>
    );
  }
  return (
    <FilterInfoText variant={"h6"} align={"center"}>
      No Data found
    </FilterInfoText>
  );
};

export default EcommerceGraph;
