import { combineReducers } from "redux";
import { authReducer, AuthType } from "./authReducer";
import { ecommerceFilterReducer } from "./ecommerceStore/reducer";
import { EcommerceFilterType } from "./ecommerceStore/type";
import { filterReducer } from "./organisation/reducer";
import { OrganisationFilterValuesType } from "./organisation/type";
import { commonStateReducer } from "./common/reducer";
import { commonStateType } from "./common/type";
import { TechCategoryType } from "./technologyStore/type";
import { TechReducer } from "./technologyStore/reducer";
import { LabelType } from "./LabelsList/type";
import { LabelReducer } from "./LabelsList/reducer";
import { SubscriptionPlanReducer } from "./subscription/reducer";
import { SubscriptionPlansType } from "./subscription/type";
import { AdvanceSearchType } from "./advancesearch/type";
import { AdvanceSearchReducer } from "./advancesearch/reducer";

export interface IRootReducer {
  auth: AuthType;
  organisationFilter: OrganisationFilterValuesType;
  ecommerceFilter: EcommerceFilterType;
  commonState: commonStateType;
  techFilter: TechCategoryType;
  labels: LabelType[];
  subscriptionPlans: SubscriptionPlansType;
  advanceSearch: AdvanceSearchType;
}

export const rootReducer = combineReducers({
  auth: authReducer,
  organisationFilter: filterReducer,
  ecommerceFilter: ecommerceFilterReducer,
  techFilter: TechReducer,
  commonState: commonStateReducer,
  labels: LabelReducer,
  subscriptionPlans: SubscriptionPlanReducer,
  advanceSearch: AdvanceSearchReducer,
});
