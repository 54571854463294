import React, { FC } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppAndTechView from "./AppAndTechView";
import { EcommerceSocialContactType, TechGroupsType } from "./type";
import SocialDetails from "./SocialDetails";
import TabPanelWrapper from "../../commoncomponent/TabPanelWrapper";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

interface EcommerceTabsViewProps {
  technology: TechGroupsType[];
  contacts?: EcommerceSocialContactType;
}

const EcommerceTabsView: FC<EcommerceTabsViewProps> = ({
  technology,
  contacts,
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (
    //eslint-disable-next-line
    event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default" style={{ boxShadow: "none" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          {contacts?.isSocialContactAvailaible && (
            <Tab style={{ textTransform: "none" }} label="Social & Contact" />
          )}
          <Tab style={{ textTransform: "none" }} label="Apps & Tech" />
        </Tabs>
      </AppBar>
      <TabPanelWrapper value={value} index={0}>
        <SocialDetails social={contacts?.socialContact} />
      </TabPanelWrapper>
      <TabPanelWrapper
        value={value}
        index={contacts?.isSocialContactAvailaible ? 1 : 0}
      >
        <AppAndTechView technology={technology} />
      </TabPanelWrapper>
    </div>
  );
};

export default EcommerceTabsView;
