import { IRootReducer } from "../combineReducer";

export const organisationFilterOptions = (state: IRootReducer) =>
  state.organisationFilter.filter;

export const useGetOrgSelectedSids = (state: IRootReducer) =>
  state.organisationFilter.selectedSids;

export const useGetOrgScrollPosition = (state: IRootReducer) =>
  state.organisationFilter.scrollPosition;

export const useGetOrgLabelList = (state: IRootReducer) =>
  state.organisationFilter.labels;

export const useGetOrgSearchedText = (state: IRootReducer) =>
  state.organisationFilter.searchedText;

export const useGetOrgIsFocusedSearchedText = (state: IRootReducer) =>
  state.organisationFilter.searchedTextFocused;

export const useGetOrgShowGraphView = (state: IRootReducer) =>
  state.organisationFilter.showGraphView;
