import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  installConstant,
  listConstant,
  operationConstant,
} from "../../../constants/SiteConstants";
import { useGetAdvanceSelectedOptions } from "../../../data/advancesearch/selector";
import {
  AdvanceSearchOptionsType,
  SelectedAdvanceFilterValueType,
} from "../../../data/advancesearch/type";
import { updateAdvanceSelectedSearchOptions } from "../../../data/advancesearch/action";
import SearchTechnology from "../../technology/SearchTechnology";
import AdvanceSelectOptionDisplayView from "./AdvanceSelectOptionDisplayView";
import { compact } from "lodash";
import { useRenderSelectedOptions } from "./hooks";

type AdvanceSelectedTechViewProps = {
  selectedOptionInfo?: AdvanceSearchOptionsType;
  selectedFilterInfo?: SelectedAdvanceFilterValueType;
  optionInfoId: number;
  handleFieldChange: (newTechValues: SelectedAdvanceFilterValueType) => void;
};

const AdvanceSelectedTechView: FC<AdvanceSelectedTechViewProps> = ({
  selectedOptionInfo,
  selectedFilterInfo,
  handleFieldChange,
  optionInfoId,
}) => {
  const dispatch = useDispatch();
  const addEcommFilters = useSelector(useGetAdvanceSelectedOptions);

  if (!(selectedOptionInfo && selectedFilterInfo)) {
    return <></>;
  }

  const handleSearchChange = (id: string, name: string) => {
    const index = addEcommFilters.findIndex((item) => item.id === optionInfoId);
    const copyTechValue = addEcommFilters[index].techValue;
    if (copyTechValue) {
      const idArray = compact(copyTechValue[0].sid.split(","));
      const nameArray = compact(copyTechValue[0].name.split(","));
      if (idArray.find((item) => item === id)) {
        const filteredIdArray = idArray.filter((item) => item !== id);
        const filteredNameArray = nameArray.filter((item) => item !== name);
        copyTechValue[0].sid = filteredIdArray.join(",");
        copyTechValue[0].name = filteredNameArray.join(",");
        addEcommFilters[index].techValue = copyTechValue;
      } else {
        copyTechValue[0].sid = [...idArray, id].join(",");
        copyTechValue[0].name = [...nameArray, name].join(",");
        addEcommFilters[index].techValue = copyTechValue;
      }
    }

    dispatch(updateAdvanceSelectedSearchOptions(addEcommFilters));
    handleFieldChange(addEcommFilters[index]);
  };

  const handleChange = (selectedOption: string, type: string) => {
    const index = addEcommFilters.findIndex((item) => item.id === optionInfoId);

    if (type === operationConstant) {
      addEcommFilters[index].operation = selectedOption;
    }
    if (type === installConstant) {
      const copyTechValue = addEcommFilters[index].techValue;
      if (copyTechValue) {
        copyTechValue[0].installOptions = selectedOption;
        addEcommFilters[index].techValue = copyTechValue;
      }
    }
    if (type === listConstant) {
      const copyTechValue = addEcommFilters[index].techValue;
      if (copyTechValue) {
        if (selectedOptionInfo.techConfig?.listData.chooseMultiple) {
          if (
            copyTechValue[0].listVals.filter((item) => item === selectedOption)
          ) {
            copyTechValue[0].listVals = copyTechValue[0].listVals.filter(
              (item) => item === selectedOption
            );
          } else {
            copyTechValue[0].listVals = [
              ...copyTechValue[0].listVals,
              selectedOption,
            ];
          }
        } else {
          const selectedOptionFilterInfo =
            selectedOptionInfo.techConfig?.listData.options.find(
              (item) => item.value === selectedOption
            );
          copyTechValue[0].selectedValuePair = selectedOptionFilterInfo
            ? [selectedOptionFilterInfo]
            : [];
          copyTechValue[0].listVals = [selectedOption];
        }
        addEcommFilters[index].techValue = copyTechValue;
      }
    }
    dispatch(updateAdvanceSelectedSearchOptions(addEcommFilters));
    handleFieldChange(addEcommFilters[index]);
  };

  const renderSelectedOptions = useRenderSelectedOptions(
    selectedFilterInfo?.techValue?.[0].name.split(",") || [],
    selectedFilterInfo?.techValue?.[0].sid.split(",") || [],
    handleSearchChange
  );

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
      <AdvanceSelectOptionDisplayView
        displayAs={selectedOptionInfo.selectionConfig.displayAs || ""}
        handleChange={(selectedOption: string) =>
          handleChange(selectedOption, operationConstant)
        }
        isTechField
        label={selectedOptionInfo.selectionConfig.name || ""}
        options={selectedOptionInfo.selectionConfig.options || []}
        value={selectedFilterInfo.operation || ""}
        isMultiple={selectedOptionInfo.selectionConfig.chooseMultiple || false}
      />
      {renderSelectedOptions}
      <SearchTechnology
        searchVariant={"basic"}
        key={`${optionInfoId}${selectedFilterInfo.operation}`}
        variant={"standard"}
        handleSelect={(id: string, name?: string) =>
          handleSearchChange(id, name || "")
        }
        isEmptySearch={true}
        searchedText={""}
      />
      <AdvanceSelectOptionDisplayView
        displayAs={
          selectedOptionInfo.techConfig?.installListData.displayAs || ""
        }
        handleChange={(selectedOption: string) =>
          handleChange(selectedOption, installConstant)
        }
        label={selectedOptionInfo.techConfig?.installListData.name || ""}
        options={selectedOptionInfo.techConfig?.installListData.options || []}
        value={
          selectedFilterInfo.techValue
            ? selectedFilterInfo.techValue[0].installOptions
            : ""
        }
        isTechField
        isMultiple={
          selectedOptionInfo.techConfig?.installListData.chooseMultiple || false
        }
      />
      {selectedFilterInfo.techValue &&
        selectedFilterInfo.techValue[0].installOptions && (
          <AdvanceSelectOptionDisplayView
            displayAs={selectedOptionInfo.techConfig?.listData.displayAs || ""}
            handleChange={(selectedOption: string) =>
              handleChange(selectedOption, listConstant)
            }
            label={selectedOptionInfo.techConfig?.listData.name || ""}
            options={selectedOptionInfo.techConfig?.listData.options || []}
            value={
              selectedFilterInfo.techValue
                ? selectedFilterInfo.techValue[0].listVals.join(",")
                : ""
            }
            isTechField
            isMultiple={
              selectedOptionInfo.techConfig?.listData.chooseMultiple || false
            }
          />
        )}
    </div>
  );
};

export default AdvanceSelectedTechView;
