import { useAuth0 } from "@auth0/auth0-react";
import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  Grid,
  TextField,
  Button,
} from "@material-ui/core";
import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { successToastConfiguration } from "../../constants/ColorConstants";
import { useGetAdvanceSelectedOptions } from "../../data/advancesearch/selector";
import { LabelType } from "../../data/LabelsList/type";
import { showError } from "../commoncomponent/Client";
import { useDataState } from "../hooks/common/hooks";
import { addLabel, addNewAdvanceSearchLabel } from "./util";

interface AddNewLabelProps {
  closeModal: () => void;
  selectedSids: string[];
  saveComplete: () => void;
  selectedLabel?: LabelType;
  type: string;
  labelSaveType?: "advance" | "basic";
}

const AddNewLabel: FC<AddNewLabelProps> = ({
  closeModal,
  selectedSids,
  saveComplete,
  selectedLabel,
  type,
  labelSaveType = "basic",
}) => {
  const labelName = useDataState<string>(selectedLabel?.name || "");
  const { logout } = useAuth0();
  const dispatch = useDispatch();
  const advanceFilterLists = useSelector(useGetAdvanceSelectedOptions);
  const searchParams = new URLSearchParams(window.location.search);

  const addNewLabel = () => {
    if (labelSaveType === "advance") {
      addNewAdvanceSearchLabel(labelName.value || "", advanceFilterLists, type)
        .then(() => {
          toast.success("List added", successToastConfiguration);
          saveComplete();
          closeModal();
        })
        .catch((err) => {
          dispatch(showError(err, logout));
        });
      return;
    }
    addLabel(
      labelName.value || "",
      selectedSids,
      type,
      searchParams.get("text") || ""
    )
      .then(() => {
        toast.success("List added", successToastConfiguration);
        saveComplete();
        closeModal();
      })
      .catch((err) => {
        dispatch(showError(err, logout));
      });
    return;
  };

  return (
    <Dialog open onClose={closeModal} fullWidth maxWidth="xs">
      <DialogTitle>
        <Typography variant="h6">
          {!selectedLabel ? "Create New List" : "Update list"}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ paddingBottom: 30 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body2">List Name</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              disabled={!!selectedLabel?.name}
              value={selectedLabel?.name || labelName.value}
              onChange={(event) => labelName.set(event.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              disabled={!labelName.value}
              variant="outlined"
              color="primary"
              onClick={addNewLabel}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default AddNewLabel;
