import { GridCellParams } from "@material-ui/data-grid";
import { useMemo } from "react";
import { getDomainImage } from "../../utils/utility";
import { DomainImageContainer } from "./style";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { CheckIconColor, CrossIconColor } from "../../constants/ColorConstants";
import { FilterType } from "../../data/ecommerceStore/type";
import FiltersView from "../commoncomponent/Filters/FiltersView";
import { CompanyLogo } from "../commoncomponent/style";

export const useEcommerceFilteredListColumns = () =>
  useMemo(
    () => [
      {
        field: "domain",
        headerName: "Domain",
        flex: 2,
        renderCell: (params: GridCellParams) => (
          <DomainImageContainer>
            <CompanyLogo src={getDomainImage(params.row.domain)} />
            <span>{params.row.domain}</span>
          </DomainImageContainer>
        ),
      },
      {
        field: "country",
        headerName: "Country",
        flex: 1,
      },
      {
        field: "contact",
        headerName: "Contact",
        flex: 1,
        renderCell: (params: GridCellParams) =>
          params.row.contact === "yes" ? (
            <CheckIcon style={{ color: CheckIconColor }} />
          ) : (
            <CloseIcon style={{ color: CrossIconColor }} />
          ),
      },
      {
        field: "platform",
        headerName: "Platform",
        flex: 1,
      },
    ],
    []
  );

export const useShowFilter = (
  filters: FilterType[],
  selectedSids: string[],
  handleSelect: (sid: string) => void
) =>
  useMemo(
    () =>
      filters.map((item) => (
        <FiltersView
          isCollapsed={item.cfg.isCollapsed}
          key={`${Math.random()}`}
          name={item.name}
          checkboxOption={item.facets}
          isChecked={selectedSids || []}
          handleFilterChange={handleSelect}
          noOfItemToShow={item.cfg.defaultSize}
        />
      )),
    [filters]
  );
