import { createTheme } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/styles";

const defaultTheme = createTheme();
export const useDatagridStyles = makeStyles(
  () =>
    createStyles({
      datagrid: {
        "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
          fontSize: 14,
          outline: "none !important",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          fontWeight: 600,
          letterSpacing: "0.5px",
          overflow: "visible",
          textOverflow: "unset",
        },
        "& .MuiDataGrid-columnHeaderTitleContainer": {
          padding: 0,
        },
      },
    }),
  { defaultTheme }
);
