import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Box, Card, CardContent } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import logo from "../../assets/logo/stackbrain_logo.compressed.png";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { CircularProgress } from "@material-ui/core";
import { useAuth0 } from "@auth0/auth0-react";

const useStyles = makeStyles({
  root: {
    minWidth: "200px",
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: "5% 0",
  },
  imgContent: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginBottom: "3%",
  },
});

const Logout = () => {
  const classes = useStyles();
  const history = useHistory();
  const { logout } = useAuth0();

  useEffect(() => {
    logout();
    setTimeout(() => {
      history.push("/");
    }, 3000);
  }, []);

  return (
    <div className={classes.container}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems={"center"}
        style={{ minHeight: "100vh" }}
      >
        <Grid item md={4} xs={12}>
          <Box style={{ display: "inline-block" }}>
            <div className={classes.imgContent}>
              <img src={logo} alt="Stack Brain" style={{ width: "60%" }} />
            </div>
            <Card className={classes.root}>
              <CardContent>
                <div className={classes.content}>
                  <Typography variant="h6" style={{ marginBottom: "3%" }}>
                    Logging out...
                  </Typography>
                  <CircularProgress />
                </div>
              </CardContent>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default Logout;
