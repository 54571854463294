import React, { FC, useEffect } from "react";
import {
  FilterAccordian,
  AccordianSummaryContainer,
  AccordianDetailsContainer,
} from "../../commoncomponent/style";
import { SideNavigationLink, LinkTitle } from "../../drawer/SideNavbarStyle";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { CheckboxOptionType } from "./type";
import { useBooleanState, useDataState } from "../../hooks/common/hooks";
import { noOfFilterToShow } from "../../../constants/SiteConstants";
import { useGetShowFilterCheckBox, useGetShowMoreOrLessButton } from "./hooks";
import { IconButton } from "@material-ui/core";
import TextFieldWrapper from "../TextFieldWrapper";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import DownloadIcon from "@mui/icons-material/Download";
import { downloadSelectedFilterCsv } from "./util";

interface FiltersViewProps {
  name: string;
  checkboxOption: CheckboxOptionType[];
  isChecked: string[];
  handleFilterChange: (sid: string) => void;
  isCollapsed?: boolean;
  noOfItemToShow?: number;
}

const FiltersView: FC<FiltersViewProps> = ({
  name,
  checkboxOption,
  isChecked,
  handleFilterChange,
  isCollapsed = false,
  noOfItemToShow = noOfFilterToShow,
}) => {
  const filteredCheckbox = useDataState<CheckboxOptionType[]>(checkboxOption);
  const searchItem = useDataState<string>("");
  const isExpanded = useBooleanState(isCollapsed);
  const totalNumberOfResult = useDataState(noOfItemToShow);
  const showSearchBox = useBooleanState();

  useEffect(() => {
    totalNumberOfResult.set(noOfItemToShow);
  }, [isCollapsed, noOfItemToShow]);

  const showFilterCheckbox = useGetShowFilterCheckBox(
    filteredCheckbox.value || [],
    totalNumberOfResult.value || noOfItemToShow,
    isChecked,
    handleFilterChange
  );

  const onSearchFilter = (text: string) => {
    searchItem.set(text);
    filteredCheckbox.set(
      checkboxOption.filter((item) =>
        item.name.toLowerCase().includes(text.toLowerCase())
      )
    );
  };

  const setTotalNumberOfResult = (totalNumber: number) =>
    totalNumberOfResult.set(totalNumber);

  const getShowMoreOrLessButton = useGetShowMoreOrLessButton(
    filteredCheckbox.value?.length || 0,
    totalNumberOfResult.value || noOfItemToShow,
    noOfItemToShow,
    setTotalNumberOfResult
  );

  return (
    <FilterAccordian expanded={!isExpanded.value}>
      <AccordianSummaryContainer
        isaccordianselected={!isExpanded.value ? 1 : 0}
        expandIcon={
          <span
            onClick={() => {
              isExpanded.toggle();
              totalNumberOfResult.set(noOfItemToShow);
            }}
          >
            <ExpandMoreIcon />
          </span>
        }
      >
        <SideNavigationLink
          button
          onClick={() => {
            isExpanded.toggle();
            totalNumberOfResult.set(noOfItemToShow);
          }}
        >
          <LinkTitle ismenuselected={!isExpanded.value ? 1 : 0}>
            {name}
          </LinkTitle>
        </SideNavigationLink>
        {!isExpanded.value &&
          (!showSearchBox.value ? (
            <IconButton
              title="Expand Searchbox"
              aria-label="Expand Searchbox"
              size="small"
              onClick={() => showSearchBox.setTrue()}
            >
              <SearchIcon fontSize="small" />
            </IconButton>
          ) : (
            <TextFieldWrapper
              value={searchItem.value}
              onChange={({ target }) => onSearchFilter(target.value)}
              placeholder={"Search"}
              isBasic
              InputProps={{
                endAdornment: (
                  <IconButton
                    title="Clear"
                    aria-label="Clear"
                    size="small"
                    onClick={() => {
                      showSearchBox.setFalse();
                      searchItem.set("");
                      onSearchFilter("");
                    }}
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>
                ),
              }}
            />
          ))}
        <IconButton
          title="Download CSV"
          size="small"
          onClick={() => downloadSelectedFilterCsv(checkboxOption, name)}
        >
          <DownloadIcon fontSize="small" />
        </IconButton>
      </AccordianSummaryContainer>
      <AccordianDetailsContainer
        isaccordianselected={isExpanded.value ? 1 : 0}
        style={{ display: "block" }}
        key={checkboxOption.length.toString()}
      >
        {showFilterCheckbox}
        {getShowMoreOrLessButton}
      </AccordianDetailsContainer>
    </FilterAccordian>
  );
};

export default FiltersView;
