import { GridCellParams } from "@material-ui/data-grid";
import { difference } from "lodash";
import { useMemo } from "react";
import DataGridFirstColumn from "../../commoncomponent/DataGridFirstColumn";
import { ColumnType } from "../../organisation/type";

export const useFilteredListColumns = (columns: ColumnType[]) =>
  useMemo(() => {
    const data: any = [];
    data.push({
      field: "domain",
      headerName: "Domain",
      flex: 2,
      renderCell: (params: GridCellParams) => (
        <DataGridFirstColumn
          name={params.row.firstCol.name}
          websiteUrl={params.row.firstCol.website}
          socialLinks={difference(Object.keys(params.row.firstCol), [
            "name",
          ]).map((item) => ({
            name: item,
            url: params.row.firstCol[item],
          }))}
        />
      ),
    });
    data.push(
      ...columns.map((column) => ({
        field: column.fieldName,
        headerName: column.name,
        flex: 1,
      }))
    );
    return data;
  }, [columns]);
