import { Grid, IconButton, Typography } from "@material-ui/core";
import React, { FC, useRef } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { noOfFilterToShow } from "../../constants/SiteConstants";
import {
  updateAlreadySelectedSid,
  updateTechScrollPosition,
  updateTechSids,
} from "../../data/technologyStore/action";
import {
  useGetTechCategoryOptions,
  useGetTechScrollPosition,
  useGetTechSelectedSids,
} from "../../data/technologyStore/selector";
import { CategoryType } from "../../data/technologyStore/type";
import TextFieldWrapper from "../commoncomponent/TextFieldWrapper";
import { DrawerNavigation } from "../drawer/SideNavbarStyle";
import { useBooleanState, useDataState } from "../hooks/common/hooks";
import { useGetShowTechCategory } from "./hooks";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import SearchTechnology from "./SearchTechnology";

interface TechnologyFilterSectionProps {
  setSelectedTechName: (techName: string) => void;
}

const TechnologyFilterSection: FC<TechnologyFilterSectionProps> = ({
  setSelectedTechName,
}) => {
  const category = useSelector(useGetTechCategoryOptions);
  const selectedSid = useSelector(useGetTechSelectedSids);
  const filteredList = useDataState<CategoryType[]>(category);
  const searchItem = useDataState<string>("");
  const totalNumberOfResult = useDataState<number>(noOfFilterToShow);
  const dispatch = useDispatch();
  const history = useHistory();
  const showSearchBox = useBooleanState(false);
  const scrollRef = useRef<HTMLDivElement>(null);
  const scrollPosition = useSelector(useGetTechScrollPosition);

  const handleSelect = (sid: string) => {
    scrollRef.current &&
      dispatch(updateTechScrollPosition(scrollRef.current.scrollTop));
    dispatch(updateTechSids(sid));
    dispatch(updateAlreadySelectedSid(true));
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get("sid") !== sid) {
      searchParams.delete("sid");
      searchParams.append("sid", sid);
    }
    history.push(`?${searchParams}`);
  };

  const showCategories = useGetShowTechCategory(
    selectedSid,
    filteredList.value || [],
    totalNumberOfResult.value,
    handleSelect
  );

  const onSearchFilter = (text: string) => {
    searchItem.set(text);
    filteredList.set(
      category.filter((item) =>
        item.name.toLowerCase().includes(text.toLowerCase())
      )
    );
  };

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollPosition;
    }
  }, [scrollRef.current]);

  useEffect(() => {
    filteredList.set(category);
    const selectedTech = category.find((item) => item.sid === selectedSid);
    selectedTech && setSelectedTechName(selectedTech.name);
  }, [category]);

  const techSelect = (id: string) => {
    history.push(`/tech/browse/detail?techsid=${id}`);
  };

  return (
    <DrawerNavigation ref={scrollRef}>
      <Grid item style={{ margin: "10px 0px 15px 0px" }}>
        <SearchTechnology handleSelect={techSelect} />
      </Grid>
      <Grid
        container
        justifyContent={"space-between"}
        style={{ padding: "0px 5px 0px 5px" }}
      >
        <Typography variant={"body2"} color={"primary"}>
          Categories
        </Typography>
        {!showSearchBox.value ? (
          <IconButton
            title="Expand Searchbox"
            aria-label="Expand Searchbox"
            size="small"
            onClick={() => showSearchBox.setTrue()}
          >
            <SearchIcon fontSize="small" />
          </IconButton>
        ) : (
          <TextFieldWrapper
            value={searchItem.value}
            onChange={({ target }) => onSearchFilter(target.value)}
            placeholder={"Search"}
            style={{ width: "50%" }}
            InputProps={{
              endAdornment: (
                <IconButton
                  title="Clear"
                  aria-label="Clear"
                  size="small"
                  onClick={() => {
                    showSearchBox.setFalse();
                    searchItem.set("");
                    onSearchFilter("");
                  }}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              ),
            }}
          />
        )}
      </Grid>
      {showCategories}
    </DrawerNavigation>
  );
};

export default TechnologyFilterSection;
