import { Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetToken } from "../../data/authSelector";
import {
  updateEcommerceFilters,
  updateEcommerceShowGraphView,
  updateEcommSearchedText,
  updateSids,
} from "../../data/ecommerceStore/action";
import {
  FilterInfoText,
  FilterOptionsGridContainer,
} from "../commoncomponent/style";
import { useBooleanState, useDataState } from "../hooks/common/hooks";
import EcommerceDataGrid from "./EcommerceDataGrid";
import EcommerceItemDetailPanel from "./EcommerceDetailPanel/EcommerceItemDetailPanel";
import { EcommerceParentContainer, GraphDataGridContainer } from "./style";
import { EcommerceItemInfo } from "./type";
import {
  noOfEcommerceItemList,
  noOfRecordPerPage,
} from "../../constants/SiteConstants";
import { showError } from "../commoncomponent/Client";
import {
  ecommerceValidateUrl,
  getAdvanceSearchResult,
  getEcommerceResults,
  transformUrl,
} from "./util";
import EcommerceFilterSection from "./EcommerceFilterSection";
import PaginationGrid from "../commoncomponent/GridPagination";
import { useAuth0 } from "@auth0/auth0-react";
import { useGetAdvanceSelectedOptions } from "../../data/advancesearch/selector";
import { formatNumber } from "../../utils/utility";
import ExportFile from "../export/ExportDialog";
import EcommerceGraph from "./EcommerceGraph/EcommerceGraph";
import { useGetEcommShowGraphView } from "../../data/ecommerceStore/ecommerceSelector";
import { ColumnType } from "../organisation/type";

const EcommerceView = () => {
  const accessToken = useSelector(useGetToken);
  const dispatch = useDispatch();
  const filterResultList = useDataState<EcommerceItemInfo[]>([]);
  const totalCount = useDataState<string>("");
  const isError = useBooleanState(false);
  const showDrawer = useBooleanState(false);
  const selectedDomain = useDataState<string>("");
  const isUrlValid = useBooleanState(true);
  const isLoading = useBooleanState(false);
  const { logout } = useAuth0();
  const advanceFilterLists = useSelector(useGetAdvanceSelectedOptions);
  const showExportDialog = useBooleanState(false);
  const [pageCount, setPageCount] = useState<number>(1);
  const isAdvanceSearchOpen = useBooleanState(false);
  const showGraph = useSelector(useGetEcommShowGraphView);
  const [columns, setColumns] = useState<ColumnType[]>([]);

  useEffect(() => {
    if (accessToken !== "") {
      if (!ecommerceValidateUrl(location.search)) {
        fetchEcommerceData();
        isUrlValid.setTrue();
      } else {
        isUrlValid.setFalse();
      }
    }
    return () => {
      filterResultList.set([]);
    };
  }, [accessToken]);

  const fetchEcommerceData = (offsetValue = 0) => {
    isLoading.setTrue();
    isError.setFalse();
    dispatch(updateSids(transformUrl(location.search, 0).sids));
    dispatch(updateEcommSearchedText(transformUrl(location.search, 0).text));
    getEcommerceResults(location.search, offsetValue)
      .then((data) => {
        dispatch(updateEcommerceFilters(data.filter));
        const activeCols = data.activeCols.map((item) => ({
          ...data.colMetaData[item],
          fieldName: item,
        }));
        setColumns(activeCols as ColumnType[]);
        filterResultList.set(data.rows);
        totalCount.set(data.total);
        data.rows.length === 0 && isError.setTrue();
      })
      .catch((error) => {
        isError.setTrue();
        dispatch(showError(error, logout));
      })
      .finally(() => isLoading.setFalse());
  };

  const handleAdvanceSearch = (offset = 0) => {
    isLoading.setTrue();
    isError.setFalse();
    offset === 0 && setPageCount(1);
    getAdvanceSearchResult(advanceFilterLists, offset)
      .then((res) => {
        filterResultList.set(res.rows);
        totalCount.set(res.count.toString());
        res.rows.length === 0 && isError.setTrue;
      })
      .catch((error) => {
        isError.setTrue();
        dispatch(showError(error, logout));
      })
      .finally(() => isLoading.setFalse());
  };

  return (
    <EcommerceParentContainer isurlvalid={isUrlValid.value ? 1 : 0} container>
      {isUrlValid.value ? (
        <>
          <FilterOptionsGridContainer item xs={12} sm={3}>
            <EcommerceFilterSection
              handleGraphChange={() =>
                dispatch(updateEcommerceShowGraphView(!showGraph))
              }
              handleAdvanceSearch={() => handleAdvanceSearch()}
              setIsAdvanceSearchOpen={() => {
                dispatch(updateEcommerceShowGraphView(false));
                isAdvanceSearchOpen.toggle();
              }}
              isAdvanceSearchOpen={isAdvanceSearchOpen.value}
              showChartView={showGraph}
            />
          </FilterOptionsGridContainer>
          <GraphDataGridContainer
            item
            xs={12}
            sm={9}
            height={showGraph ? "100vh" : "auto"}
            overflow={showGraph ? "auto" : "initial"}
          >
            {showGraph ? (
              <EcommerceGraph
                key={Math.random().toString()}
                isLoading={isLoading.value}
              />
            ) : (
              <>
                <EcommerceDataGrid
                  activeColumns={columns}
                  list={filterResultList.value || []}
                  noResultFound={isError.value}
                  isLoading={isLoading.value}
                  itemSelect={(domain: string) => {
                    showDrawer.setTrue();
                    selectedDomain.set(domain);
                  }}
                  handleExport={() => showExportDialog.setTrue()}
                />
                <Grid container alignItems={"center"}>
                  <Grid item xs={10}>
                    <PaginationGrid
                      noOfItemToShow={noOfEcommerceItemList}
                      pageCount={pageCount || 1}
                      setPageCount={(count) => {
                        setPageCount(count);
                        isAdvanceSearchOpen.value
                          ? handleAdvanceSearch(
                              count === 1 ? 0 : (count - 1) * noOfRecordPerPage
                            )
                          : fetchEcommerceData(
                              count === 1 ? 0 : (count - 1) * noOfRecordPerPage
                            );
                      }}
                      totalCount={
                        totalCount.value ? parseInt(totalCount.value) : 0
                      }
                    />
                  </Grid>
                  <Grid item xs={2}>
                    {totalCount.value && (
                      <FilterInfoText variant={"body1"}>
                        {formatNumber(parseInt(totalCount.value))}{" "}
                        {parseInt(totalCount.value) !== 0 && "records"}
                      </FilterInfoText>
                    )}
                  </Grid>
                </Grid>
              </>
            )}
          </GraphDataGridContainer>
        </>
      ) : (
        <FilterInfoText variant={"h6"} align={"center"}>
          This link is incorrect. Please contact support.
        </FilterInfoText>
      )}
      {showDrawer.value && selectedDomain.value && (
        <EcommerceItemDetailPanel
          handleClose={() => showDrawer.setFalse()}
          selectedDomain={selectedDomain.value}
        />
      )}
      {showExportDialog.value && (
        <ExportFile
          isBasicSearch={isAdvanceSearchOpen.value}
          pageCount={
            pageCount && pageCount === 1
              ? 0
              : (pageCount - 1) * noOfRecordPerPage
          }
          exportFileType={"ecomm"}
          handleClose={() => showExportDialog.setFalse()}
          offset={pageCount === 1 ? 0 : (pageCount - 1) * noOfRecordPerPage}
        />
      )}
    </EcommerceParentContainer>
  );
};

export default EcommerceView;
