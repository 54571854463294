import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { DropContainerText } from "./style";

type FileDropZoneProps = {
  setSelectedFile: (file: File) => void;
};

const FileDropZone: React.FC<FileDropZoneProps> = ({ setSelectedFile }) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles[0].id = Math.random();
      setSelectedFile(acceptedFiles[0]);
    },
    [setSelectedFile]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div style={{ marginTop: 30 }}>
      {isDragActive ? (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <DropContainerText>Drop File</DropContainerText>
        </div>
      ) : (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <DropContainerText>
            Drag and drop some files here, or click to select files
          </DropContainerText>
        </div>
      )}
    </div>
  );
};

export default FileDropZone;
