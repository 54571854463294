import { Slide, toast } from "react-toastify";

export const NavigationSelectedBackground = "#E7EAFF";
export const NavigationNonSelectedBackground = "#FCFDFF";
export const NavigationSelectedText = "#2E3FAC";
export const NavigationNonSelectedText = "#727A88";
export const ReportProblemIconColor = "rgb(255, 188, 17)";
export const IdeaIconColor = "#fdfd11";
export const NavMenuColor = `#2D72DA`;
export const TextPrimaryColor = `#1B2C4B`;
export const SelectedAccordianColor = `#ECEEF0`;
export const TextSecondaryColor = `#2D72DA`;
export const PrimaryButtonColor = `#2D72DA`;
export const GridBackgroundColor = `rgb(246, 247, 248)`;
export const CrossIconColor = `red`;
export const CheckIconColor = `green`;
export const TechPrimaryColor = `#41AE7C`;
export const TechSecondaryTextColor = `#F0BC4F`;
export const TechLossTextColor = `#72C0C5`;
export const LabelPopperColor = `#586069`;
export const LabelPopperBackgroundColor = `#f6f8fa`;
export const ScrollBarColor = `#d2d1d1`;
export const RedColor = "rgba(240, 70, 70, 0.2)";
export const YellowColor = "rgba(255, 217, 84, 0.3)";
export const LightBlueColor = "rgb(213 197 243 / 20%)";
export const GoldenColor = "rgb(244 206 0)";
export const LightYellowColor = "rgb(43 243 49 / 16%)";
export const SelectedNavigationMenu = "#245BAE";
export const white = `#ffff`;
export const lightGrey = `#efefef`;
export const planBgColor = `#e8effa`;
export const planMessageColor = `#9299A4`;
export const dangerColor = `#FF7C7C`;
export const advanceGridColor = `#e8effa`;
export const advanceGridBorderColor = `#a9c3ec`;
export const wrongIconColor = `#A00301`;
export const correctIconColor = `#2AC18E`;
export const primaryDarkFontColor = `#323232`;
export const secondarySubHeadingFontColor = `grey`;
export const iconsColor = `#949fb1`;
export const black = `rgba(0, 0, 0, 0.87)`;
export const shadowColor = `#d3dae3`;
export const graphColor = [
  "#2A6A99",
  "#3C97DA",
  "#9ECBED",
  "#DDDDDD",
  "#FFCEA9",
  "#FE9D52",
  "#B26E39",
  "#B3896A",
  "#B39782",
  "#B3A59A",
];

export const errorToastConfiguration = {
  position: toast.POSITION.TOP_RIGHT,
  autoClose: 3000,
  hideProgressBar: false,
  draggable: true,
  transition: Slide,
  pauseOnFocusLoss: false,
  style: {
    backgroundColor: "rgba(218, 59, 1, 0.7)",
  },
};

export const successToastConfiguration = {
  position: toast.POSITION.TOP_RIGHT,
  autoClose: 3000,
  hideProgressBar: false,
  draggable: true,
  transition: Slide,
  pauseOnFocusLoss: false,
  style: {
    backgroundColor: "rgba(0, 120, 212, 0.8)",
  },
};
