import { Grid, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { BoxContainer } from "./style";
import { EcommerceContactType } from "./type";
import CardDetailsTitle from "./SocialDeatilsCardTitle";
import { getDomainImage } from "../../../utils/utility";
import Email from "../../../images/envelope.svg";
import PhoneIcon from "@material-ui/icons/Phone";

interface SocialDetailsProps {
  social?: EcommerceContactType;
}

const getSocialLinks = (links: string[], mailTo = false) => {
  return links.map((item, index) => (
    <div key={`${item}-${index}`}>
      <Typography variant={"caption"} color={"textPrimary"}>
        {mailTo ? (
          <>
            Mail To:{" "}
            <a href={`mailTo:${item}`} target={"_blank"}>
              {item}
            </a>
          </>
        ) : (
          <a href={item} target={"_blank"}>
            {item}
          </a>
        )}
      </Typography>
    </div>
  ));
};

const SocialDetails: FC<SocialDetailsProps> = ({ social }) => {
  return (
    <Grid container spacing={5}>
      {social?.facebook && (
        <Grid item xs={6}>
          <BoxContainer>
            <CardDetailsTitle
              icon={
                <img
                  src={getDomainImage("www.facebook.com")}
                  height={20}
                  width={20}
                />
              }
              title={"Facebook"}
            />
            {getSocialLinks(social.facebook)}
          </BoxContainer>
        </Grid>
      )}
      {social?.emails && (
        <Grid item xs={6}>
          <BoxContainer>
            <CardDetailsTitle
              icon={<img src={Email} height={25} width={25} />}
              title={"Email"}
            />
            {getSocialLinks(social.emails, true)}
          </BoxContainer>
        </Grid>
      )}
      {social?.insta && (
        <Grid item xs={6}>
          <BoxContainer>
            <CardDetailsTitle
              icon={
                <img
                  src={getDomainImage("www.instagram.com")}
                  height={20}
                  width={20}
                />
              }
              title={"Instagram"}
            />
            {getSocialLinks(social.insta)}
          </BoxContainer>
        </Grid>
      )}
      {social?.lns && (
        <Grid item xs={6}>
          <BoxContainer>
            <CardDetailsTitle
              icon={
                <img
                  src={getDomainImage("www.linkedin.com")}
                  height={20}
                  width={20}
                />
              }
              title={"Linkedin"}
            />
            {getSocialLinks(social.lns)}
          </BoxContainer>
        </Grid>
      )}
      {social?.tels && (
        <Grid item xs={6}>
          <BoxContainer>
            <CardDetailsTitle icon={<PhoneIcon />} title={"Telephone"} />
            {getSocialLinks(social.tels)}
          </BoxContainer>
        </Grid>
      )}
      {social?.tks && (
        <Grid item xs={6}>
          <BoxContainer>
            <CardDetailsTitle
              icon={
                <img
                  src={getDomainImage("www.tiktok.com")}
                  height={20}
                  width={20}
                />
              }
              title={"Tiktok"}
            />
            {getSocialLinks(social.tks)}
          </BoxContainer>
        </Grid>
      )}
      {social?.tws && (
        <Grid item xs={6}>
          <BoxContainer>
            <CardDetailsTitle
              icon={
                <img
                  src={getDomainImage("www.twitter.com")}
                  height={20}
                  width={20}
                />
              }
              title={"Twitter"}
            />
            {getSocialLinks(social.tws)}
          </BoxContainer>
        </Grid>
      )}
      {social?.yts && (
        <Grid item xs={6}>
          <BoxContainer>
            <CardDetailsTitle
              icon={
                <img
                  src={getDomainImage("www.youtube.com")}
                  height={20}
                  width={20}
                />
              }
              title={"Youtube"}
            />
            {getSocialLinks(social.yts)}
          </BoxContainer>
        </Grid>
      )}
    </Grid>
  );
};

export default SocialDetails;
