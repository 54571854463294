import { downloadFileApiUrl } from "../../constants/ApiConstants";
import { Client } from "../commoncomponent/Client";

export const getDownloadFile = (id: string) => {
  return Client.getInstance()
    .getData(downloadFileApiUrl(id))
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "stackbrain-accounts.csv");
      document.body.appendChild(link);
      link.click();
      return Promise.resolve(res);
    })
    .catch((err) => Promise.reject(err));
};
