import React, { FC } from "react";
import { Grid } from "@material-ui/core";
import { CheckboxItemText } from "../commoncomponent/style";

interface LabelListItemProps {
  sid: string;
  name: string;
  selectedLabel: (labelSid: string, labelName: string) => void;
}
const LabelListItem: FC<LabelListItemProps> = ({
  sid,
  name,
  selectedLabel,
}) => (
  <Grid
    container
    key={sid}
    alignItems="center"
    spacing={2}
    style={{ cursor: "pointer" }}
    onClick={() => selectedLabel(sid, name)}
  >
    <Grid item xs={8}>
      <CheckboxItemText>{name}</CheckboxItemText>
    </Grid>
  </Grid>
);

export default LabelListItem;
