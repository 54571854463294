import { Button, Grid } from "@material-ui/core";
import React, { FC } from "react";
import { ReactourStepContentArgs } from "reactour";

type TourWrapperProps = {
  title: string;
  contentProps: ReactourStepContentArgs;
};
const TourWrapper: FC<TourWrapperProps> = ({
  title,
  children,
  contentProps,
}) => {
  return (
    <div>
      {title}
      <br />
      {children}
      <Grid
        container
        style={{ marginTop: 20 }}
        spacing={1}
        justifyContent={"space-between"}
      >
        <Grid item>
          <Grid container spacing={1}>
            <Grid item>
              <Button
                disabled={contentProps.step === 1}
                size={"small"}
                variant={"outlined"}
                onClick={() => contentProps.goTo(contentProps.step - 2)}
                color={"primary"}
              >
                Prev
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() => contentProps.goTo(contentProps.step)}
                disabled={contentProps.step === 3}
                size={"small"}
                variant={"outlined"}
                color={"primary"}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Button
            onClick={() => contentProps.close()}
            size={"small"}
            variant={"contained"}
            color={"primary"}
          >
            Skip
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default TourWrapper;
