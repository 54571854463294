import {
  Grid,
  Divider,
  TextField,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ListItemText,
  Button,
} from "@material-ui/core";
import Styled from "styled-components";
import { NavMenuColor, shadowColor } from "../../constants/ColorConstants";
import {
  GridBackgroundColor,
  PrimaryButtonColor,
  SelectedAccordianColor,
  TextPrimaryColor,
  TextSecondaryColor,
} from "../../constants/ColorConstants";

export const LoadingComponentContainer = Styled(Grid)`
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
`;

interface ParentContainerProps {
  isurlvalid: number;
}

export const ParentContainer = Styled(Grid)`
    justify-content: ${(props: ParentContainerProps) =>
    !props.isurlvalid && "center"};
    margin-top: ${(props: ParentContainerProps) => !props.isurlvalid && "50px"};
  `;

export const HorizontalDivider = Styled(Divider)`
  background: ${NavMenuColor};
  height: 3px;
`;

type MatTextField = {
  isbasic?: number;
};

export const MatTextField = Styled(TextField) <MatTextField>`
  ${(props) =>
    props.isbasic &&
    `
  & .MuiInputBase-input{
    font-size: 13px;
  }
  `}
`;

export const ListColumnText = Styled(Typography)`
  font-size: 14px;
`;

export const CardContainer = Styled.div`
    cursor: pointer;
    padding: 5px 20px 10px 20px;
    border-bottom: 1px solid lightgrey !important;
    line-break: anywhere;
    border: 1px solid white;
    span{
      color: ${TextPrimaryColor};
    }
    &:hover {
      border: 1px solid ${TextSecondaryColor};
      border-bottom: 1px solid ${TextSecondaryColor} !important;
      border-radius: 3px;
      span {
        color: ${TextSecondaryColor} !important
      }
    }
`;

export const ItemTitle = Styled.p`
	margin: 0px;
  font-size: 14px;
  font-weight: 500;
`;

export const ItemDomain = Styled.p`
	margin: 0px;
	margin-top: 5px;
	font-size: 12px;
    color: black;
`;

export const FilterAccordian = Styled(Accordion)`
    border-radius: 10px;
    background: inherit;
    box-shadow: none;
    margin: 0px !important;
`;

type AccordianContainerProps = {
  isaccordianselected: number;
};
export const AccordianSummaryContainer = Styled(AccordionSummary)`
    background-color: ${(props: AccordianContainerProps) =>
    props.isaccordianselected ? SelectedAccordianColor : "inherit"};
    min-height: ${(props: AccordianContainerProps) =>
    props.isaccordianselected ? "0px !important" : "40px !important"};
    & .MuiAccordionSummary-content {
      margin: 0;
    }
    & .MuiIconButton-root{
      padding: 0;
    }
`;

export const AccordianDetailsContainer = Styled(AccordionDetails)`
    background-color: ${(props: AccordianContainerProps) =>
    props.isaccordianselected ? SelectedAccordianColor : "inherit"};
    padding-top: 0px;
`;

export const FilterInfoText = Styled(Typography)`
      color: ${TextPrimaryColor};
      font-style: italic;
      font-size: 14px;
`;

export const FilterOptionsGridContainer = Styled(Grid)`
      padding-top: 24px;
      padding-left: 5px;
      background: ${GridBackgroundColor};
`;

interface CheckboxItemsContainerType {
  isselected?: number;
}
export const CheckboxItemsContainer = Styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    padding-right: 5px;
    border: ${(props: CheckboxItemsContainerType) =>
    props.isselected ? `1px ${PrimaryButtonColor} solid` : undefined};
    border-radius: ${(props: CheckboxItemsContainerType) =>
    props.isselected ? "2px" : undefined};
    font-weight: ${(props: CheckboxItemsContainerType) =>
    props.isselected ? "500" : undefined};
    color: ${(props: CheckboxItemsContainerType) =>
    props.isselected ? PrimaryButtonColor : undefined};
`;

export const CheckboxItemText = Styled(ListItemText)`
    padding: 0px;
    margin-left: 5px;
    span {
      display: flex;
      align-items: center;
    }
    & .MuiTypography-body1{
      font-size: 13px;
    }
`;

export const SimpleTextButton = Styled(Button)`
  font-weight: 600;
  text-transform: none;
`;

export const CompanyLogo = Styled.img`
  height: 16px;
  width: 16px;
`;

export const DropContainerText = Styled(Typography)`
    height: 100px;
    border: 3px #efefef solid;
    text-align: center;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 20px;
    background: #efefef;
`;

export const FirstColumnContainer = Styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  padding: 5px;
`;

export const IconWrapper = Styled.span`
line-height: 0px !important;
cursor: pointer;
  &:hover {
    svg {
      color: ${PrimaryButtonColor} !important;
    } 
  }
`;

export const DataGridLogo = Styled.img`
  height: 30px;
  width: 30px;
  border-radius: 5px;
  box-shadow: 1px 1px 1px 0 ${shadowColor};
`;
