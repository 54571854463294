import { IActionPayload } from "../../component/commoncomponent/Base";
import {
  AdvanceSearchOptionsType,
  SelectedAdvanceFilterValueType,
  UPDATE_ADVANCE_OPTIONS,
  UPDATE_ADVANCE_SELECTED_OPTIONS,
} from "./type";

export const updateAdvanceSearchOptions = (
  options: AdvanceSearchOptionsType[]
): IActionPayload<AdvanceSearchOptionsType[]> => ({
  type: UPDATE_ADVANCE_OPTIONS,
  payload: options,
});

export const updateAdvanceSelectedSearchOptions = (
  options: SelectedAdvanceFilterValueType[]
): IActionPayload<SelectedAdvanceFilterValueType[]> => ({
  type: UPDATE_ADVANCE_SELECTED_OPTIONS,
  payload: options,
});
