import { IActionPayload } from "../component/commoncomponent/Base";
import { upgradePlanMessageType } from "./type";

export const UPDATE_ACCESS_TOKEN = "UPDATE_ACCESS_TOKEN";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const REMOVE_AUTH_DATA = "REMOVE_AUTH_DATA";
export const UPDATE_UPGRADE_MESSAGE = "UPDATE_UPGRADE_MESSAGE";

export const updateAccessToken = (
  accessToken: string
): IActionPayload<string> => ({
  type: UPDATE_ACCESS_TOKEN,
  payload: accessToken,
});

export const updateUserData = (userData: any): IActionPayload<any> => ({
  type: UPDATE_USER_DATA,
  payload: userData,
});

export const removeAuthData = (): IActionPayload<any> => ({
  type: UPDATE_USER_DATA,
  payload: {},
});

export const updateUpgradeMessage = (upgradeMessage: upgradePlanMessageType | null): IActionPayload<upgradePlanMessageType | null> => ({
  type: UPDATE_UPGRADE_MESSAGE,
  payload: upgradeMessage,
});

export const transformUpgradePlanMessage = (data: any): upgradePlanMessageType => ({
  heading: data.Heading,
  params: data.Paras,
});
