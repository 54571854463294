import { IconButton } from "@material-ui/core";
import React, { FC } from "react";
import { useBooleanState } from "../hooks/common/hooks";
import TextFieldWrapper from "./TextFieldWrapper";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";

type SearchTextFieldProps = {
  value: string;
  onTextChange: (text: string) => void;
  onClearIconClick: () => void;
};

const SearchTextField: FC<SearchTextFieldProps> = ({
  onClearIconClick,
  onTextChange,
  value,
}) => {
  const showSearchBox = useBooleanState(false);

  if (!showSearchBox.value) {
    return (
      <IconButton
        title="Expand Searchbox"
        aria-label="Expand Searchbox"
        size="small"
        onClick={() => showSearchBox.setTrue()}
      >
        <SearchIcon fontSize="small" />
      </IconButton>
    );
  }

  return (
    <TextFieldWrapper
      value={value}
      onChange={({ target }) => onTextChange(target.value)}
      placeholder={"Search"}
      isBasic
      InputProps={{
        endAdornment: (
          <IconButton
            title="Clear"
            aria-label="Clear"
            size="small"
            onClick={() => {
              showSearchBox.setFalse();
              onClearIconClick();
            }}
          >
            <ClearIcon fontSize="small" />
          </IconButton>
        ),
      }}
    />
  );
};

export default SearchTextField;
