import { Typography } from "@material-ui/core";
import Styled from "styled-components";
import {
  LightBlueColor,
  LightYellowColor,
  RedColor,
  TextPrimaryColor,
  YellowColor,
} from "../../constants/ColorConstants";

export const ListContainer = Styled.div`
    overflow-x: hidden;
    max-height: 200px;
    overflow-y: auto;
    width: 100%;

`;

export const ActionContainer = Styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const ActionButtonDelete = Styled.div`
  display: grid;
  place-items: center;
  height: 100%;
  background: ${RedColor};
  border-radius: 8px;
  padding: 4px;
  cursor: pointer;
`;

export const ActionButtonDomain = Styled.div`
  display: grid;
  place-items: center;
  height: 100%;
  background: ${LightBlueColor};
  border-radius: 8px;
  padding: 4px;
  cursor: pointer;
`;

export const ActionButtonTruncate = Styled.div`
  display: grid;
  place-items: center;
  height: 100%;
  background: ${YellowColor};
  border-radius: 8px;
  padding: 4px;
  cursor: pointer;
`;

export const FavouriteButton = Styled.div`
  display: grid;
  place-items: center;
  height: 100%;
  background: ${LightYellowColor};
  border-radius: 8px;
  padding: 4px;
`;

export const DatagridTitle = Styled(Typography)`
    font-weight: bold;
    color: ${TextPrimaryColor};
    font-size: 32px;
    text-align: center;
    margin: 15px;
`;

