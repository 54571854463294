import {
  createStyles,
  ListItem,
  ListItemIcon,
  makeStyles,
  styled,
  Theme,
  Tooltip,
  TooltipProps,
  Typography,
} from "@material-ui/core";
import { tooltipClasses } from "@mui/material";
import Styled from "styled-components";
import {
  NavMenuColor,
  SelectedNavigationMenu,
  TextPrimaryColor,
  white,
} from "../../constants/ColorConstants";

const drawerWidth = 220;

export const NavigationStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      display: "flex",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      height: "90%",
      position: "absolute",
      [theme.breakpoints.down(1030)]: {
        backgroundSize: "contain",
        backgroundPosition: "left",
      },
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
        height: "90vh",
      },
    },
    appBar: {
      display: "flex",
      width: "100%",
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    CompanyLogo: {
      height: 100,
    },
    CompanyName: {
      color: "#2E3FAC",
      fontSize: 30,
      fontWeight: "bold",
      marginLeft: 10,
    },
    drawerPaper: {
      backgroundColor: "transparent",
      borderRight: 0,
      marginLeft: 16,
      marginTop: 80,
      height: "90vh",
      [theme.breakpoints.down(1030)]: {
        marginLeft: 0,
      },
    },
    menuIcon: {
      "&.MuiListItemIcon-root": {
        minWidth: 40,
      },
    },
    iconButton: {
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
  })
);

export const DrawerNavigation = Styled.div`
  height: 95vh;
  overflow-y: auto;
  padding-right: 5px;
  &::-webkit-scrollbar {
      width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #d2d1d1;
    border-radius: 10px;
  }


    @media (max-width: 600px) {
        padding-left: 5px;
        padding-right: 5px;
        background: #efefef;
    }  
       
      a {
          text-decoration: none;
          font-size: 30px;
          color: black;
          font-weight: bold;    
      }
  `;

export const SideNavigationLink = Styled(ListItem)`
      padding: 0px;
  `;

type LinkTitleProps = {
  ismenuselected: number;
};

export const LinkTitle = Styled(Typography)`
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: left;
      color: ${(props: LinkTitleProps) =>
        props.ismenuselected ? "#3d81dd" : TextPrimaryColor};
  `;

type LinkIconProps = {
  iconColor?: string;
};

export const ListIcon = Styled(ListItemIcon)`
      min-width: 30px !important;
      svg {
          color: ${(props: LinkIconProps) => props.iconColor};
      }
  `;

export const CompanyLogo = Styled.img`
      height: 100%;
      object-fit: cover;
      margin-right: 10px;
  `;

export const NavigationBarStyle = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      flexGrow: 1,
      width: "100%",
      [theme.breakpoints.down("xs")]: {
        marginLeft: 0,
      },
    },
  })
);

export const navigationMenuStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen + 200,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen + 200,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(7) + 1,
      },
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    drawerCloseIcon: {
      color: "#ffff",
      minWidth: 40,
      display: "flex",
      justifyContent: "center",
      marginBottom: 30,
      cursor: "pointer",
    },
  })
);

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: white,
    maxWidth: 220,
    textTransform: "uppercase",
    fontSize: theme.typography.pxToRem(14),
    border: "1px solid #dadde9",
    borderLeft: "none",
  },
}));

type HtmlToolTipWrapperProps = {
  isselected: number;
};

export const HtmlToolTipWrapper = Styled(HtmlTooltip)<HtmlToolTipWrapperProps>`
  &.MuiTooltip-popper {
    top: -1px !important;
    left: -14px !important;
  }
  & .MuiTooltip-tooltip {
    background-color:  ${(props) =>
      props.isselected === 1
        ? SelectedNavigationMenu
        : NavMenuColor} !important;
    height: 40px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
`;
