import { Button, Grid } from "@material-ui/core";
import { useEffect } from "react";
import LoadingComponent from "../../commoncomponent/LoadingComponent";
import { useBooleanState, useDataState } from "../../hooks/common/hooks";
import TechnologyHighlightSection from "./TechnologyHighlightSection";
import { TechHighlightDetails } from "./type";
import {
  getAddFavouriteTech,
  getRemoveFavouriteTech,
  getSelectedTechnologyHighlightDetails,
} from "./util";
import TechnologyTabsSection from "./TechnologyTabsSection";
import { useLocation, useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useGetToken } from "../../../data/authSelector";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { getCategoryData } from "../util";
import { updateTechFilters } from "../../../data/technologyStore/action";
import { showError } from "../../commoncomponent/Client";
import { useAuth0 } from "@auth0/auth0-react";

const getTechSid = (location: any) => {
  const search = new URLSearchParams(location.search);
  return search.get("techsid") || "";
};

const TechnologyItemDetailPanel = () => {
  const technologyHighlightDetails = useDataState<TechHighlightDetails>();
  const selectedTechId = useDataState<string>("");
  const location = useLocation();
  const accessToken = useSelector(useGetToken);
  const isFavourite = useBooleanState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { logout } = useAuth0();

  const handleFavouriteChange = () => {
    if (!isFavourite.value) {
      getAddFavouriteTech(selectedTechId.value || "").then(() => {
        isFavourite.setTrue();
        getCategoryData().then((res) => dispatch(updateTechFilters(res)));
      });
      return;
    }
    getRemoveFavouriteTech(selectedTechId.value || "").then(() => {
      isFavourite.setFalse();
      getCategoryData().then((res) => dispatch(updateTechFilters(res)));
    });
  };

  useEffect(() => {
    selectedTechId.set(getTechSid(location));
    accessToken &&
      getSelectedTechnologyHighlightDetails(getTechSid(location))
        .then((res) => {
          res.isFavourite ? isFavourite.setTrue() : isFavourite.setFalse();
          technologyHighlightDetails.set(res);
        })
        .catch((err) => {
          dispatch(showError(err, logout));
        });
  }, [location.search, accessToken]);

  const getView = () => {
    return (
      <>
        <TechnologyHighlightSection
          techDetails={technologyHighlightDetails.value}
          isFavourite={isFavourite.value}
          handleFavouriteChange={handleFavouriteChange}
        />
        <Grid item xs={12}>
          <TechnologyTabsSection techId={selectedTechId.value || ""} />
        </Grid>
      </>
    );
  };
  return (
    <Grid container style={{ padding: "10px 30px" }}>
      <LoadingComponent>
        <>
          <Button
            startIcon={<ArrowBackIcon />}
            variant={"outlined"}
            size={"small"}
            style={{ marginBottom: 10 }}
            color={"primary"}
            onClick={() => history.goBack()}
          >
            Back
          </Button>
          {selectedTechId.value && getView()}
        </>
      </LoadingComponent>
    </Grid>
  );
};

export default TechnologyItemDetailPanel;
