export interface TechCategoryType {
  category: CategoryType[];
  alreadySelected: boolean;
  selectedSid: string;
  isLoading: boolean;
  scrollPosition: number;
}

export interface CategoryType {
  name: string;
  sid: string;
  count: string;
  isFav: boolean;
}

export const UPDATE_TECH_FILTERS = "UPDATE_TECH_FILTERS";
export const UPDATE_TECH_SELECTED_SIDS = "UPDATE_TECH_SELECTED_SIDS";
export const UPDATE_ALREADY_SELECTED_TECH = "UPDATE_ALREADY_SELECTED_TECH";
export const CLEAR_TECH_URL = "CLEAR_TECH_URL";
export const UPDATING_TECH_LOADING = "UPDATE_LOADING";
export const UPDATE_TECH_SCROLL_POSITION = "UPDATE_TECH_SCROLL_POSITION";
