import { LogoutOptions } from "@auth0/auth0-react";
import { Dispatch } from "redux";
import { IActionPayload } from "../../component/commoncomponent/Base";
import { Client, showError } from "../../component/commoncomponent/Client";
import { getOrgAdvanceConfigSearchApiUrl } from "../../constants/ApiConstants";
import { trackEvent } from "../../service/mixpanel";
import { updateUpgradeMessage, transformUpgradePlanMessage } from "../action";
import { UPDATE_ADVANCE_OPTIONS } from "../advancesearch/type";
import { FilterType } from "../ecommerceStore/type";
import { transformAdvanceSearch } from "../ecommerceStore/util";
import { LabelType } from "../LabelsList/type";

export const UPDATE_ORGANISATION_SCROLL_POSITION =
  "UPDATE_ORGANISATION_SCROLL_POSITION";
export const UPDATE_ORGANISATION_SELECTED_SIDS =
  "UPDATE_ORGANISATION_SELECTED_SIDS";
export const UPDATE_ORG_LABEL_LIST = "UPDATE_ORG_LABEL_LIST";
export const UPDATE_ORGANISATION_FILTERS = "UPDATE_ORGANISATION_FILTERS";
export const CLEAR_FILTER_INPUT = "CLEAR_FILTER_INPUT";
export const UPDATE_ORG_SEARCHED_TEXT = "UPDATE_ORG_SEARCHED_TEXT";
export const UPDATE_ORG_FOCUSED_SEARCHED_TEXT =
  "UPDATE_ORG_FOCUSED_SEARCHED_TEXT";
export const UPDATE_ORG_SHOW_GRAPH_VIEW = "UPDATE_ORG_GRAPH_VIEW";

export const updateOrganisationFilters = (
  filters: FilterType[]
): IActionPayload<FilterType[]> => ({
  type: UPDATE_ORGANISATION_FILTERS,
  payload: filters,
});

export const updateOrgSids = (sids: string[]): IActionPayload<string[]> => ({
  type: UPDATE_ORGANISATION_SELECTED_SIDS,
  payload: sids,
});

export const updateOrgShowGraphView = (
  showGraphView: boolean
): IActionPayload<boolean> => ({
  type: UPDATE_ORG_SHOW_GRAPH_VIEW,
  payload: showGraphView,
});

export const updateOrgScrollPosition = (
  position: number
): IActionPayload<number> => ({
  type: UPDATE_ORGANISATION_SCROLL_POSITION,
  payload: position,
});

export const updateOrgLabelList = (
  labels: LabelType[]
): IActionPayload<LabelType[]> => ({
  type: UPDATE_ORG_LABEL_LIST,
  payload: labels,
});

export const updateOrgSearchedText = (
  text: string
): IActionPayload<string> => ({
  type: UPDATE_ORG_SEARCHED_TEXT,
  payload: text,
});

export const updateOrgFocusedSearchedText = (
  isFocued: boolean
): IActionPayload<boolean> => ({
  type: UPDATE_ORG_FOCUSED_SEARCHED_TEXT,
  payload: isFocued,
});

export const getOrgAdvanceSearchConfigAction =
  (logout: (options?: LogoutOptions | undefined) => void) =>
  (dispatch: Dispatch) => {
    trackEvent("GET_ORG_ADVANCE_OPTIONS_LISTS");
    Client.getInstance()
      .getData(getOrgAdvanceConfigSearchApiUrl(), true)
      .then((res) => {
        dispatch({
          type: UPDATE_ADVANCE_OPTIONS,
          payload: res.data.filter_cfg
            ? transformAdvanceSearch(res.data.filter_cfg)
            : [],
        });
      })
      .catch((err) => {
        showError(err, logout);
        err.statusCode === 402 &&
          dispatch(
            updateUpgradeMessage(transformUpgradePlanMessage(err.response.data))
          );
      });
  };
