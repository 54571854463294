import { Box, LinearProgress, Typography } from "@material-ui/core";
import React, { FC } from "react";

type UploadProgressBarProps = {
  progress: number;
};

const UploadProgressBar: FC<UploadProgressBarProps> = ({ progress }) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        marginTop: 10,
      }}
    >
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" value={progress} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          color="primary"
        >{`${progress}%`}</Typography>
      </Box>
    </Box>
  );
};

export default UploadProgressBar;
