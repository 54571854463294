import React, { ErrorInfo } from "react";
import { logError } from "../../config/errorLib";
import { Props } from "./Base";

interface ErrorBoundaryState {
  hasError: boolean;
}

export default class ErrorBoundary extends React.Component<
  Props,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryState) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logError(error, errorInfo);
  }

  render() {
    return this.state.hasError ? (
      <div style={{ padding: 100 }}>
        <h3>Sorry there was a problem loading this page</h3>
      </div>
    ) : (
      this.props.children
    );
  }
}
