import { compact } from "lodash";
import {
  getAutoCompleteOrgSearchApiUrl,
  getOrgAdvanceSearchApiUrl,
  getOrganisationTechApiUrl,
} from "../../constants/ApiConstants";
import { organisationNewRoute } from "../../constants/routesLinks";
import {
  noOfRecordPerPage,
  techConstant,
  textConstant,
} from "../../constants/SiteConstants";
import { CFGStateType, FilterType } from "../../data/ecommerceStore/type";
import { trackEvent } from "../../service/mixpanel";
import { Client } from "../commoncomponent/Client";
import {
  reverseAdvanceSearchTransform,
  transformOtherField,
  transformTech,
} from "../ecommerce/EcommerceAdvance/util";
import { getSearchUrl } from "../ecommerce/util";
import { OrganisationDetailType, OrganisationItemInfo } from "./type";

export const transformUrl = (location: string, offset: number) => {
  const searchParams = new URLSearchParams(location);
  const sid = searchParams.get("sid")?.split("%2C")[0].split(",");
  const text = searchParams.get(textConstant);
  return {
    text: text || "",
    sids: sid || [],
    order_by: "",
    offset: offset,
    count: noOfRecordPerPage,
  };
};

const transformRows = (data: any): OrganisationItemInfo[] =>
  data.map((item: any) => ({
    id: item.sid,
    contact: item.attrs.Contact,
    country: item.attrs.Country,
    domain: item.domain,
    ...item.cols,
    firstCol: item.first_col,
    ...item.first_col,
  }));

const transformCfgData = (data: any): CFGStateType => ({
  defaultSize: data.default_size,
  isCollapsed: data.is_collapsed,
});

const transformFilterData = (data: any): FilterType => ({
  cfg: transformCfgData(data.cfg),
  facets: data.facets,
  name: data.name,
});

export const transformData = (res: any): OrganisationDetailType => ({
  filter: res.data
    ? res.data.facet_groups.map((item: any) => transformFilterData(item))
    : [],
  rows: res.data.rows ? transformRows(res.data.rows) : [],
  total: res.data.total,
  activeCols: res.data.col_display_cfg.active_cols,
  colMetaData: res.data.col_display_cfg.col_metadata,
});

export const getOrganisationResults = (location: any, offset: number) => {
  trackEvent("ORGANISATION_RESULT", { ...transformUrl(location, offset) });
  return Client.getInstance()
    .createData(getOrganisationTechApiUrl(), transformUrl(location, offset))
    .then((res) => {
      return Promise.resolve(transformData(res));
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const transformAutoComplete = (data: any) =>
  data.data.map((item: any) => ({
    description: item.description,
    id: item.sid,
    name: item.name,
    resource: item.resource,
  }));

export const getOrganisationAutoCompleteResults = (text: string) => {
  trackEvent("ORGANISATION_AUTOCOMPLETE_RESULT", { text: text });
  return Client.getInstance()
    .createData(getAutoCompleteOrgSearchApiUrl(text), {})
    .then((res) => {
      return Promise.resolve(
        res.data.data ? transformAutoComplete(res.data) : []
      );
    })
    .catch((err) => Promise.reject(err));
};

export const getOrganisationAdvanceSearchResult = (
  advanceFilterLists: any,
  offset: number
) =>
  Client.getInstance()
    .createData(
      getOrgAdvanceSearchApiUrl(),
      reverseAdvanceSearchTransform(advanceFilterLists, offset)
    )
    .then((res) =>
      Promise.resolve({
        rows: res.data.results.rows ? transformRows(res.data.results.rows) : [],
        count: res.data.results.total,
      })
    )
    .catch((error) => Promise.reject(error));

export const getOrgSearchUrl = (
  selectedSids: string[],
  text: string,
  location: string
) => {
  if (selectedSids.length === 0 && text === "") {
    return organisationNewRoute;
  }
  if (selectedSids.length === 0) {
    return `?text=${text}`;
  }
  if (text === "") {
    return getSearchUrl(selectedSids, location);
  }
  return `${getSearchUrl(selectedSids, location)}&text=${text}`;
};

export const getOrgPrepopulatedAdvanceSearch = (
  sids: string,
  searchedText: string
) => {
  const sidsArray = compact(sids.split(","));
  return Client.getInstance()
    .createData(getOrgAdvanceSearchApiUrl(), {
      bs_query: {
        sids: sidsArray,
        order_by: "",
        offset: 0,
        count: 100,
        text: searchedText,
      },
    })
    .then((res) => {
      const withoutTech = res.data.as_query.filters.filter(
        (item: any) => item.field !== techConstant
      );
      const withTech = res.data.as_query.filters.filter(
        (item: any) => item.field === techConstant
      );
      return Promise.resolve([
        ...transformTech(withTech),
        ...transformOtherField(withoutTech),
      ]);
    })
    .catch((err) => Promise.reject(err));
};
