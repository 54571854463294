import {
  downloadEcommFileApiUrl,
  downloadOrgFileApiUrl,
  downloadSampleEcommFile,
  downloadSampleOrgFile,
  exportEcommFileOptionsApiUrl,
  exportOrgFileOptionsApiUrl,
} from "../../constants/ApiConstants";
import { Client } from "../commoncomponent/Client";
import { reverseAdvanceSearchTransform } from "../ecommerce/EcommerceAdvance/util";
import {
  ExportOptionsType,
  FormatOptionType,
  OptionAttributeType,
} from "./type";

const transformData = (data: any): ExportOptionsType | null => {
  if (!data) {
    return null;
  }
  return {
    exportDisabled: data.export_disabled,
    exportDisabledDisplay: data.export_disabled_display_cfg,
    formatOpts: data.format_opts.map((item: any) => ({
      name: item.name,
      attributes: item.attributes,
    })),
  };
};

const reverseTransformQueryData = (location: string, offset: number) => {
  const searchParams = new URLSearchParams(location);
  const sid = searchParams.get("sid")?.split("%2C")[0].split(",");
  return {
    sids: sid || [],
    order_by: "",
    offset: offset,
    count: 100,
    text: searchParams.get("text") || "",
  };
};

export const getExportFileOptions = (exportType: string) => {
  return Client.getInstance()
    .createData(
      exportType === "org"
        ? exportOrgFileOptionsApiUrl()
        : exportEcommFileOptionsApiUrl(),
      {}
    )
    .then((res) => Promise.resolve(transformData(res.data)))
    .catch((err) => Promise.reject(err));
};

const transformSelectedFormatOption = (
  filterOption: OptionAttributeType[]
): string[] => {
  const filterOptionName: string[] = [];
  filterOption.forEach((item) => {
    if (item.checked) {
      filterOptionName.push(item.name);
    }
  });
  return filterOptionName;
};

export const getDownloadFile = (
  exportType: string,
  selectedOption: FormatOptionType,
  location: string,
  pageCount: number,
  isBasic: boolean,
  advanceFilterLists?: any,
  offset?: number,
  isSample?: boolean
) => {
  const isBasicOrAdvanceTransform = !isBasic
    ? {
        query: {
          ...reverseTransformQueryData(location, pageCount),
        },
      }
    : reverseAdvanceSearchTransform(advanceFilterLists, offset || 0);

  const data = {
    count: 100,
    format_name: selectedOption.name,
    format_attrs: transformSelectedFormatOption(
      selectedOption.attributes || []
    ),
    ...isBasicOrAdvanceTransform,
  };
  return Client.getInstance()
    .createData(
      exportType === "org"
        ? isSample
          ? downloadSampleOrgFile()
          : downloadOrgFileApiUrl()
        : isSample
        ? downloadSampleEcommFile()
        : downloadEcommFileApiUrl(),
      data
    )
    .then((res) => Promise.resolve(res))
    .catch((err) => Promise.reject(err));
};
