import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { useHistory } from 'react-router';
import { subscriptionRoute } from '../../constants/routesLinks';

type UpgradePlanModalProps = {
    handleClose: () => void;
    title: string;
    description: string[];
}
const UpgradePlanModal: FC<UpgradePlanModalProps> = ({
    handleClose,
    title,
    description
}) => {

    const history = useHistory();

    return (
        <Dialog open onClose={handleClose} fullWidth>
            <DialogTitle>
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {
                        description.map((text, index) => <Typography key={index}>{text}</Typography>)
                    }
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ display: "flex", justifyContent: "space-between", padding: 20 }}>
                <Button onClick={() => {
                    history.push(subscriptionRoute);
                    handleClose();
                }} color={"primary"} variant={"contained"} autoFocus>
                    Upgrade
                </Button>
                <Button color={"primary"} variant={"text"} onClick={handleClose} autoFocus>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UpgradePlanModal;
