import { BAR_OPTION, PIE_OPTION, TOP_10_OPTION, TOP_20_OPTION } from "./SiteConstants";

export const graphOptions = [
    {
        label: "Bar Chart",
        value: BAR_OPTION,
    },
    {
        label: "Pie Graph",
        value: PIE_OPTION,
    },
];

export const dataSizeOption = [
    {
        label: "Top 10",
        value: TOP_10_OPTION,
    },
    {
        label: "Top 20",
        value: TOP_20_OPTION,
    },
];
