import { cloneDeep } from "lodash";
import { IActionPayload } from "../../component/commoncomponent/Base";
import { SubscriptionPlansType, UPDATE_SUBSCRIPTION_PLANS } from "./type";

const initialState: SubscriptionPlansType = {
  currentPlan: {
    name: "",
    plan: "",
  },
  billingPortal: {
    displayPortal: false,
    text: ''
  },
  planData: [],
  heading: "",
  subHeading: "",
};

export const SubscriptionPlanReducer = (
  state = initialState,
  action: IActionPayload<any>
): any => {
  const stateClone = cloneDeep(state);
  const { type, payload } = action;
  switch (type) {
    case UPDATE_SUBSCRIPTION_PLANS:
      return payload;
    default:
      return stateClone;
  }
};
