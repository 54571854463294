import {
  createCheckoutSession,
  getSubscriptionPlanApiUrl,
  updateSubscriptionPlanApiUrl,
} from "../../constants/ApiConstants";
import {
  PlansListType,
  SubscriptionPlansType,
} from "../../data/subscription/type";
import { Client, showError } from "../commoncomponent/Client";

const transformPlanData = (response: any): PlansListType[] =>
  response.map((item: any) => ({
    headline: item.headline,
    name: item.name,
    negativeFeatures: item.negative_features,
    positiveFeatures: item.positive_features,
    plan: item.plan,
    prices: item.prices,
  }));

const transformSubscriptionPlan = (response: any): SubscriptionPlansType => {
  const data = response.data;
  return {
    currentPlan: {
      name: data.current_plan.name,
      plan: data.current_plan.plan,
    },
    planData: transformPlanData(data.plan_data),
    billingPortal: {
      displayPortal: data.billing_portal.display,
      text: data.billing_portal.text
    },
    heading: data.heading,
    subHeading: data.sub_heading,
  };
};

export const getSubscriptionData = () =>
  Client.getInstance()
    .getData(getSubscriptionPlanApiUrl())
    .then((res) => Promise.resolve(transformSubscriptionPlan(res)))
    .catch((err) => Promise.reject(showError(err)));

export const buySubscriptionPlan = (plan: string, interval: string) =>
  Client.getInstance()
    .createData(createCheckoutSession(), {
      plan: plan,
      interval: interval,
    })
    .then((res) => Promise.resolve(res.data.redirect_url))
    .catch((err) => Promise.reject(err));

export const updateDownloadSubscriptionPlan = () =>
  Client.getInstance()
    .createData(updateSubscriptionPlanApiUrl(), {})
    .then((res) => Promise.resolve(res.data.redirect_url))
    .catch((err) => Promise.reject(err));
