import { Checkbox } from "@material-ui/core";
import React, { FC } from "react";
import {
  CheckboxItemsContainer,
  CheckboxItemText,
} from "../../commoncomponent/style";
import { CheckboxOptionType } from "./type";
import CheckIcon from "../../../images/checkedBox.svg";
import UnCheckIcon from "../../../images/uncheckedBox.svg";

interface CheckBoxContainerProps {
  filterDetails: CheckboxOptionType;
  handleFilterChange: (sidNumber: string) => void;
  isChecked: string[];
}

const CheckBoxContainer: FC<CheckBoxContainerProps> = ({
  filterDetails,
  handleFilterChange,
  isChecked,
}) => {
  return (
    <CheckboxItemsContainer
      onClick={() => handleFilterChange(filterDetails.sid)}
      key={filterDetails.sid}
    >
      <Checkbox
        style={{ padding: 0 }}
        checked={isChecked.includes(filterDetails.sid)}
        size={"small"}
        icon={
          <img src={UnCheckIcon} alt="uncheckedBox" width={16} height={16} />
        }
        checkedIcon={
          <img src={CheckIcon} alt="uncheckedBox" width={16} height={16} />
        }
      />
      <CheckboxItemText>{filterDetails.name}</CheckboxItemText>
      <CheckboxItemText style={{ display: "flex", justifyContent: "flex-end" }}>
        {filterDetails.count && filterDetails.count !== 0
          ? filterDetails.count.toLocaleString()
          : ""}
      </CheckboxItemText>
    </CheckboxItemsContainer>
  );
};

export default CheckBoxContainer;
