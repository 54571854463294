import React, { useEffect, useMemo } from "react";
import { LabelType } from "../../data/LabelsList/type";
import LabelListItem from "./LabelListItem";

export const useModalOutsideClick = (
  handleClickOutside: (event: any) => void
) =>
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

export const useAllUsersList = (
  filteredLabels: LabelType[],
  selectedLabel: (selectedLabel: LabelType) => void
) =>
  useMemo(
    () =>
      filteredLabels.map((item) => (
        <LabelListItem
          key={item.sid}
          name={item.name}
          sid={item.sid}
          selectedLabel={() => selectedLabel(item)}
        />
      )),
    [filteredLabels]
  );
