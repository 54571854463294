export interface SelectedAdvanceFilterValueType {
  id: number;
  fieldName: string;
  fieldType: string;
  operation: string;
  value: string[];
  selectedValuePair: OptionsType[];
  techValue?: SelectedTechVals[];
  text: string;
}

export interface OptionsType {
  name: string;
  value: string;
}

export interface SelectedTechVals {
  id: number;
  sid: string;
  name: string;
  installOptions: string;
  listVals: string[];
  selectedValuePair: OptionsType[];
  text: string;
}

export interface AdvanceSearchOptionsType {
  filterType: string;
  listData: {
    chooseMultiple: boolean;
    name: string;
    options: OptionsType[];
    displayAs: string;
  };
  name: string;
  selectionConfig: {
    chooseMultiple: boolean;
    options: OptionsType[];
    displayAs: string;
    name: string;
  };
  techConfig?: {
    installListData: {
      chooseMultiple: boolean;
      name: string;
      options: OptionsType[];
      displayAs: string;
    };
    listData: {
      chooseMultiple: boolean;
      name: string;
      options: OptionsType[];
      displayAs: string;
    };
  };
}

export interface AdvanceSearchType {
  advanceSearchOptions: {
    filterTypes: OptionsType[];
    filterInfo: AdvanceSearchOptionsType[];
  };
  selectedFilterValues: SelectedAdvanceFilterValueType[];
}

export const UPDATE_ADVANCE_SELECTED_OPTIONS =
  "UPDATE_ADVANCE_SELECTED_OPTIONS";
export const UPDATE_ADVANCE_OPTIONS = "UPDATE_ADVANCE_OPTIONS";
