import {
  getTechApiUrl,
  getTechCategoryApiUrl,
} from "../../constants/ApiConstants";
import { CategoryType } from "../../data/technologyStore/type";
import { trackEvent } from "../../service/mixpanel";
import { Client } from "../commoncomponent/Client";

const transformData = (data: any): CategoryType[] =>
  data.map((item: any) => ({
    name: item.name,
    sid: item.sid,
    isFav: item.is_fav,
    count: item.count,
  }));

const techTransformData = (data: any) =>
  data.map((item: any) => ({
    id: item.sid,
    isFav: item.is_fav,
    monthlyGain: item.monthly_gain.toLocaleString() || 0,
    monthlyLoss: item.monthly_loss.toLocaleString() || 0,
    name: item.name,
    total: item.total.toLocaleString() || 0,
    website: item.website,
  }));

export const getCategoryData = () => {
  trackEvent("CATEGORY_LIST");
  return Client.getInstance()
    .createData(getTechCategoryApiUrl(), {})
    .then((res) => Promise.resolve(transformData(res.data.categories)))
    .catch((error) => Promise.reject(error));
};

export const getTechnology = (tech: string) => {
  trackEvent("TECHNOLOGY_LIST", { tech: tech });
  return Client.getInstance()
    .createData(getTechApiUrl(tech), {})
    .then((res) =>
      Promise.resolve({
        techs: res.data.category_techs
          ? techTransformData(res.data.category_techs)
          : [],
        total: res.data.total,
      })
    )
    .catch((error) => Promise.reject(error));
};
