import React from "react";
import { Route } from "react-router";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Typography } from "@material-ui/core";

const PrivateRoute = ({ component, ...args }: any) => (
  <Route
    component={withAuthenticationRequired(component, {
      onRedirecting: () => (
        <div
          style={{
            marginTop: "15%",
          }}
        >
          <Typography
            variant="h6"
            style={{ marginBottom: "2%", textAlign: "center" }}
          >
            Redirecting to Site...
          </Typography>
        </div>
      ),
    })}
    {...args}
  />
);

export default PrivateRoute;
