import {
  getEcommerceAdvanceSearchResultApiUrl,
  getEcommerceSiteApiUrl,
  getExportEcommerceApiUrl,
} from "../../constants/ApiConstants";
import { eCommerceRoute } from "../../constants/routesLinks";
import { noOfRecordPerPage, textConstant } from "../../constants/SiteConstants";
import { CFGStateType, FilterType } from "../../data/ecommerceStore/type";
import { trackEvent } from "../../service/mixpanel";
import { Client } from "../commoncomponent/Client";
import { reverseAdvanceSearchTransform } from "./EcommerceAdvance/util";
import { EcommerceItemInfo, EcommerceSiteDetailType } from "./type";

export const ecommerceValidateUrl = (location: string): boolean => {
  const searchParams = new URLSearchParams(location);
  const validFields = ["sid", textConstant];
  const keys: string[] = [];
  let totalCorrectParamters = 0;
  for (const key of searchParams.keys()) {
    keys.push(key);
  }

  validFields.map((field) =>
    keys.map((key) => {
      if (field === key) {
        totalCorrectParamters += 1;
      }
    })
  );
  if (totalCorrectParamters === keys.length - 1) {
    return true;
  }
  return false;
};

const transformCfgData = (data: any): CFGStateType => {
  return {
    defaultSize: data.default_size,
    isCollapsed: data.is_collapsed,
  };
};

const transformFilterData = (data: any): FilterType => {
  return {
    cfg: transformCfgData(data.cfg),
    facets: data.facets,
    name: data.name,
  };
};

export const transformData = ({ data }: any): EcommerceSiteDetailType => {
  return {
    filter: data.facet_groups.map((item: any) => transformFilterData(item)),
    rows: data.rows ? transformRows(data.rows) : [],
    total: data.total,
    activeCols: data.col_display_cfg.active_cols,
    colMetaData: data.col_display_cfg.col_metadata,
  };
};

const transformRows = (data: any): EcommerceItemInfo[] =>
  data.map((item: any) => ({
    id: Math.random(),
    contact: item.attrs.Contact,
    country: item.attrs.Country,
    platform: item.attrs.Platform,
    domain: item.domain,
    ...item.cols,
    firstCol: item.first_col,
    ...item.first_col,
  })) || [];

export const transformUrl = (location: string, offset: number) => {
  const searchParams = new URLSearchParams(location);
  const sid = searchParams.get("sid")?.split("%2C")[0].split(",");
  const text = searchParams.get(textConstant);

  return {
    text: text || "",
    sids: sid || [],
    order_by: "",
    offset: offset,
    count: noOfRecordPerPage,
  };
};

export const getEcommerceResults = (location: any, offset: number) => {
  trackEvent("ECOMMERCE_RESULT", transformUrl(location, offset));
  return Client.getInstance()
    .createData(getEcommerceSiteApiUrl(), transformUrl(location, offset))
    .then((res) => {
      return Promise.resolve(transformData(res));
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const downloadEcommerceResults = (location: string, offset: number) => {
  trackEvent("DOWNLOAD_ECOMMERCE_RESULT", {
    query: transformUrl(location, offset),
  });

  return Client.getInstance()
    .createData(getExportEcommerceApiUrl(), {
      query: transformUrl(location, offset),
    })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.csv");
      document.body.appendChild(link);
      link.click();
      return Promise.resolve();
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const getEcommerceInitialSid = (location: string) => {
  const searchParams = new URLSearchParams(location);
  if (ecommerceValidateUrl(location) && searchParams.toString() === "") {
    return [];
  }

  return searchParams.get("sid")?.split("%2C")[0].split(",") || [];
};

export const getFilteredList = (
  list: EcommerceItemInfo[],
  searchText: string
) =>
  list.filter(
    (item: EcommerceItemInfo) =>
      !searchText || includeInFilteredItems(searchText.toLowerCase(), item)
  );

const includeInFilteredItems = (
  searchText: string,
  item: EcommerceItemInfo
): boolean => {
  if (item.contact && item.contact.toLowerCase().includes(searchText)) {
    return true;
  }
  if (item.country && item.country.toLowerCase().includes(searchText)) {
    return true;
  }
  if (item.domain && item.domain.toLowerCase().includes(searchText)) {
    return true;
  }
  if (item.platform && item.platform.toString().includes(searchText)) {
    return true;
  }
  return false;
};

export const getEcommSearchUrl = (
  selectedSids: string[],
  text: string,
  location: string
) => {
  if (selectedSids.length === 0 && text === "") {
    return eCommerceRoute;
  }
  if (selectedSids.length === 0) {
    return `?text=${text}`;
  }
  if (text === "") {
    return getSearchUrl(selectedSids, location);
  }
  return `${getSearchUrl(selectedSids, location)}&text=${text}`;
};

export const getSearchUrl = (
  selectedSids: string[],
  location: string
): string => {
  const searchParams = new URLSearchParams(location);
  if (searchParams.get(textConstant)) {
    searchParams.delete(textConstant);
  }
  if (searchParams.get("sid")) {
    searchParams.delete("sid");
    if (selectedSids.join(",") !== "") {
      searchParams.set("sid", selectedSids.join(","));
    }
  } else {
    searchParams.append("sid", selectedSids.join(","));
  }
  return `?${searchParams}`;
};

export const getAdvanceSearchResult = (
  advanceFilterLists: any,
  offset: number
) =>
  Client.getInstance()
    .createData(
      getEcommerceAdvanceSearchResultApiUrl(),
      reverseAdvanceSearchTransform(advanceFilterLists, offset)
    )
    .then((res) =>
      Promise.resolve({
        rows: res.data.results.rows ? transformRows(res.data.results.rows) : [],
        count: res.data.results.total,
      })
    )
    .catch((error) => Promise.reject(error));
