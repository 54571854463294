import { cloneDeep } from "lodash";
import { IActionPayload } from "../../component/commoncomponent/Base";
import {
  UPDATE_ALREADY_SELECTED_TECH,
  UPDATE_TECH_FILTERS,
  UPDATE_TECH_SCROLL_POSITION,
  UPDATE_TECH_SELECTED_SIDS,
  UPDATING_TECH_LOADING,
} from "./type";

export const initialTechCategories = {
  category: [],
  selectedSid: "",
  alreadySelected: false,
  isLoading: false,
  scrollPosition: 0,
};

export const TechReducer = (
  state = initialTechCategories,
  action: IActionPayload<any>
) => {
  const stateClone = cloneDeep(state);

  switch (action.type) {
    case UPDATING_TECH_LOADING:
      stateClone.isLoading = action.payload;
      return stateClone;

    case UPDATE_TECH_FILTERS:
      stateClone.category = action.payload;
      return stateClone;

    case UPDATE_TECH_SELECTED_SIDS:
      stateClone.selectedSid = action.payload;
      return stateClone;

    case UPDATE_ALREADY_SELECTED_TECH:
      stateClone.alreadySelected = action.payload;
      return stateClone;

    case UPDATE_TECH_SCROLL_POSITION:
      stateClone.scrollPosition = action.payload;
      return stateClone;
    default:
      return state;
  }
};
