import { Grid } from "@mui/material";
import React, { FC } from "react";
import { getDomainImage, getImageWidth } from "../../utils/utility";
import { getSocialIcons } from "../../utils/socialIcons";
import { DataGridLogo, FirstColumnContainer, IconWrapper } from "./style";
import { Tooltip, Typography } from "@material-ui/core";
import DefaultIcon from "../../assets/icons/defaulticon.svg";
import { lightGrey } from "../../constants/ColorConstants";

type DataGridFirstColumnProps = {
  websiteUrl: string;
  name: string;
  socialLinks: {
    name: string;
    url: string;
  }[];
};
const DataGridFirstColumn: FC<DataGridFirstColumnProps> = ({
  websiteUrl,
  name,
  socialLinks,
}) => (
  <FirstColumnContainer>
    <Grid item display={"flex"}>
      <DataGridLogo
        onLoad={(event: any) =>
          getImageWidth(getDomainImage(websiteUrl)).then((res) => {
            if (res === 16) {
              event.target.style.padding = "7px";
              event.target.style.background = lightGrey;
              event.target.src = DefaultIcon;
            }
          })
        }
        src={getDomainImage(websiteUrl)}
      />
    </Grid>
    <Grid
      item
      display={"flex"}
      flexDirection={"column"}
      alignItems={"flex-start"}
    >
      <Typography style={{ cursor: "pointer", fontSize: 14 }} color={"primary"}>
        {name}
      </Typography>
      <div style={{ display: "flex", gap: 6, marginTop: 13 }}>
        {socialLinks.map(
          ({ name, url }) =>
            url && (
              <Tooltip title={url}>
                <IconWrapper
                  key={url}
                  onClick={(event) => {
                    window.open(url, "_blank");
                    event.stopPropagation();
                  }}
                >
                  {getSocialIcons(name)}
                </IconWrapper>
              </Tooltip>
            )
        )}
      </div>
    </Grid>
  </FirstColumnContainer>
);

export default DataGridFirstColumn;
