import { useAuth0 } from "@auth0/auth0-react";
import { Button, Tooltip } from "@material-ui/core";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { successToastConfiguration } from "../../constants/ColorConstants";
import { useGetAdvanceSelectedOptions } from "../../data/advancesearch/selector";
import { LabelType } from "../../data/LabelsList/type";
import { showError } from "../commoncomponent/Client";
import { useBooleanState, useDataState } from "../hooks/common/hooks";
import AddNewLabel from "./AddNewLabel";
import LabelPopper from "./LabelPopper";
import { updateAdvanceLists, updateLabel } from "./util";

interface LabelListProps {
  selectedSids: string[];
  updateFilters: () => void;
  type: string;
  labelSaveType?: "advance" | "basic";
}
const LabelList: FC<LabelListProps> = ({
  selectedSids,
  updateFilters,
  type,
  labelSaveType = "basic",
}) => {
  const showLabelPopper = useBooleanState(false);
  const showAddNewLabelModal = useBooleanState(false);
  const selectedLabel = useDataState<LabelType>();
  const dispatch = useDispatch();
  const advanceFilterLists = useSelector(useGetAdvanceSelectedOptions);

  const { logout } = useAuth0();
  const searchParams = new URLSearchParams(window.location.search);

  const updateUserList = (label: LabelType) => {
    if (labelSaveType === "advance") {
      updateAdvanceLists(label.sid, advanceFilterLists, type)
        .then(() => {
          showLabelPopper.setFalse();
          toast.success("List updated", successToastConfiguration);
        })
        .catch((err) => {
          dispatch(showError(err, logout));
        });
      return;
    }
    updateLabel(label.sid, selectedSids, type, searchParams.get("text") || "")
      .then(() => {
        showLabelPopper.setFalse();
        toast.success("List updated", successToastConfiguration);
      })
      .catch((err) => {
        dispatch(showError(err, logout));
      });
    return;
  };

  return (
    <div>
      <Tooltip title="Save Search">
        <Button
          onClick={() => showLabelPopper.setTrue()}
          size={"small"}
          color={"primary"}
        >
          Save
        </Button>
      </Tooltip>
      {showLabelPopper.value && (
        <LabelPopper
          type={type}
          handleClose={() => showLabelPopper.setFalse()}
          openModalForAdd={() => showAddNewLabelModal.setTrue()}
          openModalForEdit={(label: LabelType) => {
            updateUserList(label);
          }}
        />
      )}
      {showAddNewLabelModal.value && (
        <AddNewLabel
          type={type}
          closeModal={() => {
            showAddNewLabelModal.setFalse();
            selectedLabel.set(undefined);
          }}
          selectedSids={selectedSids}
          saveComplete={() => updateFilters()}
          selectedLabel={selectedLabel.value}
          labelSaveType={labelSaveType}
        />
      )}
    </div>
  );
};

export default LabelList;
