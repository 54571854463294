import { Paper, Tabs, Typography } from "@material-ui/core";
import Styled from "styled-components";
import { TextSecondaryColor } from "../../../constants/ColorConstants";

interface TechInfoText {
  textColor?: string;
}
export const TechInfoText = Styled(Typography)`
    font-size: 25px;
    color: ${(props: TechInfoText) => props.textColor};
    font-weight: 700;
`;

export const TechInfoContainer = Styled.div`
    width: 100%;
    background-color: #f5f5f5;
    padding: 10px;
    margin-top: 24px;
`;

export const VerticalDivider = Styled.hr`
  transform: rotateZ(90deg);
  border-top: 1px ${TextSecondaryColor} solid;
`;

export const TechTotalInfoContainer = Styled(Paper)`
  background: inherit;
`;

export const TabsContainer = Styled(Tabs)`
  & .MuiTab-labelIcon{
    min-height: 48px;
  }

  & .MuiTab-wrapper {
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: row;
    align-items: normal;
    gap: 5px;
  }
`;
