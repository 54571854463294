export const UPDATE_LABEL_LIST = "UPDATE_LABEL_LIST";
export const DELETE_USER_LIST = "DELETE_USER_LIST";
export const TRUNCATE_USER_LIST = "TRUNCATE_USER_LIST";

export interface LabelType {
  isFav: boolean;
  name: string;
  sid: string;
  id: string;
}
