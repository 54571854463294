import { Button, Dialog, DialogActions, Typography } from "@material-ui/core";
import { DialogContent, DialogTitle } from "@mui/material";
import React, { FC } from "react";
import { toast } from "react-toastify";
import { getFileExtension } from "../../utils/utility";
import FileDropZone from "../commoncomponent/FileDropZone";
import UploadProgressBar from "../commoncomponent/UploadProgressBar";

type UploadFileViewProps = {
  handleClose: () => void;
  title: string;
  handleSubmit: (file: File) => void;
  progress: number;
};
const UploadFileView: FC<UploadFileViewProps> = ({
  handleClose,
  title,
  handleSubmit,
  progress,
}) => {
  const handleUpload = (file: File) => {
    if (getFileExtension(file.name) !== "csv") {
      toast.error("Only CSV files are supported");
      return;
    }
    handleSubmit(file);
  };

  return (
    <Dialog open onClose={handleClose}>
      <DialogTitle>Upload to {title}</DialogTitle>
      <DialogContent>
        <Typography>
          Upload a CSV file to add new domains to a list. The CSV file should
          have a single column with one domain name per line.
        </Typography>
        {progress > 0 && <UploadProgressBar progress={progress} />}
        <FileDropZone setSelectedFile={handleUpload} />
      </DialogContent>
      <DialogActions style={{ justifyContent: "flex-start" }}>
        <Button
          style={{ marginLeft: 10 }}
          color={"primary"}
          variant={"text"}
          onClick={handleClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadFileView;
