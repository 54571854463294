import { List, Paper } from "@material-ui/core";
import Style from "styled-components";

type SearchedItemContainer = {
  searchVariant?: "basic" | "big";
};

export const SearchedItemContainer = Style(Paper)<SearchedItemContainer>`
    height: 200px;
    width: ${(props) => (props.searchVariant === "basic" ? "auto" : "400px")};
    position: ${(props) =>
      props.searchVariant === "basic" ? "relaitive" : "fixed"};
    background: #ffff;
    z-index: 10;
    border-radius: 5px;
    overflow-y: auto;
`;

export const SearchList = Style(List)`
    padding-left: 10px;
    border-bottom: 1px #c9c8c8 solid;
    cursor: pointer;
    &:hover {
        background-color: #efefef;
    }
`;
