import { CheckboxOptionType } from "./type";

export const downloadSelectedFilterCsv = (
  filterOptions: CheckboxOptionType[],
  fileName: string
) => {
  const rows = [["Name", "Count"]];
  filterOptions.map((item) =>
    rows.push([item.name.replaceAll(",", " "), `${item.count}`])
  );
  let csvContent = "";
  csvContent = rows.map((e) => e.join(",")).join("\n");
  const download = (content: any, fileName: string, mimeType: any) => {
    const a = document.createElement("a");
    mimeType = mimeType || "application/octet-stream";
    const nav: any = navigator;
    if (nav.msSaveBlob) {
      nav.msSaveBlob(
        new Blob([content], {
          type: mimeType,
        }),
        fileName
      );
    } else if (URL && "download" in a) {
      a.href = URL.createObjectURL(
        new Blob([content], {
          type: mimeType,
        })
      );
      a.setAttribute("download", fileName);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      location.href =
        "data:application/octet-stream," + encodeURIComponent(content); // only this mime type is supported
    }
  };

  download(csvContent, fileName, "text/csv;encoding:utf-8");
};
