import { Grid } from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { secondarySubHeadingFontColor } from "../../constants/ColorConstants";
import { useGetSubscriptionInfo } from "../../data/subscription/selector";
import { showError } from "../commoncomponent/Client";
import { SimpleTextButton } from "../commoncomponent/style";
import { BillingPortalContainer, HighlightedText } from "./style";
import { updateDownloadSubscriptionPlan } from "./util";

const BillingPortalView = () => {
    const { billingPortal } = useSelector(useGetSubscriptionInfo);
    const dispatch = useDispatch();
    const handleSubmitClick = () => {
        updateDownloadSubscriptionPlan()
            .then((res) => window.open(res, "_self"))
            .catch((err) => dispatch(showError(err)));
    };

    if (!billingPortal.displayPortal) {
        return <></>;
    }

    return (
        <BillingPortalContainer container>
            <Grid item>
                <HighlightedText style={{ color: secondarySubHeadingFontColor }}>
                    {billingPortal.text}
                </HighlightedText>
            </Grid>
            <Grid item>
                <SimpleTextButton
                    size={"small"}
                    onClick={handleSubmitClick}
                    variant={"text"}
                    color={"primary"}
                >
                    Go to portal
                </SimpleTextButton>
            </Grid>
        </BillingPortalContainer>
    );
};

export default BillingPortalView;
