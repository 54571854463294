import { FC, useEffect } from "react";
import { DataGrid } from "@material-ui/data-grid";
import { useState } from "react";
import { DataGridContainer } from "../../../ecommerce/style";
import { TechnologyItemInfo } from "../TechnologySearchView/type";
import { useTechGainFilteredListColumns } from "./hooks";
import { SelectedTechType } from "../type";
import { useDatagridStyles } from "../../../userfacetlist/useDatagridStyle";

interface TechnologyGainDataGridProps {
  list: TechnologyItemInfo[];
  selectedTech: (tech: SelectedTechType) => void;
}

const TechnologyGainDataGrid: FC<TechnologyGainDataGridProps> = ({
  list,
  selectedTech,
}) => {
  const columns = useTechGainFilteredListColumns();
  const [filterList, setFilterList] = useState<TechnologyItemInfo[]>([]);
  const { datagrid } = useDatagridStyles();

  useEffect(() => {
    setFilterList(list);
  }, [list]);

  if (list.length > 0) {
    return (
      <>
        <DataGridContainer>
          <DataGrid
            rows={filterList}
            columns={columns}
            hideFooter
            disableColumnMenu
            rowHeight={40}
            autoHeight
            onRowClick={(params) =>
              selectedTech({
                domain: params.row.domain,
                sid: params.row.id,
              })
            }
            className={datagrid}
          />
        </DataGridContainer>
      </>
    );
  }
  return null;
};

export default TechnologyGainDataGrid;
