import { Button, FormControl, Grid, Select } from "@material-ui/core";
import Styled from "styled-components";
import {
  advanceGridBorderColor,
  advanceGridColor,
  dangerColor,
} from "../../../constants/ColorConstants";

export const SelectedFieldContainer = Styled(Grid)`
    padding: 15px;
    background: ${advanceGridColor};
    border: 1px solid ${advanceGridBorderColor};
    margin: 15px 5px 15px 5px;
    border-radius: 5px;
`;

export const RemoveFieldButton = Styled(Button)`
    color: ${dangerColor};
    margin-top: 20px;
`;

export const DropdownContainer = Styled(Select)`
    & .MuiSelect-root{
        font-size: 13px;
    }
`;

export const FormControlContainer = Styled(FormControl)`
    & .MuiFormLabel-root{
        font-size: 13px;
    }
`;
