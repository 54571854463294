import { CircularProgress } from "@material-ui/core";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useGetIsLoading } from "../../data/common/selector";
import { LoadingComponentContainer } from "./style";

interface LoadingComponentProps {
  children: JSX.Element;
  loading?: boolean;
  loadingVariant?: "determinate" | "static";
}

const LoadingComponent: FC<LoadingComponentProps> = ({
  children,
  loading,
  loadingVariant,
}) => {
  const isLoading = useSelector(useGetIsLoading);

  if (isLoading || loading) {
    if (loadingVariant === "static") {
      return <CircularProgress size={20} />;
    }

    return (
      <LoadingComponentContainer
        container
        justifyContent="center"
        alignItems={"center"}
      >
        <CircularProgress />
      </LoadingComponentContainer>
    );
  }
  return children;
};

export default LoadingComponent;
