import { makeStyles, Theme, AppBar, Tab } from "@material-ui/core";
import React, { FC, useState } from "react";
import TabPanelWrapper from "../../commoncomponent/TabPanelWrapper";
import TechnologyGainSection from "./TechnologyGainView/TechnologyGainSection";
import TechnologyLossSection from "./TechnologyLossView/TechnologyLossSection";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import { TabsContainer } from "./style";
import { useBooleanState, useDataState } from "../../hooks/common/hooks";
import { SelectedTechType } from "./type";
import EcommerceItemDetailPanel from "../../ecommerce/EcommerceDetailPanel/EcommerceItemDetailPanel";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    marginTop: 20,
  },
}));

interface TechnologyTabsSectionProps {
  techId: string;
}

const TechnologyTabsSection: FC<TechnologyTabsSectionProps> = ({ techId }) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const showDrawer = useBooleanState(false);
  const selectedTech = useDataState<SelectedTechType>();

  const handleChange = (
    //eslint-disable-next-line
    event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default" style={{ boxShadow: "none" }}>
        <TabsContainer
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab
            icon={<TrendingUpIcon />}
            style={{ textTransform: "none" }}
            label="Gain"
          />
          <Tab
            icon={<TrendingDownIcon />}
            style={{ textTransform: "none" }}
            label="Loss"
          />
        </TabsContainer>
      </AppBar>
      <TabPanelWrapper value={value} index={0}>
        <TechnologyGainSection
          techId={techId}
          selectedTech={(tech) => {
            selectedTech.set(tech);
            showDrawer.setTrue();
          }}
        />
      </TabPanelWrapper>
      <TabPanelWrapper value={value} index={1}>
        <TechnologyLossSection
          techId={techId}
          selectedTech={(tech) => {
            selectedTech.set(tech);
            showDrawer.setTrue();
          }}
        />
      </TabPanelWrapper>
      {showDrawer.value && selectedTech.value && (
        <EcommerceItemDetailPanel
          handleClose={() => {
            showDrawer.setFalse();
            selectedTech.set(undefined);
          }}
          selectedDomain={selectedTech.value.domain}
          sid={selectedTech.value.sid}
        />
      )}
    </div>
  );
};

export default TechnologyTabsSection;
