import { IActionPayload } from "../../component/commoncomponent/Base";
import { DELETE_USER_LIST, LabelType, UPDATE_LABEL_LIST } from "./type";

const initialLabelList: LabelType[] = [];

export const LabelReducer = (
  state = initialLabelList,
  action: IActionPayload<any>
) => {
  switch (action.type) {
    case UPDATE_LABEL_LIST:
      return action.payload;
    case DELETE_USER_LIST:
      return state.filter((list) => list.sid !== action.payload);
    default:
      return state;
  }
};
