import { Grid, Typography } from "@material-ui/core";
import React, { FC } from "react";
import {
  TechPrimaryColor,
  TechSecondaryTextColor,
  TextSecondaryColor,
} from "../../../constants/ColorConstants";
import { TechInfoText, TechTotalInfoContainer } from "./style";

interface TechnologyInfoSectionProps {
  name: string;
  description: string;
  total: string;
  gain: string;
  loss: string;
}

const TechnologyInfoSection: FC<TechnologyInfoSectionProps> = ({
  description,
  name,
  total,
  gain,
  loss,
}) => {
  return (
    <>
      <Grid
        style={{ marginTop: 0 }}
        container
        spacing={4}
        alignItems={"center"}
      >
        <Grid item style={{ borderRight: `1px ${TextSecondaryColor} solid` }}>
          <Typography
            color={"textPrimary"}
            variant={"h5"}
            style={{
              wordBreak: "break-word",
            }}
          >
            {name}
          </Typography>
        </Grid>
        <Grid item>
          <Typography color={"textPrimary"} variant={"body2"}>
            {description}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent={"center"}
        spacing={4}
        style={{ marginTop: 30 }}
      >
        <Grid item xs={3}>
          <TechTotalInfoContainer elevation={0}>
            <Typography align={"center"} variant={"h5"}>
              Total
            </Typography>
            <TechInfoText align={"center"} textColor={TechSecondaryTextColor}>
              {total.toLocaleString()}
            </TechInfoText>
          </TechTotalInfoContainer>
        </Grid>
        <Grid item xs={3}>
          <TechTotalInfoContainer elevation={0}>
            <Typography align={"center"} variant={"h5"}>
              Gain this month
            </Typography>
            <TechInfoText align={"center"} textColor={TechPrimaryColor}>
              {gain.toLocaleString()}
            </TechInfoText>
          </TechTotalInfoContainer>
        </Grid>
        <Grid item xs={3}>
          <TechTotalInfoContainer elevation={0}>
            <Typography align={"center"} variant={"h5"}>
              Loss this month
            </Typography>
            <TechInfoText align={"center"} color={"secondary"}>
              {loss.toLocaleString()}
            </TechInfoText>
          </TechTotalInfoContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default TechnologyInfoSection;
