import { Button, Divider, Grid } from "@material-ui/core";
import React, { FC } from "react";
import {
  HighlightedText,
  PlanMessage,
  PlansCardContainer,
  PlanTitle,
  Tick,
  Wrong,
} from "./style";
import { useBooleanState } from "../hooks/common/hooks";
import { PlansListType } from "../../data/subscription/type";
import { buySubscriptionPlan } from "./util";
import { showError } from "../commoncomponent/Client";
import { useDispatch } from "react-redux";
import { monthlyConstant } from "../../constants/SiteConstants";

type PlansCardViewProps = {
  planData: PlansListType;
  planSubscriptionPlan: string;
};

const PlansCardView: FC<PlansCardViewProps> = ({
  planData,
  planSubscriptionPlan,
}) => {
  const isHovered = useBooleanState(false);
  const { headline, name, negativeFeatures, positiveFeatures, prices, plan } =
    planData;
  const dispatch = useDispatch();
  const handlePlanSubmit = () => {
    buySubscriptionPlan(plan, planSubscriptionPlan)
      .then((url) => window.open(url, "_self"))
      .catch((err) => dispatch(showError(err)));
  };

  return (
    <PlansCardContainer
      onMouseEnter={() => isHovered.setTrue()}
      onMouseLeave={() => isHovered.setFalse()}
    >
      <Grid item>
        <PlanTitle>{name}</PlanTitle>
        <PlanMessage>{headline}</PlanMessage>
        <PlanMessage>
          <HighlightedText style={{ fontSize: 38 }}>
            {`$`}
            {planSubscriptionPlan === monthlyConstant
              ? prices[0].price
              : prices[1].price}
          </HighlightedText>
          {planSubscriptionPlan === monthlyConstant ? "/month" : "/year"}
        </PlanMessage>
      </Grid>
      <Grid style={{ marginTop: 20 }}>
        <Button
          fullWidth
          variant={"contained"}
          color={"primary"}
          onClick={handlePlanSubmit}
          style={{ textTransform: "none" }}
        >
          Select Plan
        </Button>
      </Grid>
      <Divider style={{ margin: "20px 0px" }} />
      <Grid>
        <div style={{ marginBottom: 10 }}>
          <HighlightedText>WHAT'S INCLUDED</HighlightedText>
        </div>
        {positiveFeatures.map((item, index) => (
          <Grid container spacing={1} key={index} direction="row">
            <Grid item>
              <Tick />
            </Grid>
            <Grid item xs={10}>
              <PlanMessage>{item}</PlanMessage>
            </Grid>
          </Grid>
        ))}
      </Grid>
      {negativeFeatures.length > 0 && (
        <Grid>
          {negativeFeatures.map((item, index) => (
            <Grid container spacing={1} key={index}>
              <Grid item>
                <Wrong />
              </Grid>
              <Grid item xs={10}>
                <PlanMessage>{item}</PlanMessage>
              </Grid>
            </Grid>
          ))}
        </Grid>
      )}
    </PlansCardContainer>
  );
};

export default PlansCardView;
