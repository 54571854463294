import { Grid } from "@material-ui/core";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fieldConstant,
  operationConstant,
  techConstant,
  textConstant,
  valueConstant,
} from "../../../constants/SiteConstants";
import { updateAdvanceSelectedSearchOptions } from "../../../data/advancesearch/action";
import { useDataState } from "../../hooks/common/hooks";
import AdvanceOptionDropdownView from "./AdvanceOptionDropdownView";
import AdvanceSelectedTechView from "./AdvanceSelectedTechView";
import AdvanceSelectOptionDisplayView from "./AdvanceSelectOptionDisplayView";
import { compact } from "lodash";
import {
  useGetAdvanceSearchOptions,
  useGetAdvanceSelectedOptions,
} from "../../../data/advancesearch/selector";
import {
  AdvanceSearchOptionsType,
  SelectedAdvanceFilterValueType,
} from "../../../data/advancesearch/type";
import AdvanceSelectedTextfieldView from "./AdvanceSelectedTextfieldView";

type AdvanceOptionsType = {
  optionInfo: SelectedAdvanceFilterValueType;
};

const AdvanceOptions: FC<AdvanceOptionsType> = ({ optionInfo }) => {
  const options = useSelector(useGetAdvanceSearchOptions);
  const selectedOptionInfo = useDataState<AdvanceSearchOptionsType>();
  const addEcommFilters = useSelector(useGetAdvanceSelectedOptions);
  const selectedFilterInfo = useDataState<SelectedAdvanceFilterValueType>();

  const dispatch = useDispatch();

  useEffect(() => {
    selectedOptionInfo.set(
      options.filterInfo.find(
        (option) => option.filterType === optionInfo.fieldType
      )
    );
    selectedFilterInfo.set(optionInfo);
  }, [optionInfo.fieldType]);

  const handleChange = (selectedOption: string, type: string) => {
    const index = addEcommFilters.findIndex(
      (item) => item.id === optionInfo.id
    );
    if (type === fieldConstant) {
      if (selectedOption === techConstant) {
        addEcommFilters[index].techValue = [
          {
            id: Math.random(),
            sid: "",
            name: "",
            installOptions: "",
            listVals: [],
            selectedValuePair: [],
            text: "",
          },
        ];
      } else {
        if (addEcommFilters[index].techValue) {
          delete addEcommFilters[index].techValue;
        }
      }
      addEcommFilters[index].fieldType = selectedOption;
      addEcommFilters[index].fieldName =
        options.filterTypes.find((item) => item.value === selectedOption)
          ?.name || "";
      selectedOptionInfo.set(
        options.filterInfo.find((item) => item.filterType === selectedOption)
      );
    }
    if (type === operationConstant) {
      addEcommFilters[index].operation = selectedOption;
    }
    if (type === valueConstant) {
      addEcommFilters[index].value = compact(selectedOption.split(","));
      const selectedOptionFilterInfo = options.filterInfo.find(
        (item) => item.filterType === optionInfo.fieldType
      );
      const selectedOptionValueInKeyPair = selectedOption
        .split(",")
        .map((option) =>
          selectedOptionFilterInfo?.listData.options.find(
            (filter) => filter.value === option
          )
        );
      addEcommFilters[index].selectedValuePair = compact(
        selectedOptionValueInKeyPair
      );
    }
    dispatch(updateAdvanceSelectedSearchOptions(addEcommFilters));
    selectedFilterInfo.set(addEcommFilters[index]);
  };

  const getOptions = (type: string) => {
    if (!selectedOptionInfo.value) {
      return <></>;
    }
    if (type === textConstant) {
      return (
        <AdvanceSelectedTextfieldView
          selectedFilterInfo={selectedFilterInfo.value}
          selectedOptionInfo={selectedOptionInfo.value}
          optionInfoId={optionInfo.id}
        />
      );
    }
    if (type === techConstant) {
      return (
        <AdvanceSelectedTechView
          selectedFilterInfo={selectedFilterInfo.value}
          selectedOptionInfo={selectedOptionInfo.value}
          handleFieldChange={(newTechValue) =>
            selectedFilterInfo.set(newTechValue)
          }
          key={selectedFilterInfo.value?.operation}
          optionInfoId={optionInfo.id}
        />
      );
    }

    return (
      <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
        <AdvanceSelectOptionDisplayView
          displayAs={selectedOptionInfo.value.selectionConfig.displayAs || ""}
          handleChange={(selectedOption: string) =>
            handleChange(selectedOption, operationConstant)
          }
          options={selectedOptionInfo.value.selectionConfig.options || []}
          value={selectedFilterInfo.value?.operation || ""}
          label={selectedOptionInfo.value.selectionConfig.name || ""}
          isMultiple={selectedOptionInfo.value.selectionConfig.chooseMultiple}
        />
        <AdvanceSelectOptionDisplayView
          label={selectedOptionInfo.value.listData.name || ""}
          displayAs={selectedOptionInfo.value.listData.displayAs}
          handleChange={(selectedOption: string) =>
            handleChange(selectedOption, valueConstant)
          }
          options={selectedOptionInfo.value.listData.options || []}
          value={selectedFilterInfo.value?.value.join(",") || ""}
          isMultiple={selectedOptionInfo.value.listData.chooseMultiple}
          isSearchable={["country", "tld"].includes(type)}
        />
      </div>
    );
  };

  return (
    <Grid>
      <AdvanceOptionDropdownView
        value={selectedOptionInfo.value?.filterType || optionInfo.fieldType}
        handleChange={(selectedOption: string) =>
          handleChange(selectedOption, fieldConstant)
        }
        isFieldTech
        label={"Fields"}
        dropdownOptions={options.filterTypes || []}
      />
      {selectedOptionInfo.value && (
        <>{getOptions(selectedOptionInfo.value?.filterType)}</>
      )}
    </Grid>
  );
};

export default AdvanceOptions;
