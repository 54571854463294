import { BASE_URL } from "../config/app";

const baseUrl = BASE_URL;
const resetPasswordBaseUrl = "https://auth.stackbrain.in";

export const getResetPasswordApiUrl = () =>
  `${resetPasswordBaseUrl}/dbconnections/change_password`;

export const getEcommerceSiteApiUrl = () => `${baseUrl}/ecomm/`;

export const getExportEcommerceApiUrl = () => `${baseUrl}/ecomm/export/`;

export const getEcommerceSiteDetailsApiUrl = () => `${baseUrl}/ecomm-site/`;

export const getTechDetailsApiUrl = (sid: string) => `${baseUrl}/org/${sid}/`;

export const getTechCategoryApiUrl = () => `${baseUrl}/categories/`;

export const getTechApiUrl = (tech: string) =>
  `${baseUrl}/category/${tech}/techs/`;

export const getTechnologyHighlightDetailsApiUrl = (techId: string) =>
  `${baseUrl}/tech/${techId}/highlights/`;

export const getTechnologySearchDetailsApiUrl = () => `${baseUrl}/tech/search/`;

export const getTechnologyGainDetailsApiUrl = (sid: string) =>
  `${baseUrl}/tech/${sid}/gain/`;

export const getTechnologyLossDetailsApiUrl = (sid: string) =>
  `${baseUrl}/tech/${sid}/loss/`;

export const getEcommerceLabelListApiUrl = (type: string) =>
  `${baseUrl}/lists/?typ=${type}`;

export const getAddEcommerceLabelApiUrl = (type: string) =>
  `${baseUrl}/list/add_with_query/?typ=${type}`;

export const getUpdateEcommerceLabelApiUrl = (type: string) =>
  `${baseUrl}/list/associate_query/?typ=${type}`;

export const getAddFavouriteApiUrl = (sid: string) =>
  `${baseUrl}/fav/add/${sid}/`;

export const getDeleteFavouriteApiUrl = (sid: string) =>
  `${baseUrl}/fav/del/${sid}/`;

export const deleteUserListApiUrl = (sid: string) =>
  `${baseUrl}/list/${sid}/delete/`;

export const truncateUserListApiUrl = (sid: string) =>
  `${baseUrl}/list/${sid}/flush/`;

export const getAddTechFavouriteApiUrl = (sid: string) =>
  `${baseUrl}/tech/fav/add/${sid}/`;

export const getDeleteTechFavouriteApiUrl = (sid: string) =>
  `${baseUrl}/tech/fav/del/${sid}/`;

export const getOrganisationTechApiUrl = () => `${baseUrl}/org/search/`;

export const getAutoCompleteOrgSearchApiUrl = (text: string) =>
  `${baseUrl}/tech/autocomplete/?pre=${text}`;

export const getLabelListApiUrl = () => `${baseUrl}/lists/`;

export const getDomainImageApiUrl = (domain: string) =>
  `https://s2.googleusercontent.com/s2/favicons?domain=${domain}&sz=64`;

export const getEcommAdvanceSearchApiUrl = () =>
  `${baseUrl}/ecomm-advanced/cfg/`;

export const getOrgAdvanceConfigSearchApiUrl = () =>
  `${baseUrl}/org/advanced/cfg/`;

export const getEcommerceAdvanceSearchResultApiUrl = () =>
  `${baseUrl}/ecomm-advanced/`;

export const getSubscriptionPlanApiUrl = () => `${baseUrl}/billing/`;

export const createCheckoutSession = () => `${baseUrl}/checkout-session/`;

export const updateSubscriptionPlanApiUrl = () => `${baseUrl}/portal-session/`;

export const getOrgAdvanceSearchApiUrl = () =>
  `${baseUrl}/org/advanced/search/`;

export const updateAdvanceListApiUrl = (type: string) =>
  `${baseUrl}/list/associate_query/?typ=${type}`;

export const addAdvanceListApiUrl = (type: string) =>
  `${baseUrl}/list/add_with_query/?typ=${type}`;

export const addNewDomainListApiUrl = () => `${baseUrl}/list/domain/`;

export const uploadDomainFileApiUrl = (id: string) =>
  `${baseUrl}/list/${id}/upload/`;

export const exportEcommFileOptionsApiUrl = () =>
  `${baseUrl}/ecomm/export-opts/`;

export const exportOrgFileOptionsApiUrl = () => `${baseUrl}/org/export-opts/`;

export const downloadSampleOrgFile = () => `${baseUrl}/org/export/`;

export const downloadSampleEcommFile = () => `${baseUrl}/org/export/`;

export const downloadOrgFileApiUrl = () => `${baseUrl}/org/async-export/`;

export const downloadEcommFileApiUrl = () => `${baseUrl}/ecomm/async-export/`;

export const downloadFileApiUrl = (id: string) =>
  `${baseUrl}/export/download/${id}`;

export const startupApiUrl = () => `${baseUrl}/preferences/startup/`;

export const startupCloseApiUrl = () => `${baseUrl}/preferences/welcome_done/`;
