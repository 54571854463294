import React, { FC } from "react";
import { DataGrid } from "@material-ui/data-grid";
import { useTechFilteredListColumns } from "./hooks";
import { TechnologyType } from "./type";
import { FilterInfoText } from "../commoncomponent/style";
import { DataGridContainer } from "../ecommerce/style";
import { TextPrimaryColor } from "../../constants/ColorConstants";
import { useDatagridStyles } from "../userfacetlist/useDatagridStyle";
import { Typography } from "@material-ui/core";
import { getAddFavouriteTech, getRemoveFavouriteTech } from "./TechnologyDetailPanel/util";

interface TechnologyDataGridProps {
  techName: string;
  list: TechnologyType[];
  noResultFound: boolean;
  totalCount: string;
  isTechSelected: boolean;
  techSelected: (techId: string) => void;
  fetchTechList: (isLoading: boolean) => void;
}

const TechnologyDataGrid: FC<TechnologyDataGridProps> = ({
  techName,
  list,
  noResultFound,
  totalCount,
  isTechSelected,
  techSelected,
  fetchTechList
}) => {
  const handleTechSelect = (id: string) => {
    techSelected(id);
  };

  const handleChangeStatus = (id: string, isFavourite: boolean) => {
    if (isFavourite) {
      getAddFavouriteTech(id).then(() => {
        fetchTechList(false);
      });
    }
    getRemoveFavouriteTech(id).then(() => {
      fetchTechList(false);
    });
  };

  const columns = useTechFilteredListColumns(handleTechSelect, handleChangeStatus);

  const { datagrid } = useDatagridStyles();

  if (isTechSelected) {
    return (
      <FilterInfoText
        variant={"h4"}
        align={"center"}
        style={{ color: TextPrimaryColor }}
      >
        Choose a category to search
      </FilterInfoText>
    );
  }

  if (noResultFound || list.length === 0) {
    return (
      <FilterInfoText variant={"h6"} align={"center"}>
        There are no results that match your search <br />
        Please try modifying your search to get more results.
      </FilterInfoText>
    );
  }

  if (list.length > 0) {
    return (
      <>
        <Typography color={"primary"}>{techName}</Typography>
        <DataGridContainer>
          <DataGrid
            rows={list}
            columns={columns}
            hideFooter
            disableColumnMenu
            rowHeight={40}
            style={{ height: "95%" }}
            className={datagrid}
          />
        </DataGridContainer>
        <FilterInfoText
          align={"center"}
          variant={"body1"}
          style={{ margin: 5 }}
        >
          {list.length} records out of {totalCount} records
        </FilterInfoText>
      </>
    );
  }
  return null;
};

export default TechnologyDataGrid;
