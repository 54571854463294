import { cloneDeep } from "lodash";
import { IActionPayload } from "../../component/commoncomponent/Base";
import {
  AdvanceSearchType,
  UPDATE_ADVANCE_OPTIONS,
  UPDATE_ADVANCE_SELECTED_OPTIONS,
} from "./type";

export const initialAdvanceSearchFilters: AdvanceSearchType = {
  advanceSearchOptions: {
    filterInfo: [],
    filterTypes: [],
  },
  selectedFilterValues: [],
};

export const AdvanceSearchReducer = (
  state = initialAdvanceSearchFilters,
  action: IActionPayload<any>
) => {
  const stateClone = cloneDeep(state);

  switch (action.type) {
    case UPDATE_ADVANCE_OPTIONS:
      stateClone.advanceSearchOptions = action.payload;
      return stateClone;

    case UPDATE_ADVANCE_SELECTED_OPTIONS:
      stateClone.selectedFilterValues = action.payload;
      return stateClone;

    default:
      return state;
  }
};
