import { compact } from "lodash";
import { getEcommerceAdvanceSearchResultApiUrl } from "../../../constants/ApiConstants";
import {
  noOfRecordPerPage,
  techConstant,
} from "../../../constants/SiteConstants";
import {
  SelectedTechVals,
  OptionsType,
  SelectedAdvanceFilterValueType,
} from "../../../data/advancesearch/type";
import { Client } from "../../commoncomponent/Client";

const reverseTechValue = (data: SelectedTechVals[]): any => {
  if (data.length === 0) {
    return [];
  }
  return data[0].sid.split(",").map((item, index) => ({
    tech: {
      name: data[0].name.split(",")[index],
      val: item,
    },
    install_opt: data[0].installOptions,
    install_values: reverseListValue(data[0].selectedValuePair),
  }));
};

const reverseListValue = (data: OptionsType[]): any =>
  data.map((item) => ({
    name: item.name,
    val: item.value,
  }));

export const reverseAdvanceSearchTransform = (
  data: SelectedAdvanceFilterValueType[],
  offset: number
): any => {
  const removeAllEmptyType = data.filter((item) => item.fieldType !== "");
  const withoutTech = removeAllEmptyType.filter(
    (item) => item.fieldType !== techConstant
  );
  const dataWithoutTech = withoutTech.map((item) => ({
    field: item.fieldType,
    op: item.operation,
    text: item.text,
    values: reverseListValue(item.selectedValuePair),
  }));
  const withTech = removeAllEmptyType.filter(
    (item) => item.fieldType === techConstant
  );
  const dataWithTech = withTech.map((item) => ({
    field: item.fieldType,
    op: item.operation,
    text: item.text,
    tech_values: reverseTechValue(item.techValue || []),
  }));
  return {
    as_query: {
      filters: [...dataWithoutTech, ...dataWithTech],
      offset: offset,
      count: noOfRecordPerPage,
    },
  };
};

export const transformTech = (techFilters: any) =>
  techFilters.map((item: any) => ({
    fieldName: "Tech - Ecomm platforms, apps and others",
    id: Math.random(),
    operation: item.op,
    value: [],
    fieldType: item.field,
    text: item.text,
    techValue: [
      {
        id: Math.random(),
        sid: item.tech_values[0].tech.val,
        name: item.tech_values[0].tech.name,
        installOptions: item.tech_values[0].install_opt,
        listVals: item.tech_values?.[0]?.install_values
          ? item.tech_values[0].install_values.map(({ val }: any) => val)
          : [],
        selectedValuePair: item.tech_values?.[0]?.install_values
          ? item.tech_values[0].install_values.map(({ name, val }: any) => ({
              name: name,
              value: val,
            }))
          : [],
      },
    ],
  })) || [];

export const transformOtherField = (otherFilters: any) =>
  otherFilters.map((item: any) => ({
    fieldName: item.field,
    id: Math.random(),
    operation: item.op,
    value: item.values ? item.values.map(({ val }: any) => val) : [],
    fieldType: item.field,
    text: item.text,
    selectedValuePair: item.values
      ? item.values.map(({ val, name }: any) => ({
          name: name,
          value: val,
        }))
      : [],
  })) || [];

export const getPrepopulatedAdvanceSearch = (
  sids: string,
  searchedText: string
) => {
  const sidsArray = compact(sids.split(","));
  return Client.getInstance()
    .createData(getEcommerceAdvanceSearchResultApiUrl(), {
      bs_query: {
        sids: sidsArray,
        order_by: "",
        offset: 0,
        count: 100,
        text: searchedText,
      },
    })
    .then((res) => {
      const withoutTech = res.data.as_query.filters.filter(
        (item: any) => item.field !== techConstant
      );
      const withTech = res.data.as_query.filters.filter(
        (item: any) => item.field === techConstant
      );

      return Promise.resolve([
        ...transformTech(withTech),
        ...transformOtherField(withoutTech),
      ]);
    })
    .catch((err) => Promise.reject(err));
};
