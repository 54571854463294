import { Grid, Typography } from "@material-ui/core";
import React, { FC } from "react";
import CardDetailsTitle from "./SocialDeatilsCardTitle";
import { BoxContainer } from "./style";
import { TechsType } from "./type";

interface TechnologyCardProps {
  techItem: TechsType[];
}

const TechnologyCard: FC<TechnologyCardProps> = ({ techItem }) => {
  return (
    <Grid container spacing={5}>
      {techItem.map((item) => (
        <Grid item xs={6} key={item.id}>
          <BoxContainer>
            <CardDetailsTitle title={item.name} />
            <Typography variant={"body2"} color={"textPrimary"}>
              <a href={item.website} target={"_blank"}>
                {item.website}
              </a>
            </Typography>
            <Typography variant={"caption"} color={"textPrimary"}>
              {item.description}
            </Typography>
          </BoxContainer>
        </Grid>
      ))}
    </Grid>
  );
};

export default TechnologyCard;
