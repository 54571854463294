import { Button } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import React, { FC } from "react";
import { FilterInfoText } from "../commoncomponent/style";
import { DataGridContainer } from "../ecommerce/style";
import { SelectedTechType } from "../technology/TechnologyDetailPanel/type";
import { useDatagridStyles } from "../userfacetlist/useDatagridStyle";
import { ColumnType, OrganisationItemInfo } from "./type";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import { Grid } from "@mui/material";
import { useFilteredListColumns } from "../hooks/common/DataGridHooks";

interface OrganisationDataGridProps {
  list: OrganisationItemInfo[];
  noResultFound: boolean;
  isLoading: boolean;
  selectedTech: (tech: SelectedTechType) => void;
  handleExport: () => void;
  activeColumns: ColumnType[];
}

const OrganisationDataGrid: FC<OrganisationDataGridProps> = ({
  isLoading,
  list,
  noResultFound,
  selectedTech,
  handleExport,
  activeColumns,
}) => {
  const classes = useDatagridStyles();
  const columns = useFilteredListColumns(activeColumns);

  if (noResultFound) {
    return (
      <FilterInfoText variant={"h6"} align={"center"}>
        There are no results that match your search <br />
        Please try modifying your search to get more results.
      </FilterInfoText>
    );
  }

  return (
    <>
      <Grid container style={{ marginBottom: 5 }} justifyContent={"flex-end"}>
        <Button
          onClick={handleExport}
          startIcon={<CloudDownloadOutlinedIcon />}
          variant={"outlined"}
          color={"primary"}
          size={"small"}
          id={"export_org"}
        >
          Export
        </Button>
      </Grid>
      <DataGridContainer>
        <DataGrid
          rows={list}
          columns={columns}
          hideFooter
          disableColumnMenu
          rowHeight={65}
          style={{ height: "91%" }}
          loading={isLoading}
          className={classes.datagrid}
          onRowClick={(params) =>
            selectedTech({
              domain: params.row.domain,
              sid: params.row.id,
            })
          }
        />
      </DataGridContainer>
    </>
  );
};

export default OrganisationDataGrid;
