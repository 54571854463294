import { Checkbox, Grid, Typography } from "@material-ui/core";
import React, { FC, useEffect, useMemo } from "react";
import {
  CheckboxItemsContainer,
  CheckboxItemText,
} from "../../commoncomponent/style";
import { useDataState } from "../../hooks/common/hooks";
import { noOfFilterToShow } from "../../../constants/SiteConstants";
import { useGetShowMoreOrLessButton } from "../../commoncomponent/Filters/hooks";
import SearchTextField from "../../commoncomponent/SearchTextField";
import CheckIcon from "../../../images/checkedBox.svg";
import UnCheckIcon from "../../../images/uncheckedBox.svg";
import { OptionsType } from "../../../data/advancesearch/type";

type AdvanceOptionListViewProps = {
  listOptions: OptionsType[];
  setSelectedOptions: (selectedOptions: string) => void;
  isMultiple?: boolean;
  label: string;
  value: string[];
};
const AdvanceOptionListView: FC<AdvanceOptionListViewProps> = ({
  listOptions,
  setSelectedOptions,
  isMultiple,
  label,
  value,
}) => {
  const filteredListOptions = useDataState<OptionsType[]>([]);
  const totalNumberOfResult = useDataState<number>(noOfFilterToShow);
  const searchItem = useDataState<string>("");

  useEffect(() => {
    filteredListOptions.set(listOptions);
  }, [listOptions]);

  const handleClick = (selectedItem: string) => {
    if (!isMultiple) {
      setSelectedOptions(selectedItem);
      return;
    }
    if (value?.includes(selectedItem)) {
      const filteredValue = value.filter((item) => item !== selectedItem);
      setSelectedOptions(filteredValue.join(","));
      return;
    }
    setSelectedOptions([...value, selectedItem].join(","));
  };

  const setTotalNumberOfResult = (totalNumber: number) =>
    totalNumberOfResult.set(totalNumber);

  const getShowMoreOrLessButton = useGetShowMoreOrLessButton(
    listOptions.length || 0,
    totalNumberOfResult.value || noOfFilterToShow,
    noOfFilterToShow,
    setTotalNumberOfResult
  );

  const onSearchFilter = (text: string) => {
    searchItem.set(text);
    if (listOptions) {
      filteredListOptions.set(
        listOptions.filter((label) => label.name.toLowerCase().includes(text))
      );
    }
  };

  const renderList = useMemo(
    () =>
      filteredListOptions.value &&
      totalNumberOfResult.value &&
      filteredListOptions.value
        .slice(0, totalNumberOfResult.value)
        .map((item) => (
          <CheckboxItemsContainer
            key={item.value}
            onClick={() => handleClick(item.value)}
            isselected={0}
          >
            <Checkbox
              style={{ padding: 0 }}
              checked={value.includes(item.value)}
              size={"small"}
              icon={
                <img
                  src={UnCheckIcon}
                  alt="uncheckedBox"
                  width={16}
                  height={16}
                />
              }
              checkedIcon={
                <img
                  src={CheckIcon}
                  alt="uncheckedBox"
                  width={16}
                  height={16}
                />
              }
            />
            <CheckboxItemText>{item.name}</CheckboxItemText>
          </CheckboxItemsContainer>
        )),
    [filteredListOptions.value, value, totalNumberOfResult.value]
  );

  return (
    <>
      <Grid
        container
        style={{ marginTop: 5 }}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Grid item xs={6}>
          <Typography variant={"body2"}>{label}</Typography>
        </Grid>
        <Grid
          item
          xs={6}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <SearchTextField
            onClearIconClick={() => {
              searchItem.set("");
              onSearchFilter("");
            }}
            value={searchItem.value || ""}
            onTextChange={onSearchFilter}
          />
        </Grid>
      </Grid>
      {renderList}
      {getShowMoreOrLessButton}
    </>
  );
};

export default AdvanceOptionListView;
