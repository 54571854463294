import { IActionPayload } from "../../component/commoncomponent/Base";
import {
  CategoryType,
  UPDATE_ALREADY_SELECTED_TECH,
  UPDATE_TECH_FILTERS,
  UPDATE_TECH_SCROLL_POSITION,
  UPDATE_TECH_SELECTED_SIDS,
  UPDATING_TECH_LOADING,
} from "./type";

export const updateTechFilters = (
  category: CategoryType[]
): IActionPayload<CategoryType[]> => ({
  type: UPDATE_TECH_FILTERS,
  payload: category,
});

export const updateTechSids = (sids: string): IActionPayload<string> => ({
  type: UPDATE_TECH_SELECTED_SIDS,
  payload: sids,
});

export const updateAlreadySelectedSid = (
  isAlreadySelected: boolean
): IActionPayload<boolean> => ({
  type: UPDATE_ALREADY_SELECTED_TECH,
  payload: isAlreadySelected,
});

export const updateLoading = (isLoading: boolean): IActionPayload<boolean> => ({
  type: UPDATING_TECH_LOADING,
  payload: isLoading,
});

export const updateTechScrollPosition = (
  position: number
): IActionPayload<number> => ({
  type: UPDATE_TECH_SCROLL_POSITION,
  payload: position,
});
