import mixpanel from "mixpanel-browser";
import configData from "../config/index";

export const initialise = () => {
  mixpanel.init(configData.ANALYTICS_TOKEN || "");
};

export const trackEvent = (event: any, data?: any) => {
  if (data) {
    mixpanel.track(event, { data, email: sessionStorage.getItem("email") });
  } else {
    mixpanel.track(event, { email: sessionStorage.getItem("email") });
  }
};

export const setIdentity = (id: string) => {
  if (id) {
    mixpanel.identify(id);
  }
};

export const setAlias = (id: string) => {
  if (id) {
    mixpanel.alias(id);
  }
};

export const setPeople = (props: any) => {
  if (props) {
    mixpanel.people.set(props);
  }
};
