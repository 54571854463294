import { Button, Grid, Tooltip } from "@material-ui/core";
import { GridCellParams, GridColDef } from "@material-ui/data-grid";
import { LaunchRounded } from "@material-ui/icons";
import { useMemo } from "react";
import { getUtcDate } from "../../utils/utility";
import ActionManager from "./ActionManager";
import { ActionButtonDomain } from "./style";
import { SelectedListType } from "./type";
import { Link } from "react-router-dom";
import { ListColumnText } from "../commoncomponent/style";
import { ecommLabelConstant } from "../../constants/SiteConstants";

const getActionManager = (selectedFacet: SelectedListType): JSX.Element => (
  <ActionManager selectedFacet={selectedFacet} />
);

const handleSearchClick = (searchIn: string, sid: string) => {
  if (searchIn === ecommLabelConstant) {
    return `/ecommerce?sid=${sid}`;
  }
  return `/organisation?sid=${sid}`;
};

export const useUserListColumns = (): GridColDef[] =>
  useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        flex: 1,
        renderCell: (params: GridCellParams) => (
          <Grid container spacing={1} alignItems={"center"}>
            <Grid item>
              <ListColumnText>{params.row.name}</ListColumnText>
            </Grid>
            <Grid item>
              <Tooltip
                title={
                  params.row.search_in === ecommLabelConstant
                    ? "Go to ecommerce"
                    : "Go to organisation"
                }
              >
                <Link
                  to={() =>
                    handleSearchClick(params.row.search_in, params.row.sid)
                  }
                >
                  <ActionButtonDomain>
                    <LaunchRounded fontSize="small" />
                  </ActionButtonDomain>
                </Link>
              </Tooltip>
            </Grid>
          </Grid>
        ),
      },
      {
        field: "createdAt",
        headerName: "Created at",
        flex: 1,
        renderCell: (params: GridCellParams) => {
          return (
            <ListColumnText>
              {params.row.createdAt
                ? getUtcDate(params.row.createdAt).toString()
                : ""}
            </ListColumnText>
          );
        },
      },
      {
        field: "",
        headerName: "",
        sortable: false,
        flex: 1,
        renderCell: (params: GridCellParams) =>
          getActionManager({
            actions: params.row.actions,
            sid: params.row.id,
            name: params.row.name,
          }),
      },
    ],
    []
  );

export const useGetShowButton = (
  selectedFacet: SelectedListType,
  handleClick: (name: string) => void
) =>
  useMemo(
    () =>
      selectedFacet.actions.map((item) => (
        <Grid key={item} item xs={12}>
          <Button
            variant={"contained"}
            onClick={() => handleClick(item)}
            color={"primary"}
          >
            {item}
          </Button>
        </Grid>
      )),
    []
  );
