import React, { FC, useEffect } from "react";
import { useDataState } from "../../hooks/common/hooks";
import DropdownWrapper from "../../commoncomponent/DropdownWrapper";
import { compact, isArray } from "lodash";
import { OptionsType } from "../../../data/advancesearch/type";

type AdvanceOptionDropdownViewProps = {
  dropdownOptions: OptionsType[];
  label: string;
  handleChange: (option: string) => void;
  value: string | string[];
  isMultiple?: boolean;
  isSearchable?: boolean;
  isFieldTech?: boolean;
};

const AdvanceOptionDropdownView: FC<AdvanceOptionDropdownViewProps> = ({
  dropdownOptions,
  label,
  handleChange,
  value,
  isMultiple,
  isSearchable,
  isFieldTech = false,
}) => {
  const selectedOptions = useDataState<string[]>([]);
  const selectedDropdownOptions = useDataState<OptionsType[]>([]);

  useEffect(() => {
    const selectedOptionArray: any = [];
    if (!isArray(value)) {
      value
        .split(",")
        .map((itemVal) =>
          selectedOptionArray.push(
            dropdownOptions.find((item) => item.value === itemVal)
          )
        );
      selectedDropdownOptions.set(compact(selectedOptionArray));
      selectedOptions.set(value.split(","));
      !isFieldTech && handleChange(value);
    }
  }, [value]);

  const handleChangeMultiple = (selectedOption: string[]) => {
    selectedOptions.set(selectedOption);
    handleChange(selectedOption.join(","));
  };

  if (isMultiple) {
    return (
      <DropdownWrapper
        isMultiple
        isSearchable={isSearchable}
        label={label}
        dropdownOptions={dropdownOptions}
        onChange={(selectedOption) =>
          handleChangeMultiple(selectedOption as string[])
        }
        selectedOptions={selectedOptions.value || []}
        value={selectedOptions.value || []}
        selectedDropdownOptions={selectedDropdownOptions.value || []}
      />
    );
  }
  return (
    <DropdownWrapper
      label={label}
      isSearchable={isSearchable}
      dropdownOptions={dropdownOptions}
      onChange={(selectedOption) => handleChange(selectedOption as string)}
      selectedOptions={selectedOptions.value || []}
      value={value}
      selectedDropdownOptions={selectedDropdownOptions.value || []}
    />
  );
};

export default AdvanceOptionDropdownView;
