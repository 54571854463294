import React, { FC } from "react";
import CheckBoxContainer from "./CheckBoxContainer";
import { CheckboxOptionType } from "./type";

interface FilterCheckBoxViewProps {
  filteredCheckBoxes: CheckboxOptionType[];
  totalNumberOfResult: number;
  handleFilterChange: (selectedSid: string) => void;
  isChecked: string[];
}

const FilterCheckBoxView: FC<FilterCheckBoxViewProps> = ({
  filteredCheckBoxes,
  totalNumberOfResult,
  handleFilterChange,
  isChecked,
}) => {
  return (
    <div>
      {filteredCheckBoxes.slice(0, totalNumberOfResult).map((item) => (
        <div key={item.sid}>
          <CheckBoxContainer
            filterDetails={item}
            handleFilterChange={handleFilterChange}
            isChecked={isChecked}
          />
          {item.sub_facet_group && (
            <div style={{ marginLeft: 10 }}>
              <FilterCheckBoxView
                handleFilterChange={handleFilterChange}
                filteredCheckBoxes={item.sub_facet_group.facets}
                totalNumberOfResult={totalNumberOfResult}
                isChecked={isChecked}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FilterCheckBoxView;
