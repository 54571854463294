import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { NavigationStyles } from "./drawer/SideNavbarStyle";
import NavigationRoutes from "./Routes";
import { useDispatch } from "react-redux";
import { updateAccessToken, updateUserData } from "../data/action";
import configData from "../config";
import { Client, showError } from "./commoncomponent/Client";
import NavigationMenu from "./drawer/NavigationMenu";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { eCommerceRoute } from "../constants/routesLinks";
import { getEcommerceInitialSid } from "./ecommerce/util";
import { updateSids } from "../data/ecommerceStore/action";
import { initialise } from "../service/mixpanel";
import { setSessionUserDetails } from "../utils/sessionStorage";
import Tour from "reactour";
import { useBooleanState } from "../component/hooks/common/hooks";
import { tourConfig } from "../constants/TourConfig";
import { WEBSITE_TOUR } from "../config/app";
import { startupApiUrl, startupCloseApiUrl } from "../constants/ApiConstants";

const AppNavigation = () => {
  const classes = NavigationStyles();
  const { isAuthenticated, user } = useAuth0();
  const { getAccessTokenSilently } = useAuth0();
  const { logout } = useAuth0();
  const dispatch = useDispatch();
  const location = useLocation();
  const startTour = useBooleanState(false);

  useEffect(() => {
    initialise();
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (
      searchParams.toString() !== "" &&
      location.pathname === eCommerceRoute
    ) {
      dispatch(updateSids(getEcommerceInitialSid(location.search)));
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const audience = configData.AUTH0_AUDIENCE;
    if (isAuthenticated) {
      getAccessTokenSilently({
        audience,
        scope: "read:current_user",
      })
        .then((res) => {
          dispatch(updateUserData(user));
          setSessionUserDetails(user?.email || "");
          Client.getInstance().setAuthenticationClient(res);
          isAuthenticated &&
            Client.getInstance()
              .getData(startupApiUrl())
              .then((res) => {
                res.data.show_welcome && startTour.setTrue();
              });
          dispatch(updateAccessToken(res));
        })
        .catch((error) => dispatch(showError(error, logout)));
    }
  }, [isAuthenticated]);

  const closeTour = () => {
    Client.getInstance()
      .createData(startupCloseApiUrl(), {})
      .then(() => {
        startTour.setFalse();
      });
  };

  return (
    <div style={{ display: "flex" }}>
      <div className={classes.root}>
        <ToastContainer />
        {isAuthenticated && <NavigationMenu />}
        <NavigationRoutes />
        {WEBSITE_TOUR === "1" && (
          <Tour
            onRequestClose={closeTour}
            steps={tourConfig}
            isOpen={startTour.value}
            showButtons={false}
            showNavigation={false}
            showCloseButton={false}
          />
        )}
      </div>
    </div>
  );
};

export default AppNavigation;
