import {
  getAddFavouriteApiUrl,
  getDeleteFavouriteApiUrl,
  getEcommerceSiteDetailsApiUrl,
  getTechDetailsApiUrl,
} from "../../../constants/ApiConstants";
import { Client } from "../../commoncomponent/Client";
import {
  EcommerceItemDetailsType,
  LocationType,
  TechGroupsType,
  TechsType,
  EcommerceSocialContactType,
} from "./type";
import { trackEvent } from "../../../service/mixpanel";

export const transformData = (res: any): EcommerceItemDetailsType => {
  const data = res.data;
  return {
    contact: getTransformContact(data.contact),
    location: getTransformLocation(data.location),
    platform: data.platform,
    techGroups: getTransformTech(data.tech_groups),
    title: data.site_text.title,
    sid: data.sid,
    isFavourite: data.is_favourite,
  };
};

const getTransformContact = (res: any): EcommerceSocialContactType => {
  const data = {
    emails: res.Emails,
    facebook: res.Fbs,
    insta: res.Its,
    lns: res.Lns,
    tels: res.Tels,
    tks: res.Tks,
    tws: res.Tws,
    yts: res.Yts,
  };

  if (
    [
      res.Emails,
      res.Fbs,
      res.Its,
      res.Lns,
      res.Tels,
      res.Tks,
      res.Tws,
      res.Yts,
    ].findIndex((item) => item) !== -1
  ) {
    return {
      isSocialContactAvailaible: true,
      socialContact: data,
    };
  }

  return {
    isSocialContactAvailaible: false,
    socialContact: data,
  };
};

const getTransformLocation = (res: any): LocationType => ({
  addresses: res.addresses,
  countries: res.countries,
});

const getTransformTech = (res: any): TechGroupsType[] =>
  res.map((item: any) => ({
    name: item.name,
    techs: getTechnology(item.techs),
  }));

const getTechnology = (res: any): TechsType[] => {
  if (!res) {
    return [];
  }
  return res.map(({ description, id, name, website }: any) => ({
    description: description,
    id: id,
    name: name,
    website: website,
  }));
};

export const getSelectedEcommerceDetails = (domain: string) => {
  trackEvent("SELECTED_ECOMMERCE_DETAIL", { domain: domain });
  return Client.getInstance()
    .createData(getEcommerceSiteDetailsApiUrl(), {
      domain: domain,
    })
    .then((res) => Promise.resolve(transformData(res)))
    .catch((err) => Promise.reject(err));
};

export const getSelectedTechDetails = (sid: string) => {
  trackEvent("SELECTED_TECH_DETAIL", { sid: sid });
  return Client.getInstance()
    .getData(getTechDetailsApiUrl(sid), true)
    .then((res) => Promise.resolve(transformData(res)))
    .catch((err) => Promise.reject(err));
};

export const getAddFavouriteDomain = (domainId: string) => {
  trackEvent("ADD_FAVOURITE_DOMAIN", { domainId: domainId });
  return Client.getInstance()
    .createData(getAddFavouriteApiUrl(domainId), {})
    .then(() => Promise.resolve())
    .catch((err) => Promise.reject(err));
};

export const getRemoveFavouriteDomain = (domainId: string) => {
  trackEvent("REMOVE_FAVOURITE_DOMAIN", { domainId: domainId });
  return Client.getInstance()
    .createData(getDeleteFavouriteApiUrl(domainId), {})
    .then(() => Promise.resolve())
    .catch((err) => Promise.reject(err));
};
